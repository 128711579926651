import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import RegularMedia from "./RegularMedia";

export default function ContentBlockImage({ data }) {
  const { palette } = useOptions();

  const sizes = {
    SMALL: {
      textBlock: {
        pl: data.isReverse ? 0 : { xxs: 0, lg: 17.25, lg2: 34.5 },
        pr: data.isReverse ? { xxs: 0, lg: 17.25, lg2: 34.5 } : 0,
        pt: 2,
        pb: 2.5,
      },
      imgBlock: {
        pl: data.isReverse ? { xxs: 0, lg: 17.25, lg2: 34.5 } : 0,
        pr: data.isReverse ? 0 : { xxs: 0, lg: 17.25, lg2: 34.5 },
        maxHeight: 400,
      },
      container: {},
      section: {},
      rich: {},
      title: {},
    },
    MEDIUM: {
      textBlock: {
        pl: data.isReverse ? 0 : { xxs: 0, lg2: 17.25 },
        pr: data.isReverse ? { xxs: 0, lg2: 17.25 } : 0,
        pt: 0,
        pb: 4,
      },
      imgBlock: {
        maxHeight: 400,
      },
      container: {},
      section: {},
      rich: {},
      title: {},
    },
    LARGE: {
      textBlock: {
        pl: data.isReverse ? 0 : { xxs: 0, lg2: 6.25 },
        pr: data.isReverse ? { xxs: 0, lg2: 6.25 } : 0,
      },
      imgBlock: {
        pl: data.isReverse ? 0 : { xxs: 0, lg2: 17.25 },
        pr: data.isReverse ? { xxs: 0, lg2: 17.25 } : 0,
        maxHeight: 600,
      },
      container: {},
      section: { py: { xxs: 6, lg: 14 } },
      rich: {},
      title: {},
    },
    "EXTRA LARGE": {
      textBlock: {
        pt: { xxs: 6, lg: 10 },
        pb: { xxs: 3.5, lg: 10 },
        pl: data.isReverse
          ? { xxs: 1.875, sm: 10, lg: 10, xl: 17.25 }
          : { xxs: 1.875, sm: 10, lg: 20, xl: 34.5 },
        pr: data.isReverse
          ? { xxs: 1.875, sm: 10, lg: 20, xl: 34.5 }
          : { xxs: 1.875, sm: 10, lg: 10, xl: 17.25 },
      },
      imgBlock: {},
      container: { px: 0 },
      section: { py: 0, minHeight: { xxs: 600, xl: 894 } },
      rich: { headingMargin: 4.5, fontSize: 24, lineHeight: 1.61 },
      title: { mb: { xxs: 5, lg: 10 } },
    },
  };

  return (
    <Stack
      overflow="hidden"
      component="section"
      position="relative"
      py={7.5}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
        ...sizes[data.sectionSize].section,
      }}
    >
      <Wrapper
        display="grid"
        gap={2}
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        extra={sizes[data.sectionSize].container}
      >
        <Stack alignItems="start" sx={sizes[data.sectionSize].textBlock}>
          {data.subtitle && (
            <Typography
              mb={0.75}
              fontSize={18}
              color="custom.accentLink"
              fontWeight={600}
            >
              {data.subtitle}
            </Typography>
          )}
          <Typography
            component="h2"
            fontWeight={600}
            fontSize={!data.isBigTitle ? 48 : { xxs: 68, lg: 100 }}
            lineHeight={!data.isBigTitle ? 1.375 : 1.1}
            mb={!data.isBigTitle ? 1.5 : 3}
            sx={sizes[data.sectionSize].title}
            dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
          ></Typography>
          <Stack mb={4.5}>
            <RichText
              data={data.description}
              fontSize={18}
              lineHeight={2}
              paragraphMargin={0}
              headingMargin={1}
              headingSize={48}
              {...sizes[data.sectionSize].rich}
            />
          </Stack>
          {data.link && (
            <LinkButtonFooterLocation
              data={data.link}
              underlineColor="custom.white"
              fontSize={22}
              svgColor="custom.ph"
              translateY={-2}
            />
          )}
        </Stack>
        <Stack
          gridColumn={data.isReverse ? { lg: 1 } : { lg: 2 }}
          gridRow={1}
          sx={sizes[data.sectionSize].imgBlock}
        >
          {data.sectionSize !== "EXTRA LARGE" && <RegularMedia data={data} />}
        </Stack>
      </Wrapper>
      {data.sectionSize === "EXTRA LARGE" && <RegularMedia data={data} />}
    </Stack>
  );
}
