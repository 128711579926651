import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import SupportCard from "./SupportCard";
import { useDeviceData } from "react-device-detect";
import { getUserIP4 } from "../../../services/getUserIP4";
import { getUserIP6 } from "../../../services/getUserIP6";
import LinkButtonSupport from "../../elements/LinkButton/LinkButtonSupport";
import SupportForm from "./SupportForm";
import { useOptions } from "../../../hooks/useOptions";

export default function Support({ data }) {
  const { palette } = useOptions();
  const [hovered, setHovered] = useState(null);
  const [userIP4, setUserIP4] = useState(null);
  const [userIP6, setUserIP6] = useState(null);
  const {
    browser: { name, version },
  } = useDeviceData();

  useEffect(() => {
    getUserIP4().then((data) => setUserIP4(data));
    getUserIP6().then((data) => setUserIP6(data));
  }, []);

  const handleHover = (idx) => {
    setHovered(idx);
  };
  return (
    <Stack
      component="section"
      pt={{ xxs: 6, lg: 10 }}
      pb={8}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper
        display="grid"
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        gap={2}
        mb={9}
      >
        <Stack>
          <Typography
            fontWeight={600}
            fontSize={36}
            color="custom.primary"
            mb={1.75}
          >
            {data.title}
          </Typography>
          {data.description && (
            <Stack alignItems="start">
              <RichText
                data={data.description}
                fontSize={24}
                paragraphMargin={0}
                lineHeight={1.67}
              />
            </Stack>
          )}
        </Stack>
        <Stack pt={4} pl={{ lg: "40%" }}>
          <Typography
            color="custom.primary"
            fontWeight={600}
            fontSize={18}
            mb={2.5}
          >
            Handig
          </Typography>
          <Typography fontSize={20} mb={2}>
            <Typography component="span" fontWeight={600} fontSize={20}>
              Jouw IP Adres:{" "}
            </Typography>
            {userIP4 && userIP4.ip}
            {userIP6 && userIP6.ip}
          </Typography>

          <Typography fontSize={20} mb={5}>
            <Typography component="span" fontWeight={600} fontSize={20}>
              Browser:{" "}
            </Typography>
            {name} {version}
          </Typography>
          <LinkButtonSupport
            fontSize={20}
            underlineColor="transparent"
            data={{
              type: "ANCHOR",
              link: "#user-data",
              title: "Meer gegevens",
            }}
          />
        </Stack>
      </Wrapper>
      {data.cards && (
        <Wrapper isBig={true} mb={10}>
          <Stack
            component="ul"
            display="grid"
            gridTemplateColumns={{
              md: "repeat(2, 1fr)",
              lg2: `repeat(${data.cards.length}, 1fr)`,
            }}
            gap={2}
          >
            {data.cards.map((i, idx) => (
              <SupportCard
                key={"support-card" + idx}
                data={i}
                hovered={idx === hovered}
                pos={idx}
                handleHover={handleHover}
                addBackground={data.addBackground}
              />
            ))}
          </Stack>
        </Wrapper>
      )}
      <Wrapper>
        <SupportForm />
      </Wrapper>
    </Stack>
  );
}
