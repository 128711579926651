import {
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React, { useState } from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";

export default function ContentBlockAccordion({ data }) {
  const { palette } = useOptions();
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };
  return (
    <Stack
      component="section"
      pt={{ xxs: 7, lg: 9.5 }}
      pb={{ xxs: 9, lg: 11.5 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Stack px={{ lg: 17.25 }}>
          <Typography
            fontWeight={600}
            fontSize={48}
            lineHeight={1.33}
            mb={3}
            dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
          ></Typography>
          <Stack display="grid" gap={2} gridTemplateColumns={{ lg: "1fr 1fr" }}>
            <Stack
              gridColumn={data.isReverse ? { lg: 2 } : { lg: 1 }}
              gridRow={{ lg: 1 }}
            >
              <RichText
                data={data.description}
                fontSize={18}
                lineHeight={2.22}
                paragraphMargin={0}
              />
            </Stack>
            <Stack
              pl={data.isReverse ? 0 : { lg: "25%" }}
              pr={data.isReverse ? { lg: "25%" } : 0}
              gridColumn={data.isReverse ? { lg: 1 } : { lg: 2 }}
              gridRow={{ lg: 1 }}
              component="ul"
            >
              {data.cases.map(({ description, title, id }, idx) => (
                <Accordion
                  key={"accordion-item" + idx}
                  component="li"
                  expanded={expanded === id}
                  onChange={handleChange(id)}
                  square
                  elevation={0}
                  disableGutters
                  sx={{
                    bgcolor: "transparent",
                    "&:before": {
                      display: "none",
                    },
                    "&:not(:last-child)": {
                      mb: 1.25,
                    },
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                      borderRadius: 3.75,
                      bgcolor: !data.addBackground
                        ? palette.secondary
                        : "custom.white",
                      pl: 2.5,
                      pr: 3,
                      pb: 3.25,
                      pt: 3.75,
                      "& > div": { m: 0 },
                      "& svg": {
                        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                        color:
                          expanded === id ? "custom.primary" : "custom.text",
                      },
                    }}
                    expandIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M6 9l6 6 6-6" />
                      </svg>
                    }
                  >
                    <Typography fontWeight={600} fontSize={22} lineHeight={1}>
                      {title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 2.5, pb: 4, pt: 2.5 }}>
                    <RichText
                      data={description}
                      paragraphMargin={0.5}
                      lineHeight={2}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
