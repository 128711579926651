import styled from "styled-components";

export const MainLink = styled.div`
  background: ${({
    $secondary,
  }) => `transparent linear-gradient(95deg, ${$secondary} 0%, #f6faff 100%) 0% 0%
    no-repeat padding-box`};
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 72px;
  width: 100%;
  @media screen and (min-width: 780px) {
    height: 150px;
    position: absolute;
    border-radius: 15px 0px 0px 0px;
    width: 315px;
    bottom: -48px;
  }
  @media screen and (min-width: 1024px) {
    width: 480px;
  }
  @media screen and (min-width: 1280px) {
    width: 560px;
  }
  @media screen and (min-width: 1920px) {
    width: calc(100vw - 1920px + 400px + 276px);
  }
`;
