import React from "react";
import { Swiper, SwiperSlide } from "../../common/Swiper/Swiper";
import { useOptions } from "../../../hooks/useOptions";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { useParallax } from "react-scroll-parallax";
import { brakeString } from "../../../helpers/brakeString";

export default function TextList({ data }) {
  const { setCursorType, palette } = useOptions();
  const parallax = useParallax({
    speed: -5,
  });
  return (
    <Stack
      component="section"
      pt={8}
      pb={6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper
        extra={{
          px: 0,
          pl: { xxs: 1.875, sm: 10, lg: 20, xl: 34.5 },
          pr: { xxs: 0 },
        }}
      >
        <Typography
          component="h2"
          fontWeight={600}
          fontSize={{ xxs: 68, lg: 100 }}
          lineHeight={1.22}
          mb={{ xxs: 5, lg: 11 }}
          ref={parallax.ref}
          dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
        ></Typography>
        <div
          onMouseEnter={() => setCursorType("slider")}
          onMouseLeave={() => setCursorType("trail")}
        >
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1.4,
                spaceBetween: 56,
              },
              780: {
                slidesPerView: 2,
                spaceBetween: 80,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 120,
              },
              1920: {
                slidesPerView: 4,
                spaceBetween: 120,
              },
            }}
            scrollbar={true}
            isAsymmetric
            isSmall
            mousewheel={true}
          >
            {data.cards.map((i, idx) => (
              <SwiperSlide key={"swiper-text" + idx}>
                <Stack
                  height="100%"
                  sx={{
                    "&:hover h3": {
                      color: "custom.controls",
                    },
                  }}
                >
                  <Typography
                    component="h3"
                    mb={2}
                    fontWeight={600}
                    fontSize={48}
                    sx={{
                      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                    }}
                  >
                    {i.title}
                  </Typography>
                  <RichText
                    data={i.description}
                    paragraphMargin={1}
                    fontSize={18}
                    lineHeight={2.2}
                  />
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Wrapper>
    </Stack>
  );
}
