import axios from "axios";

export const getNews = async (page = 1, pageSize = 3, tags = []) => {
  let filter = "";

  if (tags.length > 0) {
    filter = tags.map((tag) => `&filters[tags][id][$eq]=${tag}`).join("");
    // filter = tags
    //   .map((tag, idx) => `&filters[$and][${idx}][tags][id][$eq]=${tag}`)
    //   .join("");
  }

  const response = await axios.get(
    `/api/news?sort[0]=date:desc&populate[tags][populate]=*&populate[image][populate]=*&pagination[pageSize]=${pageSize}&pagination[page]=${page}&publicationState=live${filter}`
  );
  return response.data;
};

// {
//   sort: ['date:desc'],
//   populate: {
//     tags: {
//       populate: "*"
//     },
//     image: {
//       populate: "*"
//     }
//   },
//   pagination: {
//     pageSize: 10,
//     page: 1,
//   },
//   publicationState: 'live',
// }
