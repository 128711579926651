import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import SmallSlider from "../../elements/SmallSlider/SmallSlider";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";

export default function About({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -5,
  });
  return (
    <Stack
      component="section"
      py={{ xxs: 8, lg: 13 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper
        display="grid"
        gap={{ xxs: 8, lg: data.isSmall ? 2 : 6, xl: 2 }}
        gridTemplateColumns={
          data.isSmall ? { lg: "1fr 1fr" } : { lg: "6fr 4fr" }
        }
      >
        <Stack alignItems={{ xxs: "center" }} position="relative">
          <SmallSlider data={data.images.data} isSmall={data.isSmall} />
        </Stack>
        <Stack
          pl={data.isSmall ? 0 : { xl: "25%" }}
          pr={data.isSmall ? 6.25 : 0}
          justifyContent="center"
          alignItems="start"
          ref={parallax.ref}
        >
          <Typography
            component="h2"
            fontWeight={600}
            fontSize={data.isSmall ? { xxs: 68, lg: 48 } : { xxs: 68, lg: 100 }}
            lineHeight={
              data.isSmall ? { xxs: 1.17, lg: 1.33 } : { xxs: 1.17, lg: 1.1 }
            }
            mb={data.isSmall ? 4.5 : 3}
            dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
          ></Typography>
          <RichText data={data.description} fontSize={18} lineHeight={1.89} />
          {data.links &&
            data.links.map((i, idx) => (
              <LinkButtonFooterLocation
                key={"about" + data.title + idx}
                data={i}
                fontSize={22}
                gap={0.5}
                underlineColor="custom.white"
                translateY={-1}
                svgColor="custom.ph"
              />
            ))}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
