import React from "react";
// import Cursor from "./Cursor";
import CursorTrail from "./CursorTrail";

export default function CursorBlur({ cursor = "auto" }) {
  return (
    <>
      {/* <Cursor
        addDelay
        cursor={cursor}
        width={400}
        height={400}
        zIndex={4}
        bgcolor="transparent"
        backdropFilter="saturate(110%) blur(15px)"
        maskImage="radial-gradient(circle at center,black 0%,black 30%,transparent 70%)"
      /> */}
      <CursorTrail />
    </>
  );
}
