import { Stack, Typography } from "@mui/material";
import React from "react";

export default function SupportUserDataItem({ name, value }) {
  return (
    <Stack component="li" spacing={0.75}>
      <Typography fontSize={12} lineHeight={1.33} color="custom.primary">
        {name}
      </Typography>
      <Typography fontSize={16} lineHeight={1.375}>
        {value ?? "Niet beschikbaar"}
      </Typography>
    </Stack>
  );
}
