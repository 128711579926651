import React from "react";
import { Image, Section } from "./CaseHero.styled";
import { Stack, Typography } from "@mui/material";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import Wrapper from "../../common/Wrapper";
import LinkButtonAccentTransparent from "../../elements/LinkButton/LinkButtonAccentTransparent";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";
import { getArrSeparatedByAsterisk } from "../../../helpers/getArrSeparatedByAsterisk";
import AnimatedLetters from "../../elements/AnimatedText/AnimatedLetters";
import BreadcrumbsLink from "../../elements/BreadcrumbsLink/BreadcrumbsLink";

const api = process.env.REACT_APP_API_URL;

export default function CaseHero({
  data: {
    project: {
      data: {
        attributes: {
          image,
          title,
          subtitle,
          slug,
          characteristic,
          characteristicHighlightedWords,
        },
      },
    },
  },
  breadcrumbs,
}) {
  const { setCursorType } = useOptions();
  const parallax = useParallax({
    speed: -40,
  });
  return (
    <Section
      onMouseEnter={() => setCursorType("blur")}
      onMouseLeave={() => setCursorType("trail")}
    >
      <Wrapper zIndex={5}>
        <Stack mb={3}>
          {characteristic && (
            <Typography
              color="custom.white"
              fontSize={20}
              fontWeight={600}
              component="span"
              mb={1}
              pl={1.25}
            >
              {getHighlightedText(
                characteristic,
                characteristicHighlightedWords
              )}
            </Typography>
          )}
          <Typography
            color="custom.white"
            fontSize={{ xxs: 70, md: 120 }}
            fontWeight={600}
            lineHeight={{ xxs: 1.2, md: 1.08 }}
            component="h1"
            mb={0.5}
            sx={{ overflowWrap: "anywhere" }}
          >
            {getArrSeparatedByAsterisk(title).map((i, idx, arr) => (
              <span key={"animated-case-hero" + i + idx}>
                <AnimatedLetters text={i} />
                {arr.length - 1 !== idx && <br />}
              </span>
            ))}
          </Typography>
          {subtitle && (
            <Typography
              color="custom.white"
              fontSize={16}
              fontWeight={700}
              component="span"
              pl={1.25}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="start">
          <LinkButtonAccentTransparent
            data={{
              type: "INTERNAL LINK",
              link: `/projects/${slug}`,
              title: "Bekijk deze case",
            }}
          />
        </Stack>{" "}
        {breadcrumbs.slug !== "home" && (
          <Stack
            position="absolute"
            zIndex={4}
            bottom={40}
            direction="row"
            gap={0.5}
          >
            <BreadcrumbsLink title="Home" />
            {breadcrumbs.parent && (
              <>
                <Typography
                  component="span"
                  color="custom.white"
                  lineHeight={1.375}
                  fontSize={16}
                >
                  /
                </Typography>
                <BreadcrumbsLink
                  title={breadcrumbs.parent.title}
                  to={"/" + breadcrumbs.parent.slug}
                />
              </>
            )}
            <Typography
              component="span"
              color="custom.white"
              lineHeight={1.375}
              fontSize={16}
            >
              /
            </Typography>
            <BreadcrumbsLink
              title={breadcrumbs.title}
              to={"/" + breadcrumbs.slug}
            />
          </Stack>
        )}
      </Wrapper>
      <Stack
        position="absolute"
        top="-18%"
        bottom={0}
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        <Image
          src={api + image.data.attributes.url}
          alt={image.data.attributes.alternativeText}
        />
      </Stack>
    </Section>
  );
}
