import { Stack, Typography } from "@mui/material";
import React from "react";
import { useParallax } from "react-scroll-parallax";

export default function BannerLabel({ data }) {
  const parallax = useParallax({
    rotate: [15, -15],
  });
  return (
    <Stack
      position="absolute"
      bottom={12}
      right={{ xxs: 34, md: 0 }}
      borderRadius="50%"
      zIndex={2}
      bgcolor="custom.label"
      py={3.5}
      pr={3.5}
      pl={5.25}
      justifyContent="center"
      sx={{
        transform: "translateY(50%) translateX(50%)",
        aspectRatio: "1/1",
      }}
    >
      <Typography
        ref={parallax.ref}
        color="custom.white"
        lineHeight={1}
        fontSize={14}
        fontWeight={600}
        width={54}
      >
        {data}
      </Typography>
    </Stack>
  );
}
