import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import RichText from "../../common/RichText/RichText";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import LinkButtonStyled from "../../elements/LinkButton/LinkButtonStyled";
import ContentListItem from "./ContentListItem";

export default function ContentBlockList({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      py={8}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Stack px={data.renderTwoColumns ? 0 : { lg: 17.25 }}>
          {!data.showTitle && <HiddenTitle>{data.title}</HiddenTitle>}
          <Stack
            sx={
              data.renderTwoColumns
                ? {
                    display: "grid",
                    gridTemplateColumns: { lg: "1fr 1fr" },
                    gap: 2,
                  }
                : {}
            }
          >
            <Stack
              sx={
                data.renderTwoColumns
                  ? { justifyContent: "end", pb: { lg: 6 }, pr: { sm: 17.25 } }
                  : {}
              }
            >
              {data.showTitle && (
                <Typography
                  component="h2"
                  fontWeight={600}
                  fontSize={data.isBigTitle ? { xxs: 68, lg: 100 } : 48}
                  lineHeight={data.isBigTitle ? 1.1 : 1.33}
                  mb={3}
                  dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
                ></Typography>
              )}
              <RichText
                data={data.description}
                fontSize={18}
                lineHeight={2}
                paragraphMargin={data.renderTwoColumns ? 4 : 0}
              />
              {data.link && (
                <LinkButtonStyled
                  data={data.link}
                  fontSize={22}
                  underlineColor="transparent"
                  svgColor="custom.ph"
                />
              )}
            </Stack>
            <Stack mt={3} component="ul" gap={1.75}>
              {data.cases.map((i, idx) => (
                <ContentListItem
                  key={"content-list" + data.title + idx}
                  data={i}
                  pos={idx}
                  addNumeration={data.addNumeration}
                  isCardsGradient={data.isCardsGradient}
                  addBackground={data.addBackground}
                  renderTwoColumns={data.renderTwoColumns}
                  coloredSubtitles={data.coloredSubtitles}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
