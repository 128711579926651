import React from "react";
import { Container } from "@mui/material";

export default function Wrapper({
  children,
  addParallax = false,
  isBig = false,
  extra = {},
  ...props
}) {
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{
        px: isBig
          ? { xxs: 1.875, sm: 5, lg: 10, xl: 17.5 }
          : { xxs: 1.875, sm: 10, lg: 20, xl: 34.5 },
        ...extra,
        ...props,
      }}
    >
      {children}
    </Container>
  );
}
