import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./PlayButton.css";

export default function PlayButton({
  isPlaying,
  isSmall = false,
  handlePlay,
  handleFullScreen,
  isOnTop = false,
}) {
  const nodeRef = useRef(null);
  const txtRef = useRef(null);

  return (
    <Stack
      ref={nodeRef}
      direction="row"
      position="absolute"
      bottom={isOnTop ? "auto" : isSmall ? 32 : 54}
      top={!isOnTop ? "auto" : isSmall ? 32 : 54}
      left={isSmall ? 32 : 54}
      zIndex={5}
      justifyContent="center"
      alignItems="center"
      gap={isSmall ? 1.5 : 2}
      sx={{
        color: "custom.white",
        pointerEvents: "none",
      }}
    >
      <Stack
        component="button"
        type="button"
        bgcolor="custom.primary"
        borderRadius="50%"
        p={0.75}
        zIndex={4}
        onClick={handlePlay}
        sx={{ pointerEvents: "auto", cursor: "pointer" }}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isPlaying}
            nodeRef={nodeRef}
            addEndListener={(done) => {
              nodeRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="play-button"
          >
            <Stack ref={nodeRef}>
              {isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isSmall ? 44 : 64}
                  height={isSmall ? 44 : 64}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <rect x="6" y="4" width="4" height="16"></rect>
                  <rect x="14" y="4" width="4" height="16"></rect>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isSmall ? 44 : 64}
                  height={isSmall ? 44 : 64}
                  viewBox="0 0 20 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              )}
            </Stack>
          </CSSTransition>
        </SwitchTransition>
      </Stack>{" "}
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isPlaying}
          nodeRef={txtRef}
          addEndListener={(done) => {
            txtRef.current.addEventListener("transitionend", done, false);
          }}
          classNames="play-button-txt"
        >
          <Stack ref={txtRef}>
            {!isPlaying && (
              <Typography
                fontSize={isSmall ? 18 : 24}
                fontWeight={600}
                pt={isSmall ? 0.5 : 1}
              >
                Tik om te spelen
              </Typography>
            )}
            {isPlaying && (
              <Stack
                component="button"
                type="button"
                bgcolor="custom.primary"
                borderRadius="50%"
                p={1.5}
                zIndex={4}
                onClick={handleFullScreen}
                sx={{ pointerEvents: "auto", cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isSmall ? 32 : 52}
                  height={isSmall ? 32 : 52}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                </svg>
              </Stack>
            )}
          </Stack>
        </CSSTransition>
      </SwitchTransition>
    </Stack>
  );
}
