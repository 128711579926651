import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";

export default function AccordionItem({
  expanded,
  handleChange,
  data: { link, text, title, id },
  addBackground,
  secondary,
}) {
  return (
    <Accordion
      component="li"
      expanded={expanded === id}
      onChange={handleChange(id)}
      square
      elevation={0}
      disableGutters
      sx={{
        bgcolor: "transparent",
        "&:before": {
          display: "none",
        },
        "&:not(:last-child)": {
          mb: 1.25,
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          borderRadius: 3.75,
          bgcolor: addBackground ? secondary : "custom.white",
          pl: 2.5,
          pr: 3,
          pb: 3.25,
          pt: 3.75,
          "& > div": { m: 0 },
          "& svg": {
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            color: expanded === id ? "custom.primary" : "custom.text",
          },
        }}
        expandIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        }
      >
        <Typography fontWeight={600} fontSize={22} lineHeight={1}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 2.5, pb: 4, pt: 2.5 }}>
        <RichText data={text} paragraphMargin={0.5} lineHeight={2} />
        {link &&
          link.map((i, idx) => (
            <LinkButtonFooterLocation
              key={"hero-cta-links" + idx}
              data={i}
              fontSize={16}
              gap={1.5}
              color="custom.primary"
              underlineColor="custom.primary"
              translateY={-2}
              avoidIcon
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
}
