import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  min-height: 828px;
  padding-top: 162px;
  padding-bottom: 96px;
  display: flex;
  align-items: end;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;
