import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import { Swiper, SwiperSlide } from "../../common/Swiper/Swiper";
import { useOptions } from "../../../hooks/useOptions";
import LinkButton from "../../elements/LinkButton/LinkButton";

export default function TestimonialsSlider({ data }) {
  const { setCursorType, palette } = useOptions();
  return (
    <Stack
      component="section"
      pt={9}
      pb={9}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper
        extra={{
          px: 0,
          pl: { xxs: 1.875, sm: 10, lg: 20, xl: 34.5 },
          pr: { xxs: 0 },
        }}
      >
        <Typography fontSize={48} fontWeight={600} lineHeight={1.29} mb={2}>
          {data.title}
        </Typography>
        <Typography
          width={{ sm: "80%", md: "60%", lg: "40%" }}
          fontWeight={600}
          fontSize={22}
          lineHeight={1.82}
          mb={4}
        >
          {data.description}
        </Typography>
        <div
          onMouseEnter={() => setCursorType("slider")}
          onMouseLeave={() => setCursorType("trail")}
        >
          <Swiper
            height="auto"
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 56,
              },
              780: {
                slidesPerView: 2,
                spaceBetween: 80,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 120,
              },
              1920: {
                slidesPerView: 3,
                spaceBetween: 120,
              },
            }}
            scrollbar={true}
            mousewheel={true}
            isAsymmetric
            isSmall
          >
            {data.cards.map((i, idx) => (
              <SwiperSlide height="auto" key={"testimonials-swiper" + idx}>
                <Stack
                  height="100%"
                  boxSizing="border-box"
                  pt={2.5}
                  pb={{ xxs: 7, lg: 11 }}
                  justifyContent="space-between"
                  spacing={4.75}
                >
                  <Typography fontSize={18} lineHeight={1.89}>
                    {i.text}
                  </Typography>
                  <Stack direction="row" gap={1.25}>
                    <Typography fontWeight={600}>{i.author}</Typography>
                    {i.link && (
                      <LinkButton
                        data={i.link}
                        sx={{ color: "custom.accentLink" }}
                      />
                    )}
                  </Stack>
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Wrapper>
    </Stack>
  );
}
