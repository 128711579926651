export const getHighlightedText = (text, highlight) => {
  if (!highlight) return <span>{text}</span>;
  const deviders = highlight.trim().split(" ");
  if (deviders.length > 1) {
    const parts = text.split(new RegExp(`(${deviders.join("|")})`, "gi"));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={"cta" + highlight + i}
            style={
              deviders.includes(part.toLowerCase())
                ? {
                    fontWeight: 400,
                    fontFamily: "Libre Baskerville",
                    fontStyle: "italic",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  }

  const parts = text.split(new RegExp(`(${highlight.trim()})`, "gi"));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={"cta" + highlight + i}
          style={
            part.toLowerCase() === highlight.trim().toLowerCase()
              ? {
                  fontWeight: 400,
                  fontFamily: "Libre Baskerville",
                  fontStyle: "italic",
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};
