import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { useOptions } from "../../../hooks/useOptions";
import RichText from "../../common/RichText/RichText";
import { calculateColumns } from "../../../helpers/calculateColumns";
import ImageBannerCard from "./ImageBannerCard";

export default function ImageBanner({ data }) {
  const { palette } = useOptions();

  return (
    <Stack
      component="section"
      pt={data.addPaddings ? { xxs: 6, lg: 7.75 } : 0}
      pb={data.addPaddings ? { xxs: 6, lg: 11.25 } : 0}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        {!data.showTitle && <HiddenTitle>{data.title}</HiddenTitle>}
        {data.showTitle && (
          <Typography
            component="h2"
            fontSize={36}
            fontWeight={600}
            lineHeight={1.33}
            mb={2}
          >
            {data.title}
          </Typography>
        )}
        {data.main && (
          <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
            {data.main.map((i, idx) => (
              <Stack
                key={data.title + "image-banner" + idx}
                pr={idx === 0 ? { lg: 17.25 } : { lg: 4.5 }}
              >
                <RichText data={i.content} fontSize={24} lineHeight={1.67} />
              </Stack>
            ))}
          </Stack>
        )}
      </Wrapper>
      <Wrapper
        isBig
        display="grid"
        gap={2}
        gridTemplateColumns={{
          lg: `repeat(${calculateColumns(data.images.data.length, 3)}, 1fr)`,
        }}
      >
        {data.images.data.map((i, idx, arr) => (
          <ImageBannerCard
            key={"image-banner" + data.title + idx}
            data={i}
            left={calculateColumns(arr.length, 3)}
            pos={idx}
          />
        ))}
      </Wrapper>
    </Stack>
  );
}
