import React from "react";
import LinkButton from "./LinkButton";
import { Typography } from "@mui/material";
import { colors } from "../../../helpers/colors";

export default function LinkButtonGhost({
  data,
  fontWeight = 600,
  fontSize = 14,
  lineHeight = 1.3,
}) {
  return (
    <LinkButton data={data}>
      <Typography
        color={colors[data.color] ?? "custom.accent"}
        fontWeight={fontWeight}
        fontSize={fontSize}
        lineHeight={lineHeight}
        position="relative"
        sx={{
          "&::after": {
            content: "''",
            position: "absolute",
            bottom: -4,
            left: 0,
            width: "100%",
            height: "1px",
            bgcolor: colors[data.color] ?? "custom.accent",
          },
        }}
      >
        {data.title}
      </Typography>
    </LinkButton>
  );
}
