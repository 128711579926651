import React from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Stack, Typography } from "@mui/material";

export default function RichText({
  data,
  dark = true,
  fontSize = 16,
  headingSize = fontSize + 2,
  headingMargin = 0,
  paragraphMargin = 5,
  lineHeight = 1.5,
  headingHeight = 1.1,
}) {
  const textColor = dark ? "custom.text" : "custom.white";
  return (
    <BlocksRenderer
      content={data}
      blocks={{
        list: ({ children, format }) => {
          switch (format) {
            case "unordered":
              return (
                <Stack
                  component="ul"
                  gap={2}
                  sx={{
                    "& li": {
                      display: "flex",
                      alignItems: "baseline",
                      lineHeight: 1,
                      fontSize,
                      color: textColor,

                      "&::before": {
                        content: "''",
                        display: "block",
                        width: "0.75em",
                        height: "0.75em",
                        borderRadius: "50%",
                        backgroundColor: "custom.disabled",
                        marginRight: "0.75em",
                        border: "0.25em solid #FFA200",
                      },
                    },
                  }}
                >
                  {children}
                </Stack>
              );
            case "ordered":
              return (
                <Stack
                  component="ol"
                  sx={{
                    "& li": {
                      lineHeight: 2.22,
                      fontSize,
                      color: textColor,
                      listStyleType: "decimal",
                      listStylePosition: "inside",
                    },
                  }}
                >
                  {children}
                </Stack>
              );
            default:
              return <ul>{children}</ul>;
          }
        },
        paragraph: ({ children }) => (
          <Typography
            mb={paragraphMargin}
            fontSize={fontSize}
            color={textColor}
            lineHeight={lineHeight}
          >
            {children}
          </Typography>
        ),
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return (
                <Typography
                  component="h1"
                  fontSize={headingSize + 4}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            case 2:
              return (
                <Typography
                  component="h2"
                  fontSize={headingSize + 2}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            case 3:
              return (
                <Typography
                  component="h3"
                  fontSize={headingSize}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            case 4:
              return (
                <Typography
                  component="h4"
                  fontSize={headingSize}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            case 5:
              return (
                <Typography
                  component="h5"
                  fontSize={headingSize}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            case 6:
              return (
                <Typography
                  component="h6"
                  fontSize={headingSize}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
            default:
              return (
                <Typography
                  fontSize={headingSize}
                  fontWeight={600}
                  color={textColor}
                  mb={headingMargin}
                  lineHeight={headingHeight}
                >
                  {children}
                </Typography>
              );
          }
        },
        link: ({ children, url }) => (
          <Typography
            component="a"
            href={url}
            target="_blank"
            color="custom.primary"
            lineHeight={lineHeight}
            fontSize={fontSize}
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              "&:hover": {
                color: "custom.controls",
              },
              "&:focus": {
                color: "custom.controls",
              },
            }}
          >
            {children}
          </Typography>
        ),
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => (
          <span
            style={{ fontFamily: "Libre Baskerville", fontStyle: "italic" }}
          >
            {children}
          </span>
        ),
      }}
    />
  );
}
