export const calculateColumns = (len, max) => {
  if (len <= max) {
    return len;
  }
  for (let i = max; i > 0; i--) {
    if (len % i === 0) {
      return i;
    }
  }
};
