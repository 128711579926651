import React from "react";
import { useOptions } from "../../../hooks/useOptions";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../../common/Wrapper";
import Achieve from "./Achieve";

export default function Achievements({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      pt={8}
      pb={6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Typography
          fontWeight={600}
          fontSize={48}
          lineHeight={1.33}
          mb={3}
          component="h2"
        >
          {data.title}
        </Typography>
      </Wrapper>
      {data.main.map((i, idx) => (
        <Achieve key={"achive" + data.title + idx} data={i} />
      ))}
    </Stack>
  );
}
