import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import { useParallax } from "react-scroll-parallax";
import LinkButtonStyled from "../../elements/LinkButton/LinkButtonStyled";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";

export default function CallToAction({ data }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -3,
  });
  const parallaxMain = useParallax({
    speed: -6,
  });

  return (
    <Stack
      component="section"
      pt={{ xxs: 8, lg: 13 }}
      pb={{ xxs: 6, lg: 11.25 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <HiddenTitle>{data.title}</HiddenTitle>
      <Wrapper
        display="grid"
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        gap={{ lg: 2 }}
      >
        <Stack
          pr={{ lg: 10 }}
          gap={{ xxs: 4, lg: 6 }}
          alignItems="start"
          ref={parallaxMain.ref}
        >
          {!data.useLargeCTA && (
            <Typography fontSize={48} fontWeight={600} lineHeight={1.29}>
              {getHighlightedText(data.ctaText, data.highlightingWords ?? "")}
            </Typography>
          )}
          {data.useLargeCTA && (
            <Typography
              fontSize={{ xxs: 68, lg: 100 }}
              fontWeight={600}
              lineHeight={1.1}
              dangerouslySetInnerHTML={{ __html: brakeString(data.ctaText) }}
            ></Typography>
          )}
          {data.description && !isDesktop && (
            <RichText
              fontSize={24}
              paragraphMargin={2}
              lineHeight={{ xxs: 1.5, lg: 1.33 }}
              data={data.description}
            />
          )}
          {data.links && (
            <Stack
              direction="row"
              gap={3.75}
              alignItems="center"
              component="ul"
            >
              {data.links.map((i, idx) => (
                <Stack component="li" key={"cta-link" + data.title + idx}>
                  <LinkButtonStyled data={i} fontSize={14} />
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
        <Stack
          pt={2}
          ref={parallax.ref}
          justifyContent={data.useLargeCTA ? "end" : "start"}
        >
          {data.description && isDesktop && (
            <RichText
              fontSize={24}
              paragraphMargin={2}
              lineHeight={1.33}
              data={data.description}
            />
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
