import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import Wrapper from "../../common/Wrapper";
import LocationItem from "../../elements/LocationItem/LocationItem";
import LocationItemAbout from "../../elements/LocationItem/LocationItemAbout";
import LinkButtonFooterSocials from "../../elements/LinkButton/LinkButtonFooterSocials";
import { useOptions } from "../../../hooks/useOptions";
import LocationData from "../../elements/LocationItem/LocationData";

export default function Footer({ options }) {
  const [selectedLocation, setSelectedLocation] = useState(0);
  const { palette } = useOptions();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const onLocationHoverHandler = (idx) => {
    setSelectedLocation(idx);
  };
  return (
    <Stack component="footer">
      <Stack
        pt={{ xxs: 8.5, lg: 13 }}
        pb={{ xxs: 2.5, lg: 6.25 }}
        sx={{
          background: `transparent linear-gradient(96deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`,
        }}
      >
        {options && (
          <Wrapper
            display="grid"
            gridTemplateColumns={{ lg: "6fr 4fr" }}
            gap={{ xxs: 6.5, lg: 0 }}
          >
            <Stack spacing={2.5}>
              <Typography fontWeight={600} fontSize={36}>
                Vestigingen
              </Typography>
              <Stack
                component="ul"
                display={{ xxs: "flex", sm: "grid" }}
                flexDirection="row"
                justifyContent="space-between"
                gridTemplateColumns={`repeat(${options.locations.length}, 1fr)`}
              >
                {options.locations.map((i, idx) => (
                  <Stack component="li" key={"footer-loc" + idx}>
                    <LocationItem
                      selected={selectedLocation === idx}
                      data={i}
                      hoverHandler={onLocationHoverHandler}
                      pos={idx}
                    />
                  </Stack>
                ))}
              </Stack>
              {!isTablet && (
                <LocationData data={options.locations[selectedLocation]} />
              )}
            </Stack>
            <Stack spacing={2.5}>
              <Typography fontWeight={600} fontSize={36}>
                Informatie
              </Typography>
              <LocationItemAbout data={options.locations[selectedLocation]} />
            </Stack>
          </Wrapper>
        )}
      </Stack>
      <Stack
        pt={{ xxs: 3.75, lg: 4.5 }}
        pb={{ xxs: 3, lg: 5.5 }}
        sx={{
          background: `transparent linear-gradient(96deg, #F6FAFF 0%, ${palette.secondary} 100%) 0% 0% no-repeat padding-box`,
        }}
      >
        <Wrapper
          display="grid"
          gridTemplateColumns={{ lg: "6fr 4fr" }}
          gap={{ xxs: 2.25, lg: 0 }}
        >
          <Stack
            display={{ xxs: "flex", lg: "grid" }}
            flexDirection="row"
            justifyContent="space-between"
            gridTemplateColumns="1fr 2fr"
          >
            <Typography variant="body1" color="custom.ph" fontSize={14}>
              © Webba {new Date().getFullYear()}
            </Typography>
            <Stack direction="row" gap={4} component="ul">
              {options &&
                options.socialLinks.map((i, idx) => (
                  <li key={"footer-soc" + idx}>
                    <LinkButtonFooterSocials data={i} />
                  </li>
                ))}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            gap={{ sm: 7.5 }}
            component="ul"
            justifyContent={{ xxs: "space-around" }}
          >
            {options &&
              options.privacyLinks.map((i, idx) => (
                <li key={"footer-soc" + idx}>
                  <LinkButtonFooterSocials data={i} />
                </li>
              ))}
          </Stack>
        </Wrapper>
      </Stack>
    </Stack>
  );
}
