import { Stack, Typography, useMediaQuery } from "@mui/material";
import CustomSlider from "../../elements/CustomSlider/CustomSlider";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import RegularSlider from "../../elements/RegularSlider/RegularSlider";
import Wrapper from "../../common/Wrapper";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { MainLink } from "./CasesSlider.styled";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";

export default function CasesSlider({ data }) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      py={data.renderTitle ? { xxs: 6, lg: 9.5 } : 0}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      {data.renderTitle && (
        <Wrapper
          pb={{ xxs: 4, lg: 6 }}
          display="flex"
          justifyContent="space-between"
        >
          <Stack>
            {data.subtitle && (
              <Typography
                fontSize={18}
                color="custom.accentLink"
                fontWeight={600}
                mb={0.25}
                pl={0.25}
              >
                {data.subtitle}
              </Typography>
            )}
            <Typography
              fontSize={data.isBigTitle ? 100 : 48}
              fontWeight={600}
              lineHeight={data.isBigTitle ? 1.1 : 1.33}
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>
          </Stack>
          {data.link && isTablet && (
            <Stack
              width={{ sm: 300, md: 400 }}
              position="relative"
              flexShrink={0}
            >
              <MainLink $secondary={palette.secondary}>
                <div style={{ flexShrink: 0 }}>
                  <LinkButtonFooterLocation
                    data={data.link}
                    color="custom.text"
                    fontSize={22}
                    gap={1}
                    underlineColor="transparent"
                    svgColor="#707070"
                    translateY={-2}
                  />
                </div>
              </MainLink>
            </Stack>
          )}
        </Wrapper>
      )}
      {!data.renderTitle && <HiddenTitle>{data.title}</HiddenTitle>}
      {data.type === "FEATURED IMAGES" && <CustomSlider data={data} />}
      {data.type === "CARDS WITH DESCRIPTION" && <RegularSlider data={data} />}
      {data.type === "SMALL CARDS" && <RegularSlider data={data} isShort />}
      {data.link && !isTablet && (
        <Stack width={{ lg: 400 }} position="relative" flexShrink={0}>
          <MainLink $secondary={palette.secondary}>
            <div style={{ flexShrink: 0 }}>
              <LinkButtonFooterLocation
                data={data.link}
                color="custom.text"
                fontSize={22}
                gap={1}
                underlineColor="transparent"
                svgColor="#707070"
                translateY={-2}
              />
            </div>
          </MainLink>
        </Stack>
      )}
    </Stack>
  );
}
