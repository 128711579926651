import { Stack, useMediaQuery } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import CustomSlide from "./CustomSlide";
import Wrapper from "../../common/Wrapper";
import CustomProgressBar from "./CustomProgressBar";
import CustomDescription from "./CustomDescription";
import CustomTitle from "./CustomTitle";
import CustomControls from "./CustomControls";
import { useOptions } from "../../../hooks/useOptions";
import useSwipe from "../../../hooks/useSwipe";

export default function CustomSlider({ data: { slides } }) {
  const [selected, setSelected] = useState(0);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const total = slides.length;
  const interval = useRef(null);
  const timeout = useRef(null);
  const section = useRef(null);

  const { setCursorType } = useOptions();

  const handleSelect = (idx) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (interval.current) {
      clearInterval(interval.current);
    }
    setSelected(idx);
    timeout.current = setTimeout(() => {
      interval.current = setInterval(() => {
        setSelected((prev) => (prev + 1) % total);
      }, 5000);
    }, 10000);
  };

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleSelect((selected + 1) % total),
    onSwipedRight: () =>
      handleSelect(selected === 0 ? total - 1 : selected - 1),
  });

  useEffect(() => {
    interval.current = setInterval(() => {
      setSelected((prev) => (prev + 1) % total);
    }, 5000);
    return () => clearInterval(interval.current);
  }, [total]);

  useEffect(() => {
    const scrollHandler = (e) => {
      const selected = parseInt(e.currentTarget.dataset.selected);
      const total = parseInt(e.currentTarget.dataset.total);
      const timer = parseInt(e.currentTarget.dataset.timer);

      if (selected === 0 && e.deltaY < 0) {
      } else if (selected === total - 1 && e.deltaY > 0) {
      } else {
        e.preventDefault();
        const newTimer = new Date().getTime();
        if (timer > 0 && timer > newTimer - 250) {
          e.currentTarget.dataset.timer = newTimer;
          return;
        }
        e.currentTarget.dataset.timer = newTimer;
        if (e.deltaY > 0) {
          handleSelect(selected + 1);
        } else {
          handleSelect(selected - 1);
        }
      }
    };

    const sect = section.current;

    if (sect) {
      sect.addEventListener("wheel", scrollHandler);
    }
    return () => {
      if (sect) {
        sect.removeEventListener("wheel", scrollHandler);
      }
    };
  }, []);
  return (
    <Stack
      ref={section}
      height={{ xxs: 732, lg: 930 }}
      position="relative"
      onMouseEnter={() => setCursorType("blur")}
      onMouseLeave={() => setCursorType("trail")}
      data-total={total}
      data-selected={selected}
      data-timer={0}
      {...swipeHandlers}
    >
      <Wrapper>
        <Stack position="relative">
          <Stack
            position="absolute"
            left={0}
            top={0}
            height={{ xxs: 732, lg: 930 }}
            zIndex={30}
            pt={{ xxs: 15.5, lg: 17.75 }}
            pb={{ xxs: 8, lg: 9.5 }}
            maxWidth={{ xxs: "90%", md: "80%" }}
            direction="column"
            justifyContent="space-between"
          >
            <Stack spacing={0.25} alignItems="start">
              <CustomProgressBar
                selected={selected}
                total={total}
                handleSelect={handleSelect}
              />
              <Stack pt={3.5} overflow="hidden" sx={{ pointerEvents: "none" }}>
                <CustomDescription
                  data={slides[selected].project.data.attributes.description}
                />
              </Stack>
            </Stack>
            <CustomTitle data={slides[selected].project.data.attributes} />
          </Stack>
          {isDesktop && (
            <CustomControls
              selected={selected}
              total={total}
              handleSelect={handleSelect}
            />
          )}
        </Stack>
      </Wrapper>
      {slides.map((i, idx) => (
        <CustomSlide
          key={"custom-slider" + idx}
          data={i.project.data.attributes}
          selected={selected}
          total={total}
          pos={idx}
          handleSelect={handleSelect}
        />
      ))}
    </Stack>
  );
}
