import styled from "styled-components";

export const Card = styled.a`
  /* border-radius: 15px; */

  background: transparent linear-gradient(107deg, transparent 0%, #f6faff 100%)
    0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media screen and (min-width: 780px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (min-width: 1280px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
