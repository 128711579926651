import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 430,
      sm: 780,
      md: 1024,
      lg: 1280,
      lg2: 1600,
      xl: 1920,
    },
  },
  palette: {
    custom: {
      accent: "#0066FF",
      primary: "#38B3F6",
      secondary: "#E0EEFF",
      text: "#0E232E",
      accentLink: "#38B3F6",
      link: "#343434",
      ph: "#707070",
      phLight: "#CECECE",
      white: "#FFFFFF",
      bg: "#DBEBFF",
      bgLight: "#F6FAFF",
      bgTag: "#E6F3FC",
      bgTagLight: "#F8F8F8",
      bgPartner: "#F7FBFF",
      bgLow: "#FAFAFA",
      bgBottom: "#F5F9FF",
      bgHighlighted: "#EBEBEB",
      controls: "#FFA200",
      success: "#0FC100",
      danger: "#FF4C2B",
      border: "#E6E6E6",
      input: "#E8E8E8",
      disabled: "#E8EBED",
      label: "#FF8A2B",
    },
  },
  typography: {
    fontFamily: ["Area-Normal", "Libre Baskerville"].join(", "),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#38B3F6",
          },
          "& label.MuiInputLabel-shrink": {
            color: "#38B3F6",
          },
          "& label.Mui-error": {
            color: "#FF4C2B",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: "1px solid #E8E8E8",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "1px solid #38B3F6",
          },
          "&.Mui-focused:after": {
            borderBottom: "1px solid #38B3F6",
          },
          "&.Mui-error::after": {
            borderBottomColor: "#FF4C2B",
          },
          "&.Mui-error::before": {
            borderBottomColor: "#FF4C2B",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#FF4C2B",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 10,
          },
        },
        label: {
          fontSize: 14,
        },
      },
    },
  },
});
