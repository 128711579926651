import { createContext, useContext, useState, useEffect } from "react";

const OptionsContext = createContext();

export const useOptions = () => useContext(OptionsContext);

export const OptionsProvider = ({ children }) => {
  const [isHeroInstalled, setIsHeroInstalled] = useState(false);
  const [mainContacts, setMainContacts] = useState(null);
  const [collectionsPages, setCollectionsPages] = useState(null);
  const [isOnTop, setIsOnTop] = useState(true);
  const [cursorType, setCursorType] = useState("trail");
  const [form, setForm] = useState(null);
  const [palette, setPalette] = useState({
    primary: "#38B3F6",
    secondary: "#DBEBFF",
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsOnTop(true);
      } else {
        setIsOnTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <OptionsContext.Provider
      value={{
        isHeroInstalled,
        setIsHeroInstalled,
        mainContacts,
        setMainContacts,
        isOnTop,
        cursorType,
        setCursorType,
        form,
        setForm,
        palette,
        setPalette,
        collectionsPages,
        setCollectionsPages,
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
};
