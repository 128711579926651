import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import Wrapper from "../../common/Wrapper";
import { useParallax } from "react-scroll-parallax";
import RichText from "../../common/RichText/RichText";
import { getVacancies } from "../../../services/getVacancies";
import { Link } from "react-router-dom";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";

export default function Vacancies({ data }) {
  const { palette } = useOptions();
  const [vacancies, setVacancies] = useState([]);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    getVacancies(data.type).then((resData) => {
      setVacancies(resData.data);
    });
  }, [data.type]);

  const parallax = useParallax({
    speed: -5,
  });
  return (
    <Stack
      component="section"
      pt={8}
      pb={4.5}
      id={data.type === "WORK" ? "work" : "trainship"}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Stack
          mb={6.5}
          display="grid"
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          gap={2}
          ref={parallax.ref}
        >
          <Stack justifyContent="end" position="relative">
            <Typography
              fontSize={{ xxs: 68, lg: 100 }}
              fontWeight={600}
              lineHeight={1.1}
              component="h2"
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>
          </Stack>
          {data.description && (
            <Stack justifyContent="end">
              <RichText
                data={data.description}
                fontSize={24}
                lineHeight={1.33}
                paragraphMargin={2}
              />
            </Stack>
          )}
        </Stack>
        <Stack
          component="ul"
          display="grid"
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          rowGap={{ xxs: 6, lg: 13 }}
          columnGap={2}
        >
          {vacancies.map((i, idx) => (
            <Stack
              key={i.attributes.type + "vacancy" + idx}
              component="li"
              sx={
                idx <
                vacancies.length -
                  (data.afterWords ? 1 : 2) +
                  (isDesktop ? 0 : 1)
                  ? {
                      position: "relative",
                      "&:nth-of-type(odd)::after": {
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "-50vw",
                        width: "200vw",
                        height: "1px",
                        zIndex: 1,
                        backgroundColor: "#E3E3E3",
                      },
                      "&:nth-of-type(even)::after": {
                        display: isDesktop ? "none" : "block",
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: "-50vw",
                        width: "200vw",
                        height: "1px",
                        zIndex: 1,
                        backgroundColor: "#E3E3E3",
                      },
                    }
                  : {}
              }
            >
              <Stack
                height="100%"
                pb={5}
                justifyContent="space-between"
                gap={2}
                component={Link}
                to={`/vacancies/${i.attributes.slug}`}
                sx={{
                  svg: {
                    color: "custom.controls",
                  },
                  "&:hover svg": {
                    color: "custom.primary",
                    transform: "rotate(-45deg)",
                  },
                }}
              >
                <Stack>
                  <Typography
                    component="span"
                    fontSize={18}
                    color="custom.primary"
                    lineHeight={1.33}
                    fontWeight={600}
                    mb={1}
                    pl={0.25}
                  >
                    {i.attributes.label}
                  </Typography>
                  <Typography
                    component="span"
                    fontSize={60}
                    lineHeight={1.33}
                    fontWeight={600}
                    sx={{ overflowWrap: "anywhere" }}
                  >
                    {i.attributes.title}
                  </Typography>
                  <Stack direction="row" gap={2.5}>
                    <Typography
                      component="span"
                      fontSize={18}
                      lineHeight={1.33}
                      pl={0.25}
                    >
                      {i.attributes.location}
                    </Typography>
                    <Typography
                      component="span"
                      fontSize={18}
                      lineHeight={1.33}
                    >
                      {i.attributes.workingHours}
                    </Typography>
                  </Stack>
                </Stack>
                <svg
                  style={{
                    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M7 7l9.2 9.2M17 7v10H7" />
                </svg>
              </Stack>
            </Stack>
          ))}
          {data.afterWords && (
            <Stack component="li" pr={{ lg: "30%" }} justifyContent="center">
              <RichText data={data.afterWords} fontSize={24} />
            </Stack>
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
