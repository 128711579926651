import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";

export default function CustomProgressBar({ selected, total, handleSelect }) {
  const [buttons, setButtons] = useState([]);

  function normalizeNumberToText(number) {
    const numberString = (number + 1).toString();

    if (numberString.length === 1) {
      return "0" + numberString;
    } else {
      return numberString;
    }
  }
  useEffect(() => {
    const btns = [];
    for (let i = 0; i < total; i++) {
      btns.push(
        <Typography
          key={"select-slide-btn" + i}
          color="custom.white"
          component="button"
          type="button"
          aria-label={"Select slide " + normalizeNumberToText(i)}
          onClick={() => handleSelect(i)}
          sx={{
            cursor: "pointer",
            fontSize: 14,
            opacity: selected === i ? 1 : 0.4,
            fontWeight: 600,
            transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": {
              opacity: 1,
            },
            "&:focus": {
              opacity: 1,
            },
          }}
        >
          {normalizeNumberToText(i)}
        </Typography>
      );
    }
    setButtons(btns);
  }, [handleSelect, selected, total]);

  return (
    <Stack spacing={3} minWidth={260}>
      <Stack direction="row" spacing={2.5}>
        {buttons}
      </Stack>
      <Stack width="100%" position="relative">
        <Stack
          width="100%"
          height={2}
          bgcolor="custom.secondary"
          sx={{ opacity: 0.6 }}
        ></Stack>
        <Stack
          width={`${(100 * (selected + 1)) / total}%`}
          height={2}
          position="absolute"
          top={0}
          left={0}
          bgcolor="custom.white"
          sx={{ transition: "width 500ms cubic-bezier(0.4, 0, 0.2, 1)" }}
        ></Stack>
      </Stack>
    </Stack>
  );
}
