import React from "react";
import { Hidden, HiddenMain } from "./HiddenTitle.styled";

export default function HiddenTitle({ children, isMain }) {
  return (
    <>
      {isMain ? (
        <HiddenMain>{children.replace(/\*/g, " ")}</HiddenMain>
      ) : (
        <Hidden>{children.replace(/\*/g, " ")}</Hidden>
      )}
    </>
  );
}
