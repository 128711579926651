import axios from "axios";

export const getPageData = async (slug, collection = "pages") => {
  const filteredArr = await axios.get(
    `/api/${collection}?filters[slug][$eq]=${slug}`
  );
  const id = filteredArr.data.data[0]?.id;
  if (!id) {
    throw new Error("Page not found");
  }
  const response = await axios.get(
    `/api/${collection}/${id}?populate[blocks][populate][buttons][populate]=*&populate[blocks][populate][partners][populate]=*&populate[blocks][populate][partner][populate]=*&populate[blocks][populate][projects][populate]=*&populate[blocks][populate][project][populate]=*&populate[blocks][populate][bestProject][populate]=*&populate[blocks][populate][tags][populate]=*&populate[blocks][populate][link][populate]=*&populate[blocks][populate][links][populate]=*&populate[blocks][populate][image][populate]=*&populate[blocks][populate][images][populate]=*&populate[blocks][populate][page][populate]=*&populate[blocks][populate][main][populate]=*&populate[blocks][populate][cards][populate][service][populate]=*&populate[blocks][populate][cards][populate][link][populate]=*&populate[blocks][populate][cards][populate][links][populate]=*&populate[blocks][populate][cases][populate]=*&populate[blocks][populate][slides][populate][project][populate]=*`
  );
  return response.data;
};

// {
//   populate: {
//     blocks: {
//       populate: {
//         buttons: {
//           populate: "*"
//         },
//         partners: {
//           populate: "*"
//         },
//         partner: {
//           populate: "*"
//         },
//         projects: {
//           populate: "*"
//         },
//         project: {
//           populate: "*"
//         },
//         bestProject: {
//           populate: "*"
//         },
//         tags: {
//           populate: "*"
//         },
//         link: {
//           populate: "*"
//         },
//         links: {
//           populate: "*"
//         },
//         image: {
//           populate: "*"
//         },
//         images: {
//           populate: "*"
//         },
//         page: {
//           populate: "*"
//         },
//         main: {
//           populate: "*"
//         },
//         cards: {
//           populate: {
//             service: {
//               populate: "*"
//             },
//             link: {
//               populate: "*"
//             },
//             links: {
//               populate: "*"
//             }
//           }
//         },
//         cases: {
//           populate: "*"
//         },
//         slides: {
//           populate: {
//             project: {
//               populate: "*"
//             }
//           }
//         },
//       }
//     }
//   },
// }
