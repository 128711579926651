import styled from "styled-components";

export const Section = styled.section`
  background: ${({ $secondary, $addBackground }) =>
    $addBackground
      ? `transparent linear-gradient(100deg, ${$secondary} 0%, #f6faff 100%) 0% 0% no-repeat padding-box`
      : ""};
  padding-top: 80px;
  padding-bottom: 68px;
`;
