import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPageData } from "../../services/getPageData";
import { renderLayoutFromPageData } from "../../helpers/renderLayoutFromPageData";
import { useOptions } from "../../hooks/useOptions";
import Error from "../../components/common/Error/Error";
import { servicesColors } from "../../helpers/servicesColors";
import { AnimatePresence, motion } from "framer-motion";

function Regular({ collection = "pages" }) {
  const [data, setData] = useState(null);
  const location = useLocation();
  const { slug } = useParams();
  const { setIsHeroInstalled, setPalette, collectionsPages } = useOptions();
  const [isError, setIsError] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  useEffect(() => {
    setIsError(false);
  }, [slug, setIsError]);

  useEffect(() => {
    getPageData(slug ?? "home", collection)
      .then((data) => setData(data))
      .catch(() => setIsError(true));
  }, [slug, collection]);

  useEffect(() => {
    if (!data) return;
    if (data.data.attributes.color) {
      setPalette(servicesColors[data.data.attributes.color]);
    } else {
      setPalette(servicesColors["MAIN"]);
    }
  }, [data, setPalette]);

  useEffect(() => {
    if (!data) return;
    if (
      data.data.attributes.blocks.some(
        (i) =>
          i.__component === "blocks.hero" ||
          i.__component === "blocks.case-hero"
      )
    ) {
      setIsHeroInstalled(true);
    } else {
      setIsHeroInstalled(false);
    }
  }, [data, setIsHeroInstalled]);

  useEffect(() => {
    if (!data) return;
    if (!collectionsPages) return;
    const breadcrumbsData = {
      title: data.data.attributes.title,
      slug: data.data.attributes.slug,
      parent:
        collection === "pages"
          ? null
          : collectionsPages[collection].page.data.attributes,
    };
    setBreadcrumbs(breadcrumbsData);
  }, [collection, collectionsPages, data]);

  return (
    <AnimatePresence mode="wait">
      {data && breadcrumbs && (
        <motion.div
          className="page-content-container"
          key={"page-content" + location.pathname}
          initial={{ x: "50vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: "-50vw", opacity: 0 }}
          transition={{ duration: 1.25, ease: [0.4, 0, 0.2, 1] }}
        >
          {renderLayoutFromPageData(data.data.attributes.blocks, breadcrumbs)}
        </motion.div>
      )}
      {isError && <Error />}
    </AnimatePresence>
  );
}

export default Regular;
