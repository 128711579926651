import { Image, Section } from "./Hero.styled";
import Wrapper from "../../common/Wrapper";
import { Stack, Typography } from "@mui/material";
import RichText from "../../common/RichText/RichText";
import { useOptions } from "../../../hooks/useOptions";
import LinkButton from "../../elements/LinkButton/LinkButton";
import { useParallax } from "react-scroll-parallax";
import LinkButtonStyled from "../../elements/LinkButton/LinkButtonStyled";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import AnimatedText from "../../elements/AnimatedText/AnimatedText";
import BreadcrumbsLink from "../../elements/BreadcrumbsLink/BreadcrumbsLink";

const api = process.env.REACT_APP_API_URL;

export default function Hero({
  data: {
    addMainContacts,
    callToAction,
    image,
    link,
    highlightingWords,
    useLargeSpaces,
    useLargeText,
    title,
    text,
    showTitle,
    isSmallTitle,
    linkBelowCTA,
  },
  breadcrumbs,
}) {
  const { mainContacts, setCursorType } = useOptions();
  const parallax = useParallax({
    speed: -40,
  });
  return (
    <Section
      $largeSpaces={useLargeSpaces}
      onMouseEnter={() => setCursorType("blur")}
      onMouseLeave={() => setCursorType("trail")}
    >
      <Wrapper
        display="grid"
        gridTemplateColumns={{ lg: "6fr 4fr" }}
        zIndex={5}
      >
        <Stack alignItems="start">
          <Stack
            mb={
              useLargeSpaces
                ? { xxs: 6.75, lg: 20 }
                : !text && linkBelowCTA
                ? 0
                : "40px"
            }
            alignItems="start"
          >
            {showTitle && (
              <Typography
                component="h1"
                fontWeight={600}
                fontSize={isSmallTitle ? 36 : 48}
                color="custom.white"
                mb={isSmallTitle ? 0 : 4}
                pr={{ xxs: 5, sm: 10, lg: 0 }}
              >
                {title}
              </Typography>
            )}
            {!showTitle && title && (
              <HiddenTitle component="h1" isMain>
                {title}
              </HiddenTitle>
            )}
            {text && (
              <Stack pr={{ lg: 20 }}>
                <RichText
                  data={text}
                  dark={false}
                  fontSize={24}
                  paragraphMargin={3}
                />
              </Stack>
            )}
            {link && !linkBelowCTA && (
              <LinkButtonStyled
                data={link}
                color="custom.white"
                fontSize={22}
                gap={1.5}
                underlineColor="custom.ph"
                svgColor="custom.white"
              />
            )}
          </Stack>
          {callToAction && (
            <Typography
              fontSize={
                useLargeText ? { xxs: 72, lg: 140 } : { xxs: 68, lg: 100 }
              }
              fontWeight={600}
              lineHeight={1.24}
              color="custom.white"
              mb={link && linkBelowCTA ? 4 : 0}
              sx={{ overflowWrap: "anywhere" }}
            >
              <AnimatedText
                text={callToAction}
                highlight={highlightingWords ?? ""}
              />
            </Typography>
          )}
          {link && linkBelowCTA && (
            <LinkButtonStyled
              data={link}
              color="custom.white"
              fontSize={22}
              gap={1.5}
              underlineColor="custom.ph"
              svgColor="custom.white"
            />
          )}
        </Stack>
        {addMainContacts && mainContacts && (
          <Stack
            alignItems="end"
            justifyContent="end"
            pt={{ xxs: 6, lg: 0 }}
            sx={{
              transform: {
                lg: `translateY(${useLargeSpaces ? "-98px" : "-20px"})`,
              },
            }}
          >
            {mainContacts.map((i, idx) => (
              <LinkButton
                key={"mainContachts-hero" + idx}
                data={i}
                sx={{
                  color: "custom.white",
                  fontSize: 24,
                  lineHeight: 1.8,
                }}
              />
            ))}
          </Stack>
        )}
        {breadcrumbs.slug !== "home" && (
          <Stack
            position="absolute"
            zIndex={4}
            bottom={40}
            direction="row"
            gap={0.5}
          >
            <BreadcrumbsLink title="Home" />
            {breadcrumbs.parent && (
              <>
                <Typography
                  component="span"
                  color="custom.white"
                  lineHeight={1.375}
                  fontSize={16}
                >
                  /
                </Typography>
                <BreadcrumbsLink
                  title={breadcrumbs.parent.title}
                  to={"/" + breadcrumbs.parent.slug}
                />
              </>
            )}
            <Typography
              component="span"
              color="custom.white"
              lineHeight={1.375}
              fontSize={16}
            >
              /
            </Typography>
            <BreadcrumbsLink
              title={breadcrumbs.title}
              to={"/" + breadcrumbs.slug}
            />
          </Stack>
        )}
      </Wrapper>
      <Stack
        position="absolute"
        top="-18%"
        bottom={0}
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        <Image
          src={api + image.data.attributes.url}
          alt={image.data.attributes.alternativeText ?? title}
        />
      </Stack>
    </Section>
  );
}
