import { useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./LocationItemAbout.css";
import RichText from "../../common/RichText/RichText";

export default function LocationItemAbout({ data }) {
  const nodeRef = useRef(null);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={data.id}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div ref={nodeRef}>
          <RichText data={data.about} />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}
