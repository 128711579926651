import React from "react";
import LinkButton from "./LinkButton";

export default function LinkButtonFooterSocials({
  data,
  isLight = false,
  fontSize = 14,
}) {
  return (
    <LinkButton
      data={data}
      sx={{
        color: isLight ? "custom.white" : "custom.ph",
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        fontSize,
        "&:hover": { color: "custom.primary" },
        "&:focus": { color: "custom.primary" },
      }}
    />
  );
}
