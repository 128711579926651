import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { getCollesctionCount } from "../../../services/getCollectionCount";
import { Link } from "./MainNavItem.styled";

const collectionsDict = {
  NEWS: "news",
  TEAM: "members",
  PROJECTS: "projects",
  VACANCIES: "vacancies",
  SERVICES: "services",
  PARTNERS: "partners",
};

export default function MainNavItem({
  isLight,
  data: {
    title,
    isHighlighted,
    addCounterFor,
    page: {
      data: {
        attributes: { slug },
      },
    },
  },
}) {
  const [counter, setCounter] = useState(0);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (!addCounterFor) return;
    getCollesctionCount(collectionsDict[addCounterFor]).then((i) =>
      setCounter(i)
    );
  }, [addCounterFor]);

  return (
    <Link to={"/" + slug} $isLight={isLight}>
      <Stack position="relative">
        <Typography
          component="span"
          fontSize={{ xxs: 28, lg: 18 }}
          lineHeight={{ xxs: 2.2, lg: 1.33 }}
          fontStyle={isHighlighted ? "italic" : "normal"}
          fontFamily={isHighlighted ? "Libre Baskerville" : "Area-Normal"}
        >
          {title}
        </Typography>
        {counter > 0 && (
          <Stack
            position="absolute"
            top={{ xxs: 12, lg: -8 }}
            right={{ xxs: -10, lg: -8 }}
            fontSize={10}
            color="custom.controls"
            fontWeight={600}
          >
            {counter}
          </Stack>
        )}
      </Stack>
      {!isDesktop && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M7 7l9.2 9.2M17 7v10H7" />
        </svg>
      )}
    </Link>
  );
}
