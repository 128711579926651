import styled from "styled-components";

export const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  /* transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1); */
  /* &:hover {
    transform: scale(1.03);
  } */
`;
