import { Stack } from "@mui/material";
import React from "react";
import { Image } from "./Gallery.styled";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

export default function SlideImage({ data, pos }) {
  const parallax = useParallax({
    speed: pos % 2 === 0 ? -3 : -7,
  });
  return (
    <Stack
      overflow="hidden"
      position="relative"
      borderRadius={3.75}
      sx={{ aspectRatio: "1/1" }}
    >
      <Stack
        position="absolute"
        top={pos % 2 === 0 ? "-5%" : "-11%"}
        bottom={pos % 2 === 0 ? "-5%" : "-11%"}
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        {" "}
        <Image
          src={api + data.attributes.url}
          alt={data.attributes.alternativeText}
        />
      </Stack>
    </Stack>
  );
}
