import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer/Footer";
import { getOptions } from "../../../services/getOptions";
import { useOptions } from "../../../hooks/useOptions";
import CursorBlur from "../Cursor/CursorBlur";
import CursorNews from "../Cursor/CursorNews";
import CursorTrail from "../Cursor/CursorTrail";
import CursorSlider from "../Cursor/CursorSlider";
import ScrollToTop from "../ScrollToTop";
import LeadGenForm from "../LeadGenForm/LeadGenForm";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import LoaderLogo from "../../elements/Logo/LoaderLogo";
import Wrapper from "../Wrapper";

const cursors = {
  trail: <CursorTrail />,
  blur: <CursorBlur />,
  news: <CursorNews />,
  slider: <CursorSlider />,
};

export default function SharedLayout({ children }) {
  const [options, setOptions] = useState(null);
  const location = useLocation();
  const { setMainContacts, cursorType, setCollectionsPages } = useOptions();

  useEffect(() => {
    getOptions()
      .then((data) => setOptions(data))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (!options) return;
    setCollectionsPages(options.collectionsPages);
    setMainContacts(options.mainContacts);
  }, [options, setCollectionsPages, setMainContacts]);

  return (
    <>
      <ScrollToTop />
      <Header options={options} />
      <main>
        {children ?? <Outlet />}
        {options?.leadGenForm && <LeadGenForm data={options.leadGenForm} />}
      </main>
      <Footer options={options} /> {cursors[cursorType]}
      <AnimatePresence mode="wait">
        <motion.div
          key={"loader-transition-in" + location.pathname}
          className="loader-transition-in"
          initial={{ x: 0 }}
          animate={{
            x: 0,
          }}
          exit={{
            x: "-100vw",
          }}
          transition={{ duration: 1.25, ease: [0.4, 0, 0.2, 1] }}
        >
          <motion.div
            key={"loader-transition-in-container" + location.pathname}
            initial={{ x: 0 }}
            animate={{ x: "-100vw" }}
            exit={{ x: 0 }}
            transition={{ duration: 1.25, ease: [0.4, 0, 0.2, 1] }}
          >
            <Wrapper
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <LoaderLogo />
            </Wrapper>
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <motion.div
          key={"loader-transition-out" + location.pathname}
          className="loader-transition-in"
          initial={{ x: "-100vw" }}
          animate={{
            x: "-200vw",
          }}
          exit={{
            x: "-200vw",
          }}
          transition={{ duration: 1.25, ease: [0.4, 0, 0.2, 1] }}
        >
          <motion.div
            key={"loader-transition-out-container" + location.pathname}
            initial={{ x: 0 }}
            animate={{ x: "100vw" }}
            exit={{ x: 0 }}
            transition={{ duration: 1.25, ease: [0.4, 0, 0.2, 1] }}
          >
            <Wrapper
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <LoaderLogo />
            </Wrapper>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </>
  );
}
