import React from "react";
import LinkButton from "./LinkButton";
import { Stack, Typography } from "@mui/material";
import { colors } from "../../../helpers/colors";

export default function LinkButtonAccent({ data }) {
  return (
    <LinkButton
      data={data}
      sx={{
        bgcolor: colors[data.color] ?? "custom.accent",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        pl: 4,
        height: 50,
        borderRadius: 25,
        "& div": {
          transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        },
        "&:hover div": {
          transform: `rotate(-45deg)`,
        },
        "&:focus div": {
          transform: `rotate(-45deg)`,
        },
      }}
    >
      <Typography
        component="span"
        fontSize={14}
        fontWeight={600}
        lineHeight={1}
        color="custom.white"
        sx={{
          position: "relative",
        }}
      >
        {data.title}
      </Typography>

      <Stack
        width={50}
        height={50}
        borderRadius="50%"
        border="1px dashed #fff"
        color="custom.white"
        alignItems="center"
        justifyContent="center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M7 7l9.2 9.2M17 7v10H7" />
        </svg>
      </Stack>
    </LinkButton>
  );
}
