import { Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import LogoSVG from "./LogoSVG";

export default function Logo({ isLight, full }) {
  return (
    <Stack>
      <Stack
        direction="row"
        position="relative"
        width={{ xxs: 148, md: 181 }}
        height={{ xxs: 35, md: 43 }}
        component={Link}
        to="/"
        alignItems="end"
        aria-label="Naar de Webba-startpagina"
        sx={{
          transform: full
            ? "translateX(0) translateY(0)"
            : {
                xxs: "translateX(-116px) translateY(-5px)",
                md: "translateX(-143px) translateY(-5px)",
              },
          transition: "transform 500ms linear 250ms",
          transformOrigin: "0% 100%",

          "& svg": {
            fill: isLight ? "#38B3F6" : "#fff",
          },
          // "&:hover svg": {
          //   fill: "#38B3F6",
          // },
          // "&:focus svg": {
          //   fill: "#38B3F6",
          // },
        }}
      >
        <Stack sx={{ transform: { xxs: "scale(0.81)", md: "none" } }}>
          <LogoSVG full={full} />
        </Stack>
      </Stack>
    </Stack>
  );
}
