import { Stack } from "@mui/material";
import { useState, useEffect } from "react";

const api = process.env.REACT_APP_API_URL;

const stylesDict = {
  prevHidden: {
    zIndex: 1,
  },
  prev: {
    zIndex: 2,
  },
  curr: {
    zIndex: 3,
  },
  next: {
    transform: "scale(0.41) translateX(140%)",
    boxShadow: "3px 10px 36px #0E232E4D",
    borderRadius: 30,
    cursor: "pointer",
    zIndex: 5,
  },
  hidden: {
    pointerEvents: "none",
    opacity: 0,
    transform: "scale(0.2) translateX(330%)",
    boxShadow: "3px 10px 36px #0E232E4D",
    borderRadius: 30,
    zIndex: 6,
  },
};

export default function CustomSlide({
  data,
  selected,
  total,
  pos,
  handleSelect,
}) {
  const [status, setStatus] = useState("hidden");

  useEffect(() => {
    if (pos === selected) {
      setStatus("curr");
    } else if (pos === selected - 1) {
      setStatus("prev");
    } else if (pos === selected + 1) {
      setStatus("next");
    } else if (pos < selected) {
      setStatus("prevHidden");
    } else {
      setStatus("hidden");
    }
  }, [pos, selected, total]);

  return (
    <>
      <Stack
        onClick={() => handleSelect(pos)}
        overflow="hidden"
        position="absolute"
        height="100%"
        width="100%"
      >
        <img
          src={`${api}${data.image.data.attributes.url}`}
          alt={data.image.data.attributes.alternativeText ?? data.title}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "100%",
            transition:
              "transform 500ms cubic-bezier(0.4, 0, 0.2, 1), opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            ...stylesDict[status],
          }}
        />
      </Stack>
    </>
  );
}
