import { Stack, Typography } from "@mui/material";
import React from "react";
import ControlButton from "../ControlButton/ControlButton";

export default function CustomControls({ selected, total, handleSelect }) {
  return (
    <Stack
      position="absolute"
      right={0}
      top={816}
      zIndex={30}
      direction="row"
      alignItems="center"
    >
      <Stack direction="row">
        <ControlButton
          handler={() =>
            handleSelect(selected === 0 ? total - 1 : selected - 1)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H6M12 5l-7 7 7 7" />
          </svg>
        </ControlButton>
        <ControlButton handler={() => handleSelect((selected + 1) % total)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h13M12 5l7 7-7 7" />
          </svg>
        </ControlButton>
      </Stack>
      <Typography color="custom.white" ml={3.5} fontWeight={600}>
        scroll / sleep
      </Typography>
    </Stack>
  );
}
