import { Stack, Typography } from "@mui/material";
import React from "react";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import Wrapper from "../../common/Wrapper";
import LinkButton from "../../elements/LinkButton/LinkButton";

const api = process.env.REACT_APP_API_URL;

export default function Testimonial({ data }) {
  return (
    <Stack component="section" py={6.5}>
      <HiddenTitle>{data.title}</HiddenTitle>
      <Wrapper
        display={{ xxs: "flex", lg: "grid" }}
        flexDirection={{ xxs: "column-reverse", lg: "row" }}
        alignItems={{ xxs: "center", lg: "start" }}
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        gap={2}
      >
        <Stack alignItems="end" width={{ sm: "60%", lg: "auto" }}>
          <Stack spacing={4.75} width={{ lg: "60%" }} pr={1.75}>
            <Typography fontSize={18} lineHeight={1.89}>
              {data.text}
            </Typography>
            <Stack
              direction="row"
              gap={1.25}
              display={{ xxs: "none", lg: "flex" }}
            >
              <Typography fontWeight={600}>{data.author}</Typography>
              {data.link && (
                <LinkButton
                  data={data.link}
                  sx={{ color: "custom.accentLink" }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          width={{ sm: "60%", lg: "auto" }}
          pt={{ lg: 2.5 }}
          direction="row"
          alignItems={{ xxs: "center", lg: "start" }}
          gap={3}
        >
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="custom.bgPartner"
            borderRadius={3.75}
            width={120}
            sx={{ aspectRatio: "1/1" }}
          >
            <Stack
              height="100%"
              component="a"
              target="_blank"
              href={data.partner.data.attributes.link}
              justifyContent="center"
              alignItems="center"
              sx={{
                "&:hover img": { filter: "grayscale(0%) !important" },
                "&:focus img": { filter: "grayscale(0%) !important" },
              }}
            >
              <img
                src={
                  api + data.partner.data.attributes.image.data.attributes.url
                }
                alt={
                  data.partner.data.attributes.image.data.attributes
                    .alternativeText
                }
                style={{
                  maxWidth: "80%",
                  maxHeight: "40%",
                  filter: "grayscale(100%)",
                  transition: "filter 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            gap={1.25}
            display={{ xxs: "flex", lg: "none" }}
          >
            <Typography fontWeight={600}>{data.author}</Typography>
            {data.link && (
              <LinkButton
                data={data.link}
                sx={{ color: "custom.accentLink" }}
              />
            )}
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
