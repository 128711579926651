import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

export default function AnimatedLetter({
  children,
  baseDelay,
  baseTime,
  delay,
  time,
}) {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const constants = useRef(null);

  useEffect(() => {
    constants.current = {
      time: Math.floor(Math.random() * time + baseTime),
      delay: Math.floor(Math.random() * delay) + baseDelay,
    };
  }, [baseDelay, baseTime, delay, time]);

  return (
    <span
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transition: `opacity ${
          constants?.current?.time ?? baseTime
        }ms cubic-bezier(0.4, 0, 0.2, 1) ${
          constants?.current?.delay ?? baseDelay
        }ms`,
      }}
    >
      {children}
    </span>
  );
}
