import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import ContactForm from "./ContactForm";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { useParallax } from "react-scroll-parallax";

export default function Contact({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -3,
  });
  const parallaxTitle = useParallax({
    speed: -6,
  });
  return (
    <Stack
      component="section"
      pt={8}
      pb={8}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Typography
          ref={parallaxTitle.ref}
          component="h2"
          fontSize={{ xxs: 48, lg: 36 }}
          lineHeight={1.33}
          fontWeight={600}
          color="custom.primary"
          mb={6}
          dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
        ></Typography>
        <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
          <Stack pr={{ lg: "20%" }} ref={parallax.ref}>
            <RichText
              data={data.description}
              fontSize={18}
              headingMargin={3}
              lineHeight={2.22}
              headingHeight={1.67}
            />
          </Stack>
          <Stack pt={1}>
            <ContactForm />
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
