import React from "react";
import MainNavItem from "./MainNavItem";
import { Stack, useMediaQuery } from "@mui/material";

export default function MainNavigation({
  isLight = true,
  data,
  handler = () => {},
}) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return (
    <nav>
      <Stack
        component="ul"
        direction={{ xxs: "column", lg: "row" }}
        gap={{ xxs: 0, lg: 3, lg2: 4.25 }}
        pl={{ xxs: 2, lg: 10 }}
        pr={{ xxs: 1, lg: 0 }}
      >
        {!isDesktop && (
          <li onClick={handler}>
            <MainNavItem
              isLight={isLight}
              data={{
                title: "Home",
                isHighlighted: false,
                addCounterFor: null,
                page: {
                  data: {
                    attributes: { slug: "" },
                  },
                },
              }}
            />
          </li>
        )}
        {data.map((i, idx) => (
          <li key={"main-nav" + idx} onClick={handler}>
            <MainNavItem isLight={isLight} data={i} />
          </li>
        ))}
      </Stack>
    </nav>
  );
}
