import { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

export function Swiper(props) {
  const swiperRef = useRef(null);
  const {
    children,
    height = "auto",
    isAsymmetric = false,
    isSmall = false,
    ...rest
  } = props;

  useEffect(() => {
    register();

    const params = {
      injectStyles: [
        `
        .swiper-horizontal > .swiper-scrollbar {
          background: #E0EEFF;
          height: 2px;
          bottom: 0;
          @media screen and (min-width: 780px) {
            ${
              isAsymmetric
                ? `width: calc(100% - 2% - ${isSmall ? "80px" : "40px"});`
                : ""
            }
          }
          @media screen and (min-width: 12800px) {
            ${
              isAsymmetric
                ? `width: calc(100% - 2% - ${isSmall ? "160px" : "80px"});`
                : ""
            }
          }
          @media screen and (min-width: 1920px) {
            ${
              isAsymmetric
                ? `width: calc(100% - 2% - ${isSmall ? "276px" : "140px"});`
                : ""
            }
          }
        }
        .swiper-scrollbar-drag {
          background: #38B3F6;
        }
      `,
      ],
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      ...rest,
    };

    Object.assign(swiperRef.current, params);

    swiperRef.current.initialize();
  }, []);

  return (
    <swiper-container style={{ height }} init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
}
export function SwiperSlide(props) {
  const { children, height = "auto", paddingBottom = 0, ...rest } = props;

  return (
    <swiper-slide style={{ height, paddingBottom }} {...rest}>
      {children}
    </swiper-slide>
  );
}
