import { Link } from "react-router-dom";
import styled from "styled-components";

export const Element = styled.li`
  /* background-image: url(${(props) => props.$src});
  background-size: cover;
  background-position: top 25% center; */
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const LinkElement = styled(Link)`
  z-index: 4;
  display: block;
  width: 100%;
  background-color: ${(props) => (props.$pos % 2 === 0 ? "#fff" : "#FAFAFA")};
  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: inherit;
  @media screen and (min-width: 1280px) {
    min-height: 372px;
  }
  h3,
  span,
  p {
    transition: color 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &:hover,
  &:focus {
    background-color: transparent;
  }
  &:hover h3,
  &:focus h3,
  &:hover span.attr,
  &:focus span.attr,
  &:hover p,
  &:focus p {
    color: #fff;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  object-position: top center;
  height: 100%;
  width: 100%;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;
