import * as Yup from "yup";

export const schemaContactForm = Yup.object().shape({
  name: Yup.string().min(2, "Te kort!").required("Dit is een verplicht veld."),
  email: Yup.string()
    .email("Ongeldig e-mail")
    .required("Dit is een verplicht veld."),
  description: Yup.string(),
  privacy: Yup.boolean().oneOf([true], "Dit is een verplicht veld."),
  subscribe: Yup.boolean(),
});
