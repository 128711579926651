import React from "react";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import { useOptions } from "../../../hooks/useOptions";
import { Stack, Typography } from "@mui/material";
import { brakeString } from "../../../helpers/brakeString";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

export default function AllCasesSlide({ data, pos }) {
  const { setCursorType } = useOptions();
  const parallax = useParallax({
    speed: pos % 2 === 0 ? -20 : -10,
  });
  return (
    <Stack
      height="100%"
      alignItems="start"
      boxSizing="border-box"
      pb={{ xxs: 8, lg: 14.5 }}
      sx={{
        "&:hover img": { transform: "scale(1.03)" },
      }}
    >
      <Stack
        position="relative"
        mb={5.5}
        overflow="hidden"
        width="100%"
        borderRadius={3.75}
        flexShrink={0}
        sx={{ aspectRatio: "1/1" }}
      >
        <Stack
          position="absolute"
          top={{ xxs: "-25%", sm: "-40%", md: "-25%" }}
          bottom={{ xxs: "-25%", sm: "-40%", md: "-25%" }}
          left={0}
          right={0}
          zIndex={2}
          ref={parallax.ref}
        >
          <img
            src={`${api}${data.image.data.attributes.url}`}
            alt={data.image.data.attributes.alternativeText ?? data.title}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              height: "100%",
              transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            }}
          />
        </Stack>

        <Stack position="absolute" bottom={30} left={30} zIndex={3}>
          {data.tags.data.length > 0 && (
            <Stack component="ul" direction="row" spacing={1.25} mt={3}>
              {data.tags.data.map((i, idx) => (
                <Stack
                  key={data.title + "projects-slice1-tag" + idx}
                  component="li"
                  borderRadius={15}
                  height={30}
                  pt={0.25}
                  bgcolor="custom.bgTag"
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                >
                  <Typography component="span" fontSize={12} fontWeight={600}>
                    {i.attributes.title}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Typography
        fontSize={24}
        fontWeight={600}
        lineHeight={1.67}
        mb={2.25}
        dangerouslySetInnerHTML={{ __html: brakeString(data.title, true) }}
      ></Typography>
      <div
        onMouseEnter={() => setCursorType("trail")}
        onMouseLeave={() => setCursorType("slider")}
      >
        <LinkButtonFooterLocation
          underlineColor="transparent"
          svgColor="custom.ph"
          fontSize={22}
          translateY={-2}
          data={{
            type: "INTERNAL LINK",
            title: "Bekijk deze case",
            link: `/projects/${data.slug}`,
          }}
        />
      </div>
    </Stack>
  );
}
