import React from "react";

export default function Video({
  src,
  alt,
  handlePlay,
  handleFullScreen,
  videoRef,
}) {
  return (
    <video
      loop
      ref={videoRef}
      onClick={handlePlay}
      onDoubleClick={handleFullScreen}
      src={src}
      alt={alt}
      width="100%"
      height="100%"
      style={{
        objectFit: "cover",
        objectPosition: "center",
        cursor: "pointer",
      }}
    />
  );
}
