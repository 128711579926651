import axios from "axios";

export const getUserIP6 = async () => {
  try {
    const response = await axios.get("https://ipv6.webba.nl/ip.php");
    return response.data;
  } catch (error) {
    return null;
  }
};
