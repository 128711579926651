import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../Wrapper";
import errorBg from "../../../images/kungfupanda.gif";
import { useOptions } from "../../../hooks/useOptions";
import LinkButtonAccent from "../../elements/LinkButton/LinkButtonAccent";
import { Link, useNavigate } from "react-router-dom";

export default function Error() {
  const { setIsHeroInstalled } = useOptions();
  const navigate = useNavigate();

  useEffect(() => {
    setIsHeroInstalled(true);
  }, [setIsHeroInstalled]);
  return (
    <Stack
      component="section"
      flex="auto"
      pt={20}
      pb={2}
      sx={{
        backgroundImage: `url(${errorBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Wrapper flex="auto" display="flex" alignItems="center">
        <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }}>
          <Stack alignItems="start">
            <Typography
              fontSize={{ xxs: 68, lg: 90 }}
              fontWeight={600}
              lineHeight={1.33}
              color="custom.white"
            >
              Oeps, deze pagina bestaat{" "}
              <Typography
                component="span"
                fontSize={{ xxs: 68, lg: 90 }}
                fontFamily="Libre Baskerville"
              >
                niet
              </Typography>{" "}
              meer.
            </Typography>
          </Stack>
          <Stack
            pl={{ lg: "20%" }}
            pt={6}
            pb={{ xxs: 4, lg: 0 }}
            alignItems="start"
          >
            <Typography
              color="custom.accentLink"
              fontSize={36}
              fontWeight={600}
              mb={1.5}
            >
              {"… nothing."}
            </Typography>
            <Typography color="custom.white" fontSize={24} mb={4.5}>
              <Typography component="span" fontSize={24} fontWeight={600}>
                We konden de pagina niet vinden.
              </Typography>{" "}
              Maar we kunnen je wel weer op weg helpen! Bekijk de volgende
              pagina’s of{" "}
              <Typography
                component={Link}
                to="/contact"
                fontSize={24}
                fontWeight={600}
                position="relative"
                sx={{
                  textWrap: "nowrap",
                  "&::after": {
                    content: "''",
                    display: "block",
                    width: "100%",
                    height: "1px",
                    backgroundColor: "custom.accentLink",
                    position: "absolute",
                    bottom: "-3px",
                    right: 0,
                  },
                }}
              >
                neem contact op
              </Typography>
              .
            </Typography>
            <Stack direction="row" alignItems="center" gap={3}>
              <LinkButtonAccent
                data={{
                  color: "ACCENT LINK",
                  link: "/",
                  type: "INTERNAL LINK",
                  title: "Ga naar home",
                }}
              />
              <Stack
                component="button"
                type="button"
                onClick={() => navigate(-1)}
                sx={{
                  cursor: "pointer",
                  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  "&:hover": { color: "custom.accentLink" },
                  "&:focus": { color: "custom.accentLink" },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 0.5,
                  color: "custom.white",
                  "& svg": {
                    color: "custom.white",
                    transform: `translateY(-2px)`,
                    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  },
                  "&:hover svg": {
                    color: "custom.accentLink",
                    transform: `rotate(-45deg) translateY(-2px)`,
                  },
                  "&:focus svg": {
                    color: "custom.accentLink",
                    transform: `rotate(-45deg) translateY(-2px)`,
                  },
                  "& span::after": {
                    content: "''",
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "transparent",
                    transition:
                      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                    transformOrigin: "100% 100%",
                  },
                  "&:hover span::after": {
                    backgroundColor: "custom.accentLink",
                  },
                  "&:focus span::after": {
                    backgroundColor: "custom.accentLink",
                  },
                }}
              >
                <Typography
                  component="span"
                  fontSize={22}
                  lineHeight={1}
                  position="relative"
                >
                  Ga terug
                </Typography>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M7 7l9.2 9.2M17 7v10H7" />
                </svg>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
