import React from "react";
import Wrapper from "../../common/Wrapper";
import { Stack, Typography } from "@mui/material";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../LinkButton/LinkButtonFooterLocation";
import CustomTitle from "../CustomSlider/CustomTitle";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { useParallax } from "react-scroll-parallax";
import { Image } from "./BestProject.styled";

const api = process.env.REACT_APP_API_URL;

export default function BestProject({ data, title }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -25,
  });
  return (
    <Wrapper mb={11} isBig>
      <Stack
        minHeight={{ xxs: "auto", lg: 674 }}
        overflow="hidden"
        borderRadius={3.75}
        bgcolor={palette.secondary}
        display="grid"
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        gap={2}
      >
        <Stack
          justifyContent="center"
          pl={{ xxs: 1.875, sm: 5, lg: 10, xl: 17.25 }}
          pr={{ xxs: 1.875, lg: 5 }}
          py={8}
        >
          <Typography
            fontSize={48}
            fontWeight={600}
            mb={2.5}
            dangerouslySetInnerHTML={{ __html: brakeString(title) }}
          ></Typography>
          {data.tags.data.length > 0 && (
            <Stack component="ul" direction="row" spacing={1.25} mb={6}>
              {data.tags.data.map((i, idx) => (
                <Stack
                  key={data.title + "projects-slice1-tag" + idx}
                  component="li"
                  borderRadius={15}
                  height={30}
                  pt={0.25}
                  bgcolor="custom.bgTag"
                  justifyContent="center"
                  alignItems="center"
                  px={1.5}
                >
                  <Typography component="span" fontSize={12} fontWeight={600}>
                    {i.attributes.title}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
          <RichText
            data={data.description}
            fontSize={24}
            lineHeight={1.67}
            paragraphMargin={8}
          />

          <LinkButtonFooterLocation
            underlineColor="transparent"
            svgColor="custom.ph"
            fontSize={22}
            translateY={-2}
            data={{
              type: "INTERNAL LINK",
              link: `/projects/${data.slug}`,
              title: "Bekijk deze case",
            }}
          />
        </Stack>
        <Stack
          overflow="hidden"
          position="relative"
          height={{ xxs: 702, lg: "100%" }}
        >
          <Stack
            overflow="hidden"
            position="absolute"
            top={{ xxs: "-28%", lg: "-10%" }}
            bottom={{ xxs: "-28%", lg: "-10%" }}
            left={0}
            right={0}
            zIndex={2}
            ref={parallax.ref}
          >
            <Image
              src={api + data.image.data.attributes.url}
              alt={data.image.data.attributes.alternativeText}
            />
          </Stack>
          <Stack
            position="absolute"
            height="100%"
            width="100%"
            zIndex={3}
            sx={{
              opacity: 0.76,
              background:
                "transparent linear-gradient(360deg, #0E232E 0%, #5E6C7300 100%) 0% 0% no-repeat padding-box",
            }}
          ></Stack>
          <Stack
            zIndex={4}
            height="100%"
            px={{ xxs: 2, lg: 7 }}
            pb={{ xxs: 11.75, lg: 7 }}
            justifyContent="end"
          >
            <CustomTitle data={data} />
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
}
