import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SmallSlide from "./SmallSlide";
import useSwipe from "../../../hooks/useSwipe";

export default function SmallSlider({ data, isSmall = false }) {
  const [selected, setSelected] = useState(0);
  const interval = useRef(null);
  const timeout = useRef(null);
  const section = useRef(null);
  const total = data.length;

  const handleSelect = (idx) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (interval.current) {
      clearInterval(interval.current);
    }
    setSelected(idx);
    timeout.current = setTimeout(() => {
      interval.current = setInterval(() => {
        setSelected((prev) => (prev + 1) % total);
      }, 5000);
    }, 10000);
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      setSelected((prev) => (prev + 1) % total);
    }, 5000);
    return () => clearInterval(interval.current);
  }, [total]);

  useEffect(() => {
    const scrollHandler = (e) => {
      const total = parseInt(e.currentTarget.dataset.total);
      const timer = parseInt(e.currentTarget.dataset.timer);

      e.preventDefault();
      const newTimer = new Date().getTime();
      if (timer > 0 && timer > newTimer - 250) {
        e.currentTarget.dataset.timer = newTimer;
        return;
      }
      e.currentTarget.dataset.timer = newTimer;

      if (e.deltaY > 0) {
        handleSelect((prev) => (prev + 1) % total);
      } else {
        handleSelect((prev) => (prev === 0 ? total - 1 : prev - 1));
      }
    };

    const sect = section.current;

    if (sect) {
      sect.addEventListener("wheel", scrollHandler);
    }
    return () => {
      if (sect) {
        sect.removeEventListener("wheel", scrollHandler);
      }
    };
  }, []);

  const swipeHandlers = useSwipe({
    onSwipedRight: () => handleSelect((selected + 1) % total),
    onSwipedLeft: () => handleSelect(selected === 0 ? total - 1 : selected - 1),
  });

  return (
    <Stack
      ref={section}
      data-total={total}
      data-timer={0}
      height={
        isSmall
          ? { xxs: 328, md: 398 }
          : { xxs: 328, md: 674, lg: 500, xl: 674 }
      }
      position="relative"
      right={{ xl: isSmall ? 94 : -6 }}
      left={{ xxs: 0, lg: "auto" }}
      {...swipeHandlers}
    >
      {data.map((i, idx) => (
        <SmallSlide
          key={"small-slider" + idx}
          data={i.attributes}
          selected={selected}
          total={total}
          pos={idx}
          handleSelect={handleSelect}
          isSmall={isSmall}
        />
      ))}
    </Stack>
  );
}
