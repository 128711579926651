import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./InfiniteLogoSVG.css";

export default function InfiniteLogoSVG() {
  return (
    <svg
      className="logo"
      viewBox="0 0 737 173.71"
      width="148"
      height="35"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
      style={{
        transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1)",
      }}
    >
      <AnimatePresence>
        <motion.path
          key="2-logo-letter"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 0.25, duration: 1 },
          }}
          exit={{ opacity: 0, transition: { delay: 0.2, duration: 0.25 } }}
          transition={{ repeat: "infinite" }}
          className="cls-1 logo-letter-2"
          d="M499.15,555.68a57.88,57.88,0,1,0-56.37,71.17h1.73V597.62h-1.73a28.84,28.84,0,0,1-24.63-14h80.69l.31-1.33a58.49,58.49,0,0,0,0-26.58Zm-82.88-1c5.92-10.75,15.23-16.24,26.51-16.24A29.54,29.54,0,0,1,469.3,554.7Z"
          transform="translate(-172 -453.15)"
        />
      </AnimatePresence>
      <AnimatePresence>
        <motion.path
          key="4-logo-letter"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 0.45, duration: 0.5 },
          }}
          exit={{
            opacity: 0,
            transition: { delay: 0.4, duration: 0.25 },
          }}
          className="cls-1 logo-letter-4"
          d="M695.5,511.09H666.85V453.15H637.61V569a57.9,57.9,0,1,0,57.89-57.88Zm0,86.53A28.68,28.68,0,0,1,666.85,569V540.33H695.5a28.65,28.65,0,1,1,0,57.29Z"
          transform="translate(-172 -453.15)"
        />
      </AnimatePresence>
      <AnimatePresence>
        <motion.path
          key="1-logo-letter"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 0.65, duration: 0.5 },
          }}
          exit={{ opacity: 0, transition: { delay: 0, duration: 0.25 } }}
          className="cls-1 logo-letter-1"
          d="M345.08,569a28.66,28.66,0,0,1-57.31,0V511.08H258.53V569a28.65,28.65,0,1,1-57.29,0V511.08H172V569a57.85,57.85,0,0,0,101.15,38.4A57.87,57.87,0,0,0,374.32,569V511.08H345.08Z"
          transform="translate(-172 -453.15)"
        />
      </AnimatePresence>
      <AnimatePresence>
        <motion.path
          key="3-logo-letter"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 0.85, duration: 0.5 },
          }}
          exit={{ opacity: 0, transition: { delay: 0.3, duration: 0.25 } }}
          className="cls-1 logo-letter-3"
          d="M569.14,511.09H540.49V453.15H511.26V569a57.89,57.89,0,1,0,57.88-57.88Zm0,86.53A28.68,28.68,0,0,1,540.49,569V540.33h28.65a28.65,28.65,0,1,1,0,57.29Z"
          transform="translate(-172 -453.15)"
        />
      </AnimatePresence>
      <AnimatePresence>
        <motion.path
          key="5-logo-letter"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 1, duration: 0.5 },
          }}
          exit={{ opacity: 0, transition: { delay: 0.2, duration: 0.25 } }}
          className="cls-1 logo-letter-5"
          d="M879.77,597.55V569a57.9,57.9,0,1,0-57.89,57.89h28.65V597.61H821.88A28.66,28.66,0,1,1,850.53,569v28.65h29.24v29.17H909V597.55Z"
          transform="translate(-172 -453.15)"
        />
      </AnimatePresence>
    </svg>
  );
}
