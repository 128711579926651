import Cursor from "./Cursor";

export default function CursorNews() {
  return (
    <Cursor changeCursor={true} readMore={true}>
      {/* <Typography
        color="custom.white"
        fontSize={14}
        fontWeight={600}
        textAlign="center"
      >
        Lees meer
      </Typography> */}
    </Cursor>
  );
}
