import React from "react";
import LinkButtonAccent from "./LinkButtonAccent";
import LinkButtonGhost from "./LinkButtonGhost";
import LinkButtonFooterLocation from "./LinkButtonFooterLocation";
import { colors } from "../../../helpers/colors";

export default function LinkButtonStyled({
  data,
  fontSize = 22,
  underlineColor = colors[data.color],
  svgColor = colors[data.color],
  color = colors[data.color],
}) {
  return (
    <>
      {data.styleType === "ACCENT" && <LinkButtonAccent data={data} />}
      {data.styleType === "GHOST" && <LinkButtonGhost data={data} />}
      {data.styleType === "REGULAR" && (
        <LinkButtonFooterLocation
          data={data}
          fontSize={fontSize}
          color={color}
          underlineColor={underlineColor}
          svgColor={svgColor}
          translateY={-2}
        />
      )}
    </>
  );
}
