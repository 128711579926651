import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function BreadcrumbsLink({ to = "/", title }) {
  return (
    <Typography
      component={Link}
      to={to}
      color="custom.white"
      lineHeight={1.375}
      fontSize={16}
      aria-label={title}
      sx={{
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          color: "custom.primary",
        },

        "&:focus": {
          color: "custom.primary",
        },
      }}
    >
      {title.replace(/\*/g, " ")}
    </Typography>
  );
}
