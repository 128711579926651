import React from "react";
import LinkButton from "./LinkButton";

export default function LinkButtonHeaderCta({ isLight, data, full }) {
  return (
    <LinkButton
      data={data}
      sx={{
        flexShrink: 0,
        fontSize: 14,
        color: isLight ? "custom.primary" : "white",
        bgcolor: isLight ? "transparent" : "custom.accent",
        border: isLight ? "1px solid #E6E6E6" : "1px solid #0066FF",
        borderRadius: 50,
        padding: { xxs: "12px 16px", xs: "12px 30px" },
        transform: full
          ? {
              xxs: "translateX(8px)",
              xs: "translateX(28px)",
              md: "translateX(96px)",
              lg: "translateX(48px)",
              lg2: "translateX(96px)",
            }
          : {
              xxs: "translateX(-92px)",
              md: "translateX(-125px)",
            },
        transition:
          "transform 500ms linear 250ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1), background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          bgcolor: isLight ? "custom.accent" : "custom.primary",
          color: isLight ? "custom.white" : "white",
          borderColor: isLight ? "custom.accent" : "custom.primary",
        },
        "&:focus": {
          bgcolor: isLight ? "custom.accent" : "custom.primary",
          color: isLight ? "custom.white" : "white",
          borderColor: isLight ? "custom.accent" : "custom.primary",
        },
      }}
    />
  );
}
