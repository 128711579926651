import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { useParallax } from "react-scroll-parallax";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";

export default function ContentBlock({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -3,
  });
  return (
    <Stack
      component="section"
      pt={data.addBackground ? { xxs: 11, lg: 13 } : 6}
      pb={data.addBackground ? { xxs: 12, lg: 13 } : 6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(96deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Stack
          ref={parallax.ref}
          px={data.addExtraPaddings ? { lg: 17.25 } : 0}
        >
          {data.showTitle && (
            <Typography
              component="h2"
              fontWeight={600}
              fontSize={48}
              lineHeight={1.33}
              mb={3}
              sx={{ overflowWrap: "anywhere" }}
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>
          )}
          {!data.showTitle && <HiddenTitle>{data.title}</HiddenTitle>}
          <Stack
            display="grid"
            gap={2}
            gridTemplateColumns={
              data.main.length > 1 ? { lg: "1fr 1fr" } : "1fr"
            }
          >
            {data.main.map((i, idx) => (
              <div key={"content" + data.title + idx}>
                <RichText
                  data={i.content}
                  fontSize={data.isLargeFont ? 24 : 18}
                  lineHeight={2.22}
                />
              </div>
            ))}
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
