import { Stack, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import Video from "../../elements/Video/Video";
import { Image } from "./ContentBlockImage.styled";
import { useParallax } from "react-scroll-parallax";
import PlayButton from "../../elements/PlayButton/PlayButton";

const api = process.env.REACT_APP_API_URL;

export default function RegularMedia({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef(null);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const parallax = useParallax({
    speed: data.sectionSize !== "EXTRA LARGE" ? -10 : isDesktop ? -50 : -20,
  });

  const handlePlay = () => {
    if (document.fullscreenElement) return;
    if (ref.current.paused) {
      ref.current.play();
      setIsPlaying(true);
    } else {
      ref.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) return;
    if (ref.current.requestFullscreen) ref.current.requestFullscreen();
    else if (ref.current.webkitRequestFullscreen)
      ref.current.webkitRequestFullscreen();
    else if (ref.current.msRequestFullScreen) ref.current.msRequestFullScreen();
  };
  return (
    <Stack
      overflow="hidden"
      borderRadius={data.sectionSize !== "EXTRA LARGE" ? 3.75 : 0}
      position={
        data.sectionSize !== "EXTRA LARGE"
          ? "relative"
          : { xxs: "relative", lg: "absolute" }
      }
      sx={{
        aspectRatio: { xxs: "1/1", lg: "auto" },
      }}
      height={{ lg: "100%" }}
      top={0}
      bottom={0}
      left={
        data.sectionSize !== "EXTRA LARGE"
          ? 0
          : data.isReverse
          ? 0
          : { xxs: 0, lg: "calc(50% + 8px)" }
      }
      right={
        data.sectionSize !== "EXTRA LARGE"
          ? 0
          : data.isReverse
          ? { xxs: 0, lg: "calc(50% + 8px)" }
          : 0
      }
      zIndex={2}
    >
      {data.image.data.attributes.mime.includes("video") && (
        <PlayButton
          isSmall={data.sectionSize !== "EXTRA LARGE"}
          isPlaying={isPlaying}
          handlePlay={handlePlay}
          handleFullScreen={handleFullScreen}
        />
      )}
      <Stack
        ref={parallax.ref}
        position="absolute"
        top={data.sectionSize !== "EXTRA LARGE" ? "-14%" : "-22%"}
        bottom={data.sectionSize !== "EXTRA LARGE" ? "-14%" : "-22%"}
        left={0}
        right={0}
      >
        {data.image.data.attributes.mime.includes("video") && (
          <Video
            src={api + data.image.data.attributes.url}
            alt={data.image.data.attributes.alternativeText}
            videoRef={ref}
            handlePlay={handlePlay}
            handleFullScreen={handleFullScreen}
          />
        )}
        {data.image.data.attributes.mime.includes("image") && (
          <Image
            src={api + data.image.data.attributes.url}
            alt={data.image.data.attributes.alternativeText}
          />
        )}
      </Stack>
    </Stack>
  );
}
