import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { useParallax } from "react-scroll-parallax";
import AnimatedNumber from "../../common/AnimatedNumber/AnimatedNumber";

export default function Advantages({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -4,
  });
  const parallaxGrid = useParallax({
    speed: -6,
  });
  return (
    <Stack
      component="section"
      pt={{ xxs: 6, lg: 8 }}
      pb={{ xxs: 10, lg: 8 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Typography
          ref={parallax.ref}
          fontSize={{ xxs: 68, lg: 100 }}
          lineHeight={1.1}
          component="h2"
          fontWeight={600}
          mb={14}
          dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
        ></Typography>
        <Stack
          ref={parallaxGrid.ref}
          display="grid"
          columnGap={2}
          rowGap={{ xxs: 5.75, lg: 13.5 }}
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          component="ul"
        >
          {data.cards.map((i, idx) => (
            <Stack
              key={"advantage" + idx}
              component="li"
              px={{ lg: "20%" }}
              pt={{ xxs: i.subtitle ? 12 : 9, lg: 3.5 }}
              position="relative"
            >
              {i.subtitle && (
                <Typography
                  position="absolute"
                  top={{ xxs: 64, lg: -4 }}
                  color="custom.primary"
                  fontSize={18}
                  lineHeight={1.33}
                  fontWeight={600}
                  mb={0.5}
                >
                  {i.subtitle}
                </Typography>
              )}
              <Typography
                component="h3"
                fontWeight={600}
                fontSize={48}
                lineHeight={1.33}
                mb={2.5}
                position="relative"
              >
                {i.title}
                <Typography
                  component="span"
                  position="absolute"
                  left={{ xxs: 0, lg: -80 }}
                  bottom={{ xxs: i.subtitle ? 112 : 85, lg: 6 }}
                  fontWeight={600}
                  fontSize={36}
                  lineHeight={1.33}
                >
                  {idx + 1 < 10 && 0}
                  <AnimatedNumber
                    num={idx + 1}
                    start={1}
                    delay={0}
                    duratuion={250}
                  />
                  .
                </Typography>
              </Typography>
              {i.description && (
                <RichText
                  data={i.description}
                  fontSize={18}
                  lineHeight={2}
                  paragraphMargin={0}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
