import React from "react";
import { Image } from "./ImageBanner.styled";
import { useParallax } from "react-scroll-parallax";
import { Stack } from "@mui/material";

const api = process.env.REACT_APP_API_URL;

const dictSpeed = {
  0: -10,
  1: -30,
  2: -20,
};

const dictAspect = {
  1: "3/1",
  2: "1/1",
  3: "1/1",
};

export default function ImageBannerCard({ data, cols, pos }) {
  const parallax = useParallax({
    speed: dictSpeed[pos % cols],
  });
  return (
    <Stack
      overflow="hidden"
      position="relative"
      borderRadius={3.75}
      minHeight={550}
      sx={{
        aspectRatio: dictAspect[cols],
      }}
    >
      <Stack
        position="absolute"
        top="-20%"
        bottom="-20%"
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        <Image
          src={api + data.attributes.url}
          alt={data.attributes.alternativeText}
        />
      </Stack>
    </Stack>
  );
}
