import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import LinkButton from "../../elements/LinkButton/LinkButton";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import "./ticker.css";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";

export default function Ticker({ data }) {
  const { palette } = useOptions();
  const [isRunning, setIsRunning] = useState(true);
  const [isNormalDirection, setIsNormalDirection] = useState(true);
  const parallax = useParallax({
    speed: -5,
  });
  const ref = useRef(null);

  const linksArray = [...data.links, ...data.links];

  useEffect(() => {
    const onScrollHandler = (e) => {
      e.preventDefault();
      if (e.deltaY > 0) {
        setIsNormalDirection(false);
      } else {
        setIsNormalDirection(true);
      }
      setIsRunning(true);
    };

    const block = ref.current;
    if (block) {
      block.addEventListener("wheel", onScrollHandler);
    }
    return () => {
      if (block) {
        block.removeEventListener("wheel", onScrollHandler);
      }
    };
  });

  return (
    <Stack
      component="section"
      py={{ xxs: 8, lg: 11.5 }}
      overflow="hidden"
      position="relative"
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <HiddenTitle>{data.title}</HiddenTitle>
      <Stack ref={parallax.ref} width={9999}>
        <Stack direction="row" ref={ref}>
          <Stack
            component="ul"
            direction="row"
            spacing={4}
            pl={4}
            sx={{
              animation: "animate 30s linear infinite",
              animationDirection: isNormalDirection ? "normal" : "reverse",
              animationDelay: "-30s",
              animationPlayState: isRunning ? "running" : "paused",
            }}
          >
            {linksArray.map((i, idx) => (
              <Stack key={"ticker-link" + idx} component="li">
                <LinkButton
                  func={setIsRunning}
                  data={i}
                  sx={{
                    color: data.addBackground ? "custom.white" : "custom.bgTag",
                    fontSize: { xxs: 68, lg: 100 },
                    fontWeight: 600,
                    textWrap: "nowrap",
                  }}
                >
                  {getHighlightedText(i.title, data.highlightingWords ?? "")}
                </LinkButton>
              </Stack>
            ))}
          </Stack>
          <Stack
            component="ul"
            direction="row"
            spacing={4}
            sx={{
              animation: "animate2 30s linear infinite",
              animationDirection: isNormalDirection ? "normal" : "reverse",
              animationDelay: "-15s",
              animationPlayState: isRunning ? "running" : "paused",
            }}
          >
            {linksArray.map((i, idx) => (
              <Stack key={"ticker-link-duplicates" + idx} component="li">
                <LinkButton
                  func={setIsRunning}
                  data={i}
                  sx={{
                    color: data.addBackground ? "custom.white" : "custom.bgTag",
                    fontSize: { xxs: 68, lg: 100 },
                    fontWeight: 600,
                    textWrap: "nowrap",
                  }}
                >
                  {getHighlightedText(i.title, data.highlightingWords)}
                </LinkButton>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
