import { createGlobalStyle } from "styled-components";
import "modern-normalize";

export const GlobalStyle = createGlobalStyle`
  html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 10px !important;
  }
  ::-webkit-scrollbar-track {
    background: rgba(224, 238, 255, 0.5) !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(56, 179, 246, 0.8) !important;
    border-radius: 4px !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(56, 179, 246, 1) !important;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
}

  body {
    overflow-x: hidden;
    margin: 0;

    font-family: 'Area-Normal', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    color: #0E232E;

    background-color: #fff;
  }
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

  }
  main {
    display: flex;
    flex-direction: column;
    flex: auto;
    & > .page-content-container {
      display: flex;
      flex-direction: column;
      flex: auto;
    }
  }


  address {
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
  ul,
  ol {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .custom-toast {
    color: #0E232E;
    padding: 30px;
    background: transparent linear-gradient(108deg, #DBEBFF 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box;
  }
`;
