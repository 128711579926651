import React from "react";
import { useOptions } from "../../../hooks/useOptions";
import { Image } from "./LargeImageBanner.styled";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../../common/Wrapper";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

export default function LargeImageBanner({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -50,
  });
  return (
    <Stack component="section" pt={7.5} position="relative">
      <Wrapper>
        {!data.showTitle && <HiddenTitle>{data.title}</HiddenTitle>}
        {data.showTitle && (
          <Typography
            fontSize={36}
            fontWeight={600}
            lineHeight={1.33}
            mb={6.75}
          >
            {data.title}
          </Typography>
        )}
      </Wrapper>
      <Wrapper isBig>
        <Stack
          overflow="hidden"
          position="relative"
          borderRadius={3.75}
          height={750}
        >
          <Stack
            position="absolute"
            top="-18%"
            bottom="-18%"
            left={0}
            right={0}
            zIndex={2}
            ref={parallax.ref}
          >
            <Image
              src={api + data.image.data.attributes.url}
              alt={data.image.data.attributes.alternativeText}
            />
          </Stack>
        </Stack>
      </Wrapper>
      <Stack
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom="45%"
        zIndex={-1}
        sx={{
          background: data.addBackground
            ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
            : "",
        }}
      />
    </Stack>
  );
}
