import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { servicesColors } from "../../../helpers/servicesColors";
import { useFormik } from "formik";
import { schemaLeadGenForm } from "../../../helpers/validationSchema/schemaLeadGenForm";
import { createFormSubmitting } from "../../../services/createFormSubmitting";
import { toast } from "react-toastify";
import InputField from "../../elements/InputField/InputField";
import ArrowButton from "../../elements/ArrowButton/ArrowButton";
import { motion, AnimatePresence } from "framer-motion";
import CloseButton from "../../elements/CloseButton/CloseButton";

const api = process.env.REACT_APP_API_URL;

const initialValues = {
  name: "",
  email: "",
};

export default function LeadGenForm({ data }) {
  const [isShow, setIsShow] = useState(false);

  const closeForm = () => {
    setIsShow(false);
    localStorage.setItem("messgedAt", new Date());
  };

  useEffect(() => {
    const messgedAt = localStorage.getItem("messgedAt");
    const diff = new Date() - new Date(messgedAt);
    if (messgedAt && diff < 1000 * 60 * 60 * data.waitingHours) {
      return;
    }
    if (data.delaySeconds) {
      setTimeout(() => {
        setIsShow(true);
      }, data.delaySeconds * 1000);
    } else {
      setIsShow(true);
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLeadGenForm,
    onSubmit: async (values, { resetForm }) => {
      const formData = { ...values };
      delete formData.privacy;
      await createFormSubmitting("LEAD GENERATION", formData).then(() =>
        toast(
          <>
            <Typography mb={0.5}>Verstuurd</Typography>
            <Typography fontWeight={600} fontSize={48} mb={2} lineHeight={1.33}>
              Succes
            </Typography>
            <Typography fontSize={18} lineHeight={2}>
              Bedankt voor het invullen van het formulier.
            </Typography>
          </>
        )
      );

      window.open(`${api}${data.file.data.attributes.url}`, "_blank");
      resetForm(initialValues);
      closeForm();
    },
  });

  return (
    <AnimatePresence>
      {isShow && (
        <motion.div
          style={{ zIndex: 95 }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { delay: 0.25, duration: 1 },
          }}
          exit={{ opacity: 0, transition: { duration: 0.25 } }}
        >
          <Stack
            component="section"
            bgcolor={servicesColors[data.color].secondary}
            position="fixed"
            borderRadius={3.75}
            maxWidth={400}
            width="calc(100% - 30px)"
            maxHeight={{ xxs: "calc(100% - 30px)", sm: "auto" }}
            pt={6}
            pb={4.5}
            pl={{ xxs: 1.875, sm: 4 }}
            pr={{ xxs: 1.875, sm: 3 }}
            bottom={{ xxs: 15, sm: 40 }}
            left={{ xxs: 15, sm: 50 }}
            zIndex={200}
            sx={{
              overflowY: "auto",
              background: `transparent linear-gradient(124deg, ${
                servicesColors[data.color].secondary
              } 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`,
            }}
          >
            {data.subtitle && (
              <Typography component="span" mb={0.5}>
                {data.subtitle}
              </Typography>
            )}
            <Typography
              component="h2"
              fontWeight={600}
              fontSize={48}
              mb={2}
              lineHeight={1.3}
              sx={{ overflowWrap: "anywhere" }}
            >
              {data.title}
            </Typography>
            <Typography fontSize={18} lineHeight={2} mb={4}>
              {data.description}
            </Typography>
            <CloseButton handler={closeForm} />
            <Stack component="form" id="contact" onSubmit={formik.handleSubmit}>
              <Stack gap={1.875} mb={2.5}>
                <InputField
                  formik={formik}
                  name="name"
                  label="Je naam"
                  required
                />
                <InputField
                  formik={formik}
                  name="email"
                  label="Wat is je e-mail?"
                  required
                />
              </Stack>
              <Stack gap={5.5} direction="row">
                <ArrowButton
                  text={data.buttonTitle}
                  isSumbit
                  bgcolor="custom.controls"
                />
              </Stack>
            </Stack>
          </Stack>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
