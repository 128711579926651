import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import ArrowButton from "../../elements/ArrowButton/ArrowButton";
import { useFormik } from "formik";
import { schemaSubscriptionForm } from "../../../helpers/validationSchema/schemaSubscriptionForm";
import { createFormSubmitting } from "../../../services/createFormSubmitting";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
};

export default function SubscriptionField() {
  const formik = useFormik({
    initialValues,
    validationSchema: schemaSubscriptionForm,
    onSubmit: async (values, { resetForm }) => {
      await createFormSubmitting("SUBSCRIPTION", values).then(() =>
        toast(
          <>
            <Typography mb={0.5}>Verstuurd</Typography>
            <Typography fontWeight={600} fontSize={48} mb={2} lineHeight={1.33}>
              Succes
            </Typography>
            <Typography fontSize={18} lineHeight={2}>
              Bedankt voor je abonnement! U bent succesvol op ons nieuws
              geabonneerd.
            </Typography>
          </>
        )
      );

      resetForm(initialValues);
    },
  });
  return (
    <Stack
      component="form"
      direction="row"
      position="relative"
      id="subscription"
      onSubmit={formik.handleSubmit}
    >
      <TextField
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        name="email"
        type="text"
        label="Jouw e-mailadres"
        variant="standard"
        sx={{
          width: "100%",
          pr: 3.25,
          "& input": { pr: 20, pb: 1.125, fontSize: 18 },
        }}
      />
      <ArrowButton
        text="Aanmelden"
        isSumbit
        sx={{ position: "absolute", zIndex: 2, right: 0, top: 6 }}
      />
    </Stack>
  );
}
