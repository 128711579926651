import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import LinkButtonSupport from "../../elements/LinkButton/LinkButtonSupport";
import { useOptions } from "../../../hooks/useOptions";

export default function VacanciesDescription({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      pt={{ xxs: 6, lg: 10 }}
      pb={6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
        <Stack>
          <Typography
            component="h2"
            fontWeight={600}
            fontSize={36}
            color="custom.primary"
            mb={2}
          >
            {data.title}
          </Typography>
          {data.description && (
            <Stack alignItems="start">
              <RichText
                data={data.description}
                fontSize={24}
                paragraphMargin={0}
                lineHeight={1.67}
              />
            </Stack>
          )}
        </Stack>
        <Stack pt={4} pl={{ lg: "40%" }} gap={1.5}>
          <Typography
            color="custom.primary"
            fontWeight={600}
            fontSize={18}
            mb={1}
          >
            Bekijk
          </Typography>

          {data.addVacanciesAnchor && (
            <LinkButtonSupport
              fontSize={20}
              underlineColor="transparent"
              data={{
                type: "ANCHOR",
                link: "#work",
                title: "Vacatures",
              }}
            />
          )}
          {data.addVacanciesAnchor && (
            <LinkButtonSupport
              fontSize={20}
              underlineColor="transparent"
              data={{
                type: "ANCHOR",
                link: "#trainship",
                title: "Stageplekken",
              }}
            />
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
