import React, { useRef, useState } from "react";
import { useParallax } from "react-scroll-parallax";
import { getArrSeparatedByAsterisk } from "../../../helpers/getArrSeparatedByAsterisk";
import AnimatedLetters from "../../elements/AnimatedText/AnimatedLetters";
import { Image } from "./HighlightedBlock.styled";
import { Stack, Typography } from "@mui/material";
import Video from "../../elements/Video/Video";
import PlayButton from "../../elements/PlayButton/PlayButton";

const api = process.env.REACT_APP_API_URL;

export default function Media({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef(null);
  const parallax = useParallax({
    speed: -20,
  });

  const handlePlay = () => {
    if (document.fullscreenElement) return;
    if (ref.current.paused) {
      ref.current.play();
      setIsPlaying(true);
    } else {
      ref.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) return;
    if (ref.current.requestFullscreen) ref.current.requestFullscreen();
    else if (ref.current.webkitRequestFullscreen)
      ref.current.webkitRequestFullscreen();
    else if (ref.current.msRequestFullScreen) ref.current.msRequestFullScreen();
  };
  return (
    <>
      {data.image.data.attributes.mime.includes("video") && (
        <PlayButton
          isOnTop
          isPlaying={isPlaying}
          handlePlay={handlePlay}
          handleFullScreen={handleFullScreen}
        />
      )}
      <Stack
        position="absolute"
        top="-10%"
        bottom="-10%"
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        {data.image.data.attributes.mime.includes("video") && (
          <Video
            src={api + data.image.data.attributes.url}
            alt={data.image.data.attributes.alternativeText}
            videoRef={ref}
            handlePlay={handlePlay}
            handleFullScreen={handleFullScreen}
          />
        )}
        {data.image.data.attributes.mime.includes("image") && (
          <Image
            src={api + data.image.data.attributes.url}
            alt={data.image.data.attributes.alternativeText}
          />
        )}
      </Stack>

      {!isPlaying && (
        <Stack
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={3}
          sx={{
            pointerEvents: "none",
            opacity: 0.76,
            background:
              "transparent linear-gradient(360deg, #0E232E 0%, #5E6C7300 100%) 0% 0% no-repeat padding-box",
          }}
        />
      )}

      {!isPlaying && (
        <Stack
          zIndex={4}
          position="absolute"
          left={{ xxs: 16, lg: 54 }}
          bottom={{ xxs: 94, lg: 54 }}
          sx={{
            pointerEvents: "none",
          }}
        >
          {data.topTitle && (
            <Typography
              color="custom.white"
              fontSize={18}
              fontWeight={600}
              component="span"
              mb={1}
              pl={{ lg: 1.25 }}
            >
              {data.topTitle}
            </Typography>
          )}
          <Typography
            color="custom.white"
            fontSize={{ xxs: 70, lg: 120 }}
            fontWeight={600}
            lineHeight={{ xxs: 1.2, lg: 1.08 }}
            component="h3"
            mb={{ xxs: 2, lg: 0.75 }}
          >
            {getArrSeparatedByAsterisk(data.title).map((i, idx, arr) => (
              <span key={"animated-case-card" + i + idx}>
                <AnimatedLetters
                  text={i}
                  delay={200}
                  time={400}
                  baseDelay={0}
                />
                {arr.length - 1 !== idx && <br />}
              </span>
            ))}
          </Typography>
          {data.bottomTitle && (
            <Typography
              color="custom.white"
              fontSize={16}
              fontWeight={700}
              component="span"
              pl={{ lg: 1.25 }}
            >
              {data.bottomTitle}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
}
