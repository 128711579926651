import { Stack, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

export default function SmallSlide({
  data,
  selected,
  total,
  pos,
  handleSelect,
  isSmall,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [status, setStatus] = useState("hidden");
  const parallax = useParallax({
    speed: isMobile ? -8 : -10,
  });

  useEffect(() => {
    if (pos === selected) {
      setStatus("curr");
    } else if (pos === selected - 1) {
      setStatus("prev");
    } else if (pos === selected + 1) {
      setStatus("next");
    } else if (pos < selected) {
      setStatus("prevHidden");
    } else {
      setStatus("hidden");
    }
  }, [pos, selected, total]);

  const mainSlideTransform = (tot, idx) => {
    if (tot < 3) {
      return "translateY(-50%) translateX(61%)";
    } else if (idx === 0) {
      return "translateY(-50%) translateX(61%)";
    } else if (idx === tot - 1) {
      return "translateY(-50%) translateX(39%)";
    } else {
      return "translateY(-50%) translateX(50%)";
    }
  };

  const stylesDict = {
    prevHidden: {
      zIndex: 2,
      transform: "translateY(-50%) scale(0.69) translateX(111%)",
    },
    prev: {
      zIndex: 4,
      transform:
        total < 3
          ? "translateY(-50%) scale(0.69) translateX(34%)"
          : "translateY(-50%) scale(0.69) translateX(111%)",
      cursor: "pointer",
    },
    curr: {
      zIndex: 5,
      transform: mainSlideTransform(total, pos),
    },
    next: {
      zIndex: 3,
      transform: "translateY(-50%) scale(0.69) translateX(34%)",
      cursor: "pointer",
    },
    hidden: {
      zIndex: 1,
      transform: "translateY(-50%) scale(0.69) translateX(34%)",
      opacity: 0,
    },
  };
  return (
    <Stack
      onClick={() => handleSelect(pos)}
      overflow="hidden"
      position="absolute"
      height={
        isSmall
          ? { xxs: 328, md: 398 }
          : { xxs: 328, md: 674, lg: 500, xl: 674 }
      }
      width={
        isSmall
          ? { xxs: 328, md: 398 }
          : { xxs: 328, md: 674, lg: 500, xl: 674 }
      }
      borderRadius={3.75}
      right="50%"
      top="50%"
      sx={{
        transition:
          "transform 500ms cubic-bezier(0.4, 0, 0.2, 1), opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)",
        ...stylesDict[status],
      }}
    >
      <Stack
        position="absolute"
        top="-12%"
        bottom="-13%"
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        <img
          src={`${api}${data.url}`}
          alt={data.alternativeText}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "100%",
          }}
        />
      </Stack>
    </Stack>
  );
}
