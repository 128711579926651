import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import { useOptions } from "../../../hooks/useOptions";
import SubscriptionField from "../SubscriptionForm/SubscriptionField";
import SupportForm from "../Support/SupportForm";
import ContactForm from "../Contact/ContactForm";
import ColaborationForm from "../../elements/ColaborationForm/ColaborationForm";
import RichText from "../../common/RichText/RichText";
import { brakeString } from "../../../helpers/brakeString";

export default function FormsPlaceholder({ data }) {
  const { form, palette } = useOptions();
  return (
    <Stack
      component="section"
      py={8}
      id={form ? `${form.formType.toLowerCase()}-form` : ""}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        {(form?.formTitle ||
          form?.formType === "SUBSCRIPTION" ||
          form?.formType === "CONTACT") && (
          <Stack
            mb={12}
            justifyContent={{ lg: "space-between" }}
            direction={{ lg: "row" }}
            alignItems={{ lg: "end" }}
          >
            <Typography
              component="h2"
              fontWeight={600}
              fontSize={{ xxs: 68, lg: 100 }}
              lineHeight={1.1}
              sx={{ overflowWrap: "anywhere" }}
              dangerouslySetInnerHTML={{ __html: brakeString(form.formTitle) }}
            ></Typography>
            {(form.formType === "COLLABORATION" ||
              form.formType === "SUPPORT") && (
              <Stack width={{ lg: "40%" }}>
                {form.formDescription && (
                  <RichText
                    data={form.formDescription}
                    fontSize={18}
                    lineHeight={2}
                    paragraphMargin={0}
                  />
                )}
              </Stack>
            )}
          </Stack>
        )}
        {form?.formType === "SUBSCRIPTION" && (
          <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
            <Stack pr={{ lg: "20%" }} justifyContent="center">
              <SubscriptionField />
            </Stack>
            <Stack>
              <RichText
                data={form.formDescription}
                fontSize={18}
                lineHeight={2}
                paragraphMargin={0}
              />
            </Stack>
          </Stack>
        )}
        {form?.formType === "SUPPORT" && <SupportForm />}
        {form?.formType === "COLLABORATION" && <ColaborationForm />}
        {form?.formType === "CONTACT" && (
          <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
            <Stack pr={{ lg: "20%" }} justifyContent="center">
              <RichText
                data={form.formDescription}
                fontSize={18}
                lineHeight={2}
                paragraphMargin={0}
              />
            </Stack>
            <Stack>
              <ContactForm />
            </Stack>
          </Stack>
        )}
      </Wrapper>
    </Stack>
  );
}
