import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Section } from "./Accordion.styled";
import Wrapper from "../../common/Wrapper";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import RichText from "../../common/RichText/RichText";
import AccordionItem from "./AccordionItem";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";

export default function Accordion({ data }) {
  const [expanded, setExpanded] = useState(null);
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -8,
  });
  const parallaxAcc = useParallax({
    speed: -5,
  });

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };
  return (
    <Section $secondary={palette.secondary} $addBackground={data.addBackground}>
      <Wrapper
        display="flex"
        flexDirection={{ xxs: "column", lg: "row" }}
        justifyContent="space-between"
        gap={{ xxs: 7, lg: 0 }}
      >
        <Stack
          ref={parallax.ref}
          pt={{ lg: 7.5 }}
          pb={{ xxs: 5, lg: 0 }}
          width={{ md: 650 }}
          justifyContent="space-between"
          spacing={6}
        >
          {data.main && (
            <Stack>
              <Typography
                fontWeight={600}
                fontSize={48}
                color="custom.primary"
                mb={1.75}
                component="h2"
              >
                {data.main.title}
              </Typography>
              <Stack>
                <RichText
                  data={data.main.text}
                  fontSize={24}
                  paragraphMargin={4.5}
                />
                {data.main.link &&
                  data.main.link.map((i, idx) => (
                    <LinkButtonFooterLocation
                      key={"hero-cta-links" + idx}
                      data={i}
                      fontSize={22}
                      gap={1.5}
                      underlineColor="transparent"
                      translateY={-2}
                    />
                  ))}
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack width={{ lg: 400 }} position="relative" flexShrink={0}>
          <Stack component="ul" ref={parallaxAcc.ref}>
            {data.cases &&
              data.cases.map((i, idx) => (
                <AccordionItem
                  key={"accordion-item" + idx}
                  expanded={expanded}
                  handleChange={handleChange}
                  data={i}
                  addBackground={!data.addBackground}
                  secondary={palette.secondary}
                />
              ))}
          </Stack>
        </Stack>
      </Wrapper>
    </Section>
  );
}
