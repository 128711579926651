import { Stack, Typography } from "@mui/material";
import React from "react";
import { Image } from "./CaseCard.styled";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../LinkButton/LinkButtonFooterLocation";
import { useParallax } from "react-scroll-parallax";
import { getArrSeparatedByAsterisk } from "../../../helpers/getArrSeparatedByAsterisk";
import AnimatedLetters from "../AnimatedText/AnimatedLetters";

const api = process.env.REACT_APP_API_URL;

export default function CaseCard({ data, pos }) {
  const parallax = useParallax({
    speed: pos % 2 === 0 ? -20 : -40,
  });
  return (
    <Stack
      justifyContent="space-between"
      gap={{ xxs: 3, lg: 6 }}
      py={{ xxs: 4, lg: 6 }}
      sx={{
        "&:hover img": {
          transform: "scale(1.03)",
        },
      }}
    >
      <Stack>
        <Stack
          overflow="hidden"
          position="relative"
          borderRadius={3.75}
          mb={3.5}
          sx={{ aspectRatio: "1/1" }}
        >
          <Stack
            position="absolute"
            top={
              pos % 2 === 0
                ? { xxs: "-18%", lg: "-10%" }
                : { xxs: "-30%", lg: "-18%" }
            }
            bottom={
              pos % 2 === 0
                ? { xxs: "-18%", lg: "-10%" }
                : { xxs: "-30%", lg: "-18%" }
            }
            left={0}
            right={0}
            zIndex={2}
            ref={parallax.ref}
          >
            <Image
              src={api + data.image.data.attributes.url}
              alt={data.image.data.attributes.alternativeText}
            />
          </Stack>
          <Stack position="absolute" bottom={0} left={0} p={2.5} zIndex={3}>
            <Typography
              color="custom.white"
              fontSize={78}
              fontWeight={600}
              lineHeight={1.03}
            >
              {getArrSeparatedByAsterisk(data.title).map((i, idx, arr) => (
                <span key={"animated-case-card" + i + idx}>
                  <AnimatedLetters
                    text={i}
                    delay={200}
                    time={400}
                    baseDelay={0}
                  />
                  {arr.length - 1 !== idx && <br />}
                </span>
              ))}
            </Typography>
            <Typography fontSize={14} color="custom.white" fontWeight={600}>
              {data.subtitle}
            </Typography>
            {data.tags.data.length > 0 && (
              <Stack component="ul" direction="row" spacing={1.25} mt={3}>
                {data.tags.data.map((i, idx) => (
                  <Stack
                    key={data.title + "projects-slice1-tag" + idx}
                    component="li"
                    borderRadius={15}
                    height={30}
                    pt={0.25}
                    bgcolor="custom.bgTag"
                    justifyContent="center"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography component="span" fontSize={12} fontWeight={600}>
                      {i.attributes.title}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Typography fontSize={48} fontWeight={600} lineHeight={1.29} mb={4.5}>
          {data.characteristic}
        </Typography>
        {data.description && (
          <RichText
            paragraphMargin={0}
            headingMargin={4}
            fontSize={18}
            lineHeight={2.22}
            data={data.description}
          />
        )}
      </Stack>
      <LinkButtonFooterLocation
        underlineColor="custom.white"
        svgColor="custom.ph"
        fontSize={22}
        translateY={-2}
        data={{
          type: "INTERNAL LINK",
          link: `/projects/${data.slug}`,
          title: "Bekijk deze case",
        }}
      />
    </Stack>
  );
}
