import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import ServiceCard from "./ServiceCard";
import { useParallax } from "react-scroll-parallax";
import ServiceBigList from "./ServiceBigList";

export default function ServiceList({ data }) {
  const [hovered, setHovered] = useState(null);
  const parallax = useParallax({
    speed: -3,
  });
  const parallaxTxt = useParallax({
    speed: -6,
  });

  const handleHover = (idx) => {
    setHovered(idx);
  };

  return (
    <Stack
      component="section"
      pt={{ xxs: 8, lg: 11 }}
      pb={{ xxs: 5.25, lg: 13 }}
      sx={{
        background:
          data.type === "GRID"
            ? "transparent linear-gradient(181deg, #FFFFFF 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box"
            : "",
      }}
    >
      <Wrapper
        display="grid"
        gridTemplateColumns={
          data.type === "GRID" ? { lg: "4fr 6fr" } : { lg: "5fr 5fr" }
        }
        mb={data.type === "HORIZONTAL LIST" ? { lg: 12.5 } : 0}
      >
        <HiddenTitle>{data.title}</HiddenTitle>
        {data.main && (
          <Stack
            ref={parallaxTxt.ref}
            pt={data.type === "GRID" ? { lg: 3.75 } : 0}
            pr={data.type === "GRID" ? { lg: 17.5 } : { lg: 1 }}
            pb={{ xxs: 8, lg: 0 }}
          >
            <Typography
              fontWeight={600}
              fontSize={36}
              color="custom.primary"
              mb={1.75}
            >
              {data.main.title}
            </Typography>
            <Stack alignItems="start">
              <RichText
                data={data.main.text}
                fontSize={24}
                paragraphMargin={3.75}
                lineHeight={1.67}
              />
              {data.main.link &&
                data.main.link.map((i, idx) => (
                  <LinkButtonFooterLocation
                    key={"hero-cta-links" + idx}
                    data={i}
                    fontSize={22}
                    gap={0.5}
                    underlineColor="transparent"
                    translateY={-1}
                    svgColor="custom.ph"
                  />
                ))}
            </Stack>
          </Stack>
        )}
        <Stack
          ref={parallax.ref}
          display="grid"
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          gap={2}
          component="ul"
        >
          {data.type === "GRID" &&
            data.cards.map((i, idx) => (
              <ServiceCard
                key={"service-grid-card" + idx}
                data={i}
                hovered={idx === hovered}
                pos={idx}
                handleHover={handleHover}
              />
            ))}
        </Stack>
      </Wrapper>
      <Wrapper isBig={true}>
        {data.type === "HORIZONTAL LIST" && (
          <ServiceBigList
            data={data.cards}
            hovered={hovered}
            handleHover={handleHover}
          />
        )}
      </Wrapper>
    </Stack>
  );
}
