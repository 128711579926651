import React from "react";
import AnimatedNumber from "../../common/AnimatedNumber/AnimatedNumber";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../../common/Wrapper";

export default function Achieve({ data }) {
  return (
    <Stack pt={3.75} pb={5} borderBottom="1px solid #E3E3E3">
      <Wrapper
        display="grid"
        gridTemplateColumns={{ xxs: "1fr 2fr", lg: "1fr 1fr" }}
        gap={2}
      >
        <Typography
          fontWeight={600}
          color="custom.accentLink"
          fontSize={{ xxs: 20, lg: 36 }}
          lineHeight={1.33}
        >
          {data.value > 0 && "+"}
          <AnimatedNumber num={data.value} addRandom />
          {"%"}
        </Typography>
        <Typography fontSize={{ xxs: 20, lg: 36 }} lineHeight={1.33}>
          {data.title}
        </Typography>
      </Wrapper>
    </Stack>
  );
}
