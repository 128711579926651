import { Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import cursorSlider from "../../../images/icon-drag.png";
import cursorReadMore from "../../../images/icon-lees-meer.png";

export default function Cursor({
  children,
  removePointer = false,
  changeCursor = false,
  readMore = false,
  addDelay = false,
  ...props
}) {
  const nodeRef = useRef(null);
  useEffect(() => {
    if (changeCursor) {
      document.body.style.cursor = !readMore
        ? `url(${cursorSlider}) 62 62, auto`
        : `url(${cursorReadMore}) 30 30, auto`;
    } else if (removePointer) {
      document.body.style.cursor = "none";
    }
    const mouseTrailing = (e) => {
      if (!nodeRef.current) return;
      if (addDelay) {
        setTimeout(() => {
          if (!nodeRef.current) return;
          nodeRef.current.style.left = e.pageX + "px";
          nodeRef.current.style.top = e.pageY + "px";
        }, 150);
      } else {
        nodeRef.current.style.left = e.pageX + "px";
        nodeRef.current.style.top = e.pageY + "px";
      }
    };
    document.addEventListener("mousemove", mouseTrailing);

    return () => {
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", mouseTrailing);
    };
  }, [changeCursor, readMore, removePointer, addDelay]);
  return (
    <Stack
      display={changeCursor ? "none" : { xxs: "none", md: "flex" }}
      ref={nodeRef}
      width={124}
      height={124}
      borderRadius="50%"
      zIndex={201}
      color="custom.white"
      bgcolor="custom.controls"
      direction="row"
      gap={0.5}
      justifyContent={"center"}
      alignItems={"center"}
      position="absolute"
      top={0}
      left={0}
      sx={{
        pointerEvents: "none",
        transform: "translate(-50%, -50%)",
        cursor: "none",
        ...props,
      }}
    >
      {children}
    </Stack>
  );
}
