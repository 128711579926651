import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { useOptions } from "../../../hooks/useOptions";
import LinkButtonStyled from "../../elements/LinkButton/LinkButtonStyled";
import { brakeString } from "../../../helpers/brakeString";
import Media from "./Media";

export default function HighlightedBlock({ data }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      py={6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper isBig={data.isBig}>
        <Stack
          display="grid"
          gap={2}
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          bgcolor={data.addBackground ? "custom.white" : palette.secondary}
          borderRadius={3.75}
          overflow="hidden"
          minHeight={
            data.isBig ? { xxs: "auto", lg: 674 } : { xxs: "auto", lg: 618 }
          }
        >
          <Stack
            pl={
              data.isReverse
                ? { xxs: 1.875, sm: 5, lg: 5 }
                : data.isBig
                ? { xxs: 1.875, sm: 5, lg: 10, xl: 17.25 }
                : { xxs: 1.875, sm: 5, lg: 13.5 }
            }
            pr={
              !data.isReverse
                ? { xxs: 1.875, sm: 5, lg: 5 }
                : data.isBig
                ? { xxs: 1.875, sm: 5, lg: 10, xl: 17.25 }
                : { xxs: 1.875, sm: 5, lg: 13.5 }
            }
            justifyContent={data.isBig ? "center" : "end"}
            pt={9}
            pb={{ xxs: 5, lg: 9 }}
            gap={4}
            alignItems="start"
          >
            <Stack gap={2.5}>
              {data.sectionTitle && (
                <Typography
                  component="h2"
                  fontSize={48}
                  lineHeight={1.29}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: brakeString(data.sectionTitle),
                  }}
                ></Typography>
              )}
              {data.tags.data.length > 0 && (
                <Stack
                  component="ul"
                  direction="row"
                  gap={1.25}
                  flexWrap="wrap"
                >
                  {data.tags.data.map((i, idx) => (
                    <Stack
                      key={data.title + "tag" + idx}
                      component="li"
                      borderRadius={15}
                      height={30}
                      bgcolor="custom.bgTag"
                      justifyContent="center"
                      alignItems="center"
                      px={1.5}
                    >
                      <Typography
                        component="span"
                        fontSize={12}
                        fontWeight={600}
                      >
                        {i.attributes.title}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>
            <div>
              <RichText
                data={data.description}
                fontSize={24}
                lineHeight={1.67}
                paragraphMargin={0}
                headingMargin={0}
                headingSize={36}
              />
            </div>
            {data.link && (
              <LinkButtonStyled
                data={data.link}
                underlineColor="transparent"
                svgColor="custom.ph"
              />
            )}
          </Stack>
          <Stack
            justifyContent="end"
            sx={
              isDesktop
                ? {
                    gridColumn: data.isReverse ? 1 : 2,
                    gridRow: 1,
                  }
                : {}
            }
          >
            <Stack
              height={{ xxs: 702, lg: "100%" }}
              position="relative"
              overflow="hidden"
            >
              <Media data={data} />
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
