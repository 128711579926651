import MainNavigation from "../../elements/MainNavigation/MainNavigation";
import SubNavigation from "../../elements/SubNavigation/SubNavigation";
import { Stack, useMediaQuery } from "@mui/material";
import Wrapper from "../../common/Wrapper";
import LinkButtonHeaderCta from "../../elements/LinkButton/LinkButtonHeaderCta";
import Logo from "../../elements/Logo/Logo";
import { useOptions } from "../../../hooks/useOptions";
import "./header.css";
import { useEffect, useRef, useState } from "react";
import MenuButton from "../../elements/MenuButton/MenuButton";
import { motion, AnimatePresence } from "framer-motion";
import CloseButton from "../../elements/CloseButton/CloseButton";
import LinkButtonFooterSocials from "../../elements/LinkButton/LinkButtonFooterSocials";
import InfiniteLogoSVG from "../../elements/Logo/InfiniteLogoSVG";
import { RemoveScroll } from "react-remove-scroll";

export default function Header({ options }) {
  const { isHeroInstalled, isOnTop, setCursorType } = useOptions();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const nodeRef = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", scrollingProgress);
    return () => {
      window.removeEventListener("scroll", scrollingProgress);
    };
  }, []);

  function scrollingProgress() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    setScrollProgress(scrolled);
  }

  const handleMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  return (
    <Stack component="header">
      <Stack
        onMouseEnter={() =>
          setCursorType(isHeroInstalled && isOnTop ? "blur" : "trail")
        }
        onMouseLeave={() => setCursorType("trail")}
        ref={nodeRef}
        pb={2.5}
        pt={{ xxs: 7, md: 8.75 }}
        bgcolor={isOnTop ? "transparent" : "white"}
        position={"fixed"}
        width="100%"
        zIndex={100}
        sx={{
          transform: isOnTop
            ? "translateY(0)"
            : { xxs: "translateY(-36px)", md: "translateY(-50px)" },
          transition: "transform 250ms linear",
        }}
      >
        <Wrapper>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            zIndex={102}
          >
            {options && (
              <SubNavigation
                isLight={!isOnTop || !isHeroInstalled}
                full={isOnTop}
                data={options.subNavigation}
              />
            )}
            <Stack direction="row" alignItems="center">
              <Logo full={isOnTop} isLight={!isOnTop || !isHeroInstalled} />
              {options && (
                <LinkButtonHeaderCta
                  isLight={!isOnTop || !isHeroInstalled}
                  full={isOnTop}
                  data={options.callToAction}
                />
              )}
            </Stack>
            {options && isDesktop && (
              <MainNavigation
                isLight={!isOnTop || !isHeroInstalled}
                data={options.mainNavigation}
              />
            )}
            {options && !isDesktop && (
              <MenuButton
                isLight={!isOnTop || !isHeroInstalled}
                handleMenu={handleMenu}
              />
            )}
          </Stack>
        </Wrapper>
        {!isOnTop && (
          <Stack
            position="absolute"
            right={0}
            bottom={0}
            width="100%"
            height={4}
          >
            <Stack
              bgcolor="custom.primary"
              height="100%"
              width="100%"
              sx={{
                transformOrigin: "center left",
                transform: `scaleX(${scrollProgress})`,
                transition: "transform 100ms linear",
              }}
            ></Stack>
          </Stack>
        )}
      </Stack>
      <AnimatePresence>
        {options && !isDesktop && isShowMenu && (
          <motion.div
            initial={{
              x: "100%",
              y: "-100%",
            }}
            animate={{
              x: 0,
              y: 0,
              transition: { duration: 0.75, ease: [0.4, 0, 0.2, 1] },
            }}
            exit={{
              x: "100%",
              y: "-100%",
              transition: { duration: 0.75, ease: [0.4, 0, 0.2, 1] },
            }}
            style={{
              position: "fixed",
              top: 0,
              left: "-100vw",
              bottom: "-100vh",
              right: 0,
              zIndex: 301,
              backgroundColor: "#0066FF",
              overflowX: "hidden",
              borderBottomLeftRadius: "100vw",
              paddingLeft: "100vw",
              paddingBottom: "100vh",
            }}
          >
            {/* <motion.div
              initial={{
                x: "100%",
                y: "-100%",
              }}
              animate={{
                x: 0,
                y: 0,
                transition: {
                  duration: 0.75,
                  ease: [0.4, 0, 0.2, 1],
                  delay: 1,
                },
              }}
              exit={{
                x: "100%",
                y: "-100%",
                transition: {
                  duration: 0.75,
                  ease: [0.4, 0, 0.2, 1],
                  delay: 1,
                },
              }}
              style={{
                position: "fixed",
                top: 0,
                left: "-100vw",
                bottom: "-100vh",
                right: 0,
                zIndex: 302,
                backgroundColor: "#0066FF",
                overflowX: "hidden",
                borderBottomLeftRadius: "100vw",
                paddingLeft: "100vw",
                paddingBottom: "100vh",
              }}
            ></motion.div> */}
            <motion.div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                zIndex: 303,
              }}
              initial={{
                x: "-200%",
                y: "200%",
              }}
              animate={{
                x: 0,
                y: 0,
                transition: { duration: 0.75, ease: [0.4, 0, 0.2, 1] },
              }}
              exit={{
                x: "-200%",
                y: "200%",
                transition: { duration: 0.75, ease: [0.4, 0, 0.2, 1] },
              }}
            >
              <RemoveScroll style={{ height: "100%", width: "100%" }}>
                <Wrapper
                  pt={7}
                  pb={5}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  height="100%"
                >
                  <Stack>
                    <Stack
                      position="relative"
                      pb={5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      pl={2}
                    >
                      <SubNavigation
                        isLight={false}
                        full={true}
                        data={options.subNavigation}
                        handler={handleMenu}
                      />
                      <InfiniteLogoSVG />
                      <CloseButton
                        handler={handleMenu}
                        strokeWidth={2}
                        size={20}
                        sx={{
                          position: "relative",
                          top: "auto",
                          right: "auto",
                          "& svg": {
                            color: "custom.white",
                          },
                        }}
                      />
                    </Stack>
                    <MainNavigation
                      data={options.mainNavigation}
                      handler={handleMenu}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    component="ul"
                    px={2}
                  >
                    {options &&
                      options.socialLinks.map((i, idx) => (
                        <li key={"footer-soc" + idx} onClick={handleMenu}>
                          <LinkButtonFooterSocials
                            data={i}
                            isLight
                            fontSize={18}
                          />
                        </li>
                      ))}
                  </Stack>
                </Wrapper>
              </RemoveScroll>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
}
