import React from "react";
import Wrapper from "../../common/Wrapper";
import { Swiper, SwiperSlide } from "../../common/Swiper/Swiper";
import { useOptions } from "../../../hooks/useOptions";
import RegularCard from "./RegularCard";
import AllCasesSlide from "../../blocks/AllCases/AllCasesSlide";

export default function RegularSlider({ data, isShort = false }) {
  const { setCursorType } = useOptions();
  return (
    <Wrapper
      extra={{
        px: { xxs: 1.875, sm: 5, lg: 10, xl: 0 },
        pl: { xxs: 1.875, sm: 5, lg: 10, xl: 17.5 },
        pr: { xxs: 1.875, sm: 0, lg: 0, xl: 0 },
      }}
    >
      <div
        onMouseEnter={() => setCursorType("slider")}
        onMouseLeave={() => setCursorType("trail")}
      >
        <Swiper
          height="auto"
          isAsymmetric
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            780: {
              slidesPerView: 2.5,
            },
            1280: {
              slidesPerView: 3.5,
            },
            1920: {
              slidesPerView: 4.5,
            },
          }}
          spaceBetween={16}
          scrollbar={true}
          mousewheel={true}
        >
          {!isShort &&
            data.slides.map((i, idx) => (
              <SwiperSlide key={"regular-cases-swiper" + idx} height="auto">
                <RegularCard
                  height="auto"
                  data={i.project.data.attributes}
                  pos={idx}
                />
              </SwiperSlide>
            ))}
          {isShort &&
            data.slides.map((i, idx) => (
              <SwiperSlide
                key={"regular-short-cases-swiper" + idx}
                height="auto"
              >
                <AllCasesSlide data={i.project.data.attributes} pos={idx} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </Wrapper>
  );
}
