export const servicesColors = {
  MAIN: {
    primary: "#38B3F6",
    secondary: "#DBEBFF",
  },
  DESIGN: {
    primary: "#FFA200",
    secondary: "#FDF1E2 ",
  },
  MARKETING: {
    primary: "#E418A8",
    secondary: "#F8E2F1",
  },
  TECH: {
    primary: "#38B3F6",
    secondary: "#DFE9FD",
  },
  HOSTING: {
    primary: "#89BAF8",
    secondary: "#E6F3FC",
  },
  DEFAULT: {
    primary: "#38B3F6",
    secondary: "#DBEBFF",
  },
};
