import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import LinkButtonFooterLocation from "../LinkButton/LinkButtonFooterLocation";

export default function LocationItem({ selected, data, hoverHandler, pos }) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <Stack onMouseEnter={() => hoverHandler(pos)}>
      <Typography
        fontWeight={600}
        fontSize={18}
        mb={{ xxs: 2, sm: 4.5 }}
        color={selected ? "custom.text" : "custom.ph"}
        sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
      >
        {data.title}
      </Typography>
      {isTablet && (
        <Stack spacing={2}>
          <Typography
            component="address"
            color={selected ? "custom.text" : "custom.ph"}
            lineHeight={1.69}
            sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
          >
            {data.addressStreet}
            <br />
            {data.addressPostcode} {data.title}
          </Typography>
          {data.links.map((i, idx) => (
            <div
              key={"footer-loc-link" + data.title + idx}
              onFocus={() => hoverHandler(pos)}
            >
              <LinkButtonFooterLocation
                data={i}
                color={selected ? "custom.text" : "custom.ph"}
              />
            </div>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
