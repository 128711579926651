import { Stack } from "@mui/material";
import React from "react";
import LinkButtonHeaderSub from "../LinkButton/LinkButtonHeaderSub";

export default function SubNavigation({
  isLight,
  data,
  full,
  handler = () => {},
}) {
  return (
    <Stack
      component="ul"
      position="absolute"
      right={0}
      top={-36}
      width={{ xxs: "100%", sm: "auto" }}
      direction="row"
      gap={{ sm: 6, md: 8 }}
      justifyContent={{ xxs: "space-between", sm: "flex-end" }}
      sx={{
        opacity: full ? 1 : 0,
        transition: "opacity 250ms linear",
      }}
    >
      {data.map((i, idx) => (
        <li key={"sub-link" + idx} style={{ flexShrink: 0 }} onClick={handler}>
          <LinkButtonHeaderSub isLight={isLight} data={i} />
        </li>
      ))}
    </Stack>
  );
}
