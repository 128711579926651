import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Wrapper from "../../common/Wrapper";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import { useOptions } from "../../../hooks/useOptions";

export default function ContactHero({ data }) {
  const { form, setForm, palette } = useOptions();
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    setForm(data.buttons.find((i) => i.formType === "CONTACT"));
  }, [data.buttons, setForm]);

  return (
    <Stack
      component="section"
      pt={29.5}
      pb={8}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Typography
          fontSize={18}
          fontWeight={600}
          color="custom.accentLink"
          mb={0.75}
        >
          {data.subtitle}
        </Typography>
        <Typography fontSize={48} fontWeight={600} lineHeight={1.29} mb={2.5}>
          {getHighlightedText(data.title, data.highlightingWords ?? "")}
        </Typography>
        <Stack
          display="grid"
          gap={{ xxs: 1, lg: 2 }}
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          mb={{ xxs: 7.5, lg: 0 }}
        >
          <Stack>
            <Stack pr={1.75}>
              <RichText data={data.text} fontSize={24} paragraphMargin={7} />
            </Stack>
          </Stack>
          <Stack
            pl={{ lg: "20%" }}
            display="grid"
            gap={2}
            gridTemplateColumns="1fr 1fr"
          >
            {data.links.map((i, idx) => (
              <Stack
                key={"contact-group" + i.title + idx}
                alignItems="start"
                spacing={1.5}
              >
                <Typography fontWeight={600}>{i.title}</Typography>
                {i.links.map((j, idx) => (
                  <LinkButtonFooterLocation
                    color="custom.accentLink"
                    fontSize={16}
                    key={"contact-link" + i.title + idx}
                    data={j}
                  />
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack display="grid" gap={2} gridTemplateColumns={{ lg: "1fr 1fr" }}>
          <Stack>
            <Stack
              pr={2.5}
              display="grid"
              gap={3.75}
              gridTemplateColumns={{ lg: "auto auto" }}
              justifyContent="space-between"
            >
              {data.buttons.map((i, idx) => (
                <Stack
                  key={"contact-anchor" + i.title + idx}
                  onClick={() => {
                    setFormChanged(true);
                    setForm(i);
                  }}
                  component="a"
                  href={`#${i.formType.toLowerCase()}-form`}
                  aria-label={i.title}
                  lineHeight={1.5}
                  sx={{
                    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                    "&:hover": { color: "custom.primary" },
                    "&:focus": { color: "custom.primary" },
                    color:
                      formChanged && i.formType === form?.formType
                        ? "custom.primary"
                        : "custom.text",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 0.5,
                    "& svg": {
                      color:
                        formChanged && i.formType === form?.formType
                          ? "custom.primary"
                          : "custom.ph",
                      transform:
                        formChanged && i.formType === form?.formType
                          ? `rotate(45deg)`
                          : { xxs: `rotate(45deg)`, lg: "" },
                      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                    },
                    "&:hover svg": {
                      color: "custom.primary",
                      transform: `rotate(45deg)`,
                    },
                    "&:focus svg": {
                      color: "custom.primary",
                      transform: `rotate(45deg)`,
                    },
                    "& span::after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "1px",
                      backgroundColor:
                        formChanged && i.formType === form?.formType
                          ? "custom.primary"
                          : "transparent",
                      transition:
                        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                      transformOrigin: "100% 100%",
                    },
                    "&:hover span::after": {
                      backgroundColor: "custom.primary",
                    },
                    "&:focus span::after": {
                      backgroundColor: "custom.primary",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    fontSize={22}
                    sx={{
                      position: "relative",
                    }}
                  >
                    {i.title}
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M7 7l9.2 9.2M17 7v10H7" />
                  </svg>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
