import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import LinkButtonGhost from "../../elements/LinkButton/LinkButtonGhost";

export default function CaseDescription({ data }) {
  return (
    <Stack component="section" pt={10} pb={10}>
      <HiddenTitle>{data.title}</HiddenTitle>
      <Wrapper display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
        <Stack>
          <Typography
            fontWeight={600}
            fontSize={data.main.text ? 36 : 18}
            color="custom.accentLink"
            mb={data.main.text ? 1.75 : 1}
          >
            {data.main.title}
          </Typography>
          <Stack alignItems="start">
            {data.main.text && (
              <RichText
                data={data.main.text}
                fontSize={24}
                paragraphMargin={2}
                lineHeight={1.67}
              />
            )}
            {data.main.link &&
              data.main.link.map((i, idx) => (
                <LinkButtonFooterLocation
                  key={"case-desc-links" + idx}
                  data={i}
                  fontSize={data.main.text ? 22 : 20}
                  gap={0.5}
                  underlineColor="transparent"
                  translateY={-3}
                  svgColor="custom.ph"
                />
              ))}
          </Stack>
        </Stack>
        <Stack pl={{ lg: "40%" }} pt={2}>
          {data.clientName && (
            <Stack
              spacing={1.25}
              pt={2}
              pb={2.75}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E8E8E8",
                },
              }}
            >
              <Typography
                color="custom.accentLink"
                fontSize={18}
                fontWeight={600}
                lineHeight={1.33}
              >
                De klant
              </Typography>
              <Typography fontWeight={600} fontSize={20}>
                {data.clientName}
              </Typography>
            </Stack>
          )}
          {data.tags && (
            <Stack
              spacing={1.5}
              pt={2}
              pb={2.75}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E8E8E8",
                },
              }}
            >
              <Typography
                color="custom.accentLink"
                fontSize={18}
                fontWeight={600}
                lineHeight={1.33}
              >
                Onze diensten
              </Typography>
              <Stack component="ul" direction="row" gap={1.25}>
                {data.tags.data.map((i, idx) => (
                  <Stack
                    key={data.title + "case-descr-tag" + idx}
                    component="li"
                    borderRadius={15}
                    height={30}
                    bgcolor="custom.bgTag"
                    justifyContent="center"
                    alignItems="center"
                    px={1.5}
                  >
                    <Typography component="span" fontSize={12} fontWeight={600}>
                      {i.attributes.title}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {data.links && (
            <Stack
              spacing={1.25}
              pt={2}
              pb={2.75}
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #E8E8E8",
                },
              }}
            >
              <Typography
                color="custom.accentLink"
                fontSize={18}
                fontWeight={600}
                lineHeight={1.33}
              >
                Het resultaat
              </Typography>
              <Stack direction="row" gap={5.75}>
                {data.links.map((i, idx) => (
                  <LinkButtonGhost
                    key={data.title + "case-descr-link" + idx}
                    data={{ ...i, color: "ACCENT LINK" }}
                    fontSize={20}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
