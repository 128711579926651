import Hero from "../components/blocks/Hero/Hero";
import Accordion from "../components/blocks/Accordion/Accordion";
import CasesSlider from "../components/blocks/CasesSlider/CasesSlider";
import ServiceList from "../components/blocks/ServiceList/ServiceList";
import LatestNews from "../components/blocks/LatestNews/LatestNews";
import Gallery from "../components/blocks/Gallery/Gallery";
import CallToAction from "../components/blocks/CallToAction/CallToAction";
import Ticker from "../components/blocks/Ticker/Ticker";
import TextList from "../components/blocks/TextList/TextList";
import LinkCards from "../components/blocks/LinkCards/LinkCards";
import About from "../components/blocks/About/About";
import TestimonialsSlider from "../components/blocks/TestimonialsSlider/TestimonialsSlider";
import ContentBlock from "../components/blocks/ContentBlock/ContentBlock";
import SubscriptionForm from "../components/blocks/SubscriptionForm/SubscriptionForm";
import Support from "../components/blocks/Support/Support";
import AllCases from "../components/blocks/AllCases/AllCases";
import Partners from "../components/blocks/Partners/Partners";
import VacanciesDescription from "../components/blocks/VacanciesDescription/VacanciesDescription";
import Vacancies from "../components/blocks/Vacancies/Vacancies";
import Advantages from "../components/blocks/Advantages/Advantages";
import ContactHero from "../components/blocks/ContactHero/ContactHero";
import FormsPlaceholder from "../components/blocks/FormsPlaceholder/FormsPlaceholder";
import Contact from "../components/blocks/Contact/Contact";
import Banner from "../components/blocks/Banner/Banner";
import ImageBanner from "../components/blocks/ImageBanner/ImageBanner";
import ContentBlockAccordion from "../components/blocks/ContentBlockAccordion/ContentBlockAccordion";
import ContentBlockImage from "../components/blocks/ContentBlockImage/ContentBlockImage";
import HighlightedBlock from "../components/blocks/HighlightedBlock/HighlightedBlock";
import ContentBlockList from "../components/blocks/ContentBlockList/ContentBlockList";
import LargeImageBanner from "../components/blocks/LargeImageBanner/LargeImageBanner";
import Achievements from "../components/blocks/Achievements/Achievements";
import Testimonial from "../components/blocks/Testimonial/Testimonial";
import CaseDescription from "../components/blocks/CaseDescription/CaseDescription";
import CaseHero from "../components/blocks/CaseHero/CaseHero";
import VacancyAbout from "../components/blocks/VacancyAbout/VacancyAbout";
import Error from "../components/common/Error/Error";

export const renderLayoutFromPageData = (data, breadcrumbs) => {
  if (!data || data.length === 0) return <Error />;

  return data.map((i, idx) => {
    if (i.__component === "blocks.hero") {
      return (
        <Hero key={i.__component + idx} data={i} breadcrumbs={breadcrumbs} />
      );
    } else if (i.__component === "blocks.accordion") {
      return <Accordion key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.cases-slider") {
      return <CasesSlider key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.service-list") {
      return <ServiceList key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.latest-news") {
      return <LatestNews key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.gallery") {
      return <Gallery key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.call-to-action") {
      return <CallToAction key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.ticker") {
      return <Ticker key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.text-list") {
      return <TextList key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.link-cards") {
      return <LinkCards key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.about") {
      return <About key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.testimonials-slider") {
      return <TestimonialsSlider key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.subscription-form") {
      return <SubscriptionForm key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.support") {
      return <Support key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.all-cases") {
      return <AllCases key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.partners") {
      return <Partners key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.vacancies-description") {
      return <VacanciesDescription key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.vacancies") {
      return <Vacancies key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.advantages") {
      return <Advantages key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.contact-hero") {
      return <ContactHero key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.contacts-forms-placeholder") {
      return <FormsPlaceholder key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.contact-form") {
      return <Contact key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.banner") {
      return <Banner key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.image-banner") {
      return <ImageBanner key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.content-block") {
      return <ContentBlock key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.content-block-accordion") {
      return <ContentBlockAccordion key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.content-block-image") {
      return <ContentBlockImage key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.content-block-list") {
      return <ContentBlockList key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.highlighted-block") {
      return <HighlightedBlock key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.large-image-banner") {
      return <LargeImageBanner key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.achievements") {
      return <Achievements key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.testimonial") {
      return <Testimonial key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.case-description") {
      return <CaseDescription key={i.__component + idx} data={i} />;
    } else if (i.__component === "blocks.case-hero") {
      return (
        <CaseHero
          key={i.__component + idx}
          data={i}
          breadcrumbs={breadcrumbs}
        />
      );
    } else if (i.__component === "blocks.vacancy-about") {
      return <VacancyAbout key={i.__component + idx} data={i} />;
    } else return null;
  });
};
