import React from "react";
import { TextField } from "@mui/material";

export default function InputField({ name, label, textArea = false, formik }) {
  return (
    <>
      {textArea ? (
        <TextField
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          label={label}
          fullWidth
          multiline
          minRows={textArea}
          name={name}
          //   type="text"
          variant="standard"
          sx={{
            "& input": { pb: 1.5, pt: 0.75, fontSize: 16, lineHeight: 1.33 },
          }}
        />
      ) : (
        <TextField
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          label={label}
          fullWidth
          name={name}
          type="text"
          variant="standard"
          sx={{
            "& input": { pb: 1.5, pt: 0.75, fontSize: 16, lineHeight: 1.33 },
          }}
        />
      )}
    </>
  );
}
