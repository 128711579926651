import { Stack, Typography } from "@mui/material";
import React from "react";
import { useOptions } from "../../../hooks/useOptions";
import Wrapper from "../../common/Wrapper";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";

export default function VacancyAbout({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      pt={{ xxs: 6, lg: 10 }}
      pb={6}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper
        display="grid"
        gap={{ lg: 2 }}
        gridTemplateColumns={{ lg: "7fr 3fr" }}
      >
        <Stack>
          <Typography
            component="h2"
            fontWeight={600}
            fontSize={36}
            color="custom.accentLink"
            mb={2}
          >
            {data.title}
          </Typography>
          <Stack component="ul" gap={{ xxs: 2, lg: 0.5 }}>
            {data.main.map((i, idx) => (
              <Stack
                component="li"
                key={data.title + "vacancy" + idx}
                display="grid"
                gap={{ lg: 2 }}
                gridTemplateColumns={{ lg: "1fr 6fr" }}
              >
                <Typography fontSize={24} fontWeight={600}>
                  {i.title}
                </Typography>
                <Typography fontSize={24}>{i.value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack pt={4} gap={1.5}>
          <Typography
            color="custom.accentLink"
            fontWeight={600}
            fontSize={18}
            mb={1}
          >
            {data.linksBlockTitle}
          </Typography>
          {data.links.map((i, idx) => (
            <LinkButtonFooterLocation
              key={"vacancy-about-link" + idx}
              data={i}
              underlineColor="transparent"
              fontSize={20}
            />
          ))}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
