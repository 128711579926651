import { Stack, Typography } from "@mui/material";
import React from "react";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import { useParallax } from "react-scroll-parallax";

export default function Linkdata({ data, pos }) {
  const parallax = useParallax({
    speed: pos % 2 === 0 ? -3 : -6,
  });
  return (
    <Stack
      key={data.title + pos}
      ref={parallax.ref}
      pl={data.text ? 0 : { xxs: 0, lg: 34.5 }}
      pt={data.text ? 0 : { xxs: 0, lg: 3 }}
    >
      <Typography
        fontWeight={600}
        fontSize={data.text ? 36 : { xxs: 24, lg: 18 }}
        color="custom.primary"
        mb={data.text ? 1.75 : 1}
      >
        {data.title}
      </Typography>
      <Stack alignItems="start">
        {data.text && (
          <RichText
            data={data.text}
            fontSize={24}
            paragraphMargin={3.75}
            lineHeight={1.67}
          />
        )}
        <Stack gap={1.25}>
          {data.link &&
            data.link.map((i, idx) => (
              <LinkButtonFooterLocation
                key={"hero-cta-links" + idx}
                data={i}
                fontSize={data.text ? 22 : 20}
                gap={0.5}
                underlineColor="transparent"
                translateY={-3}
                svgColor="custom.ph"
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
