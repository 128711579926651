import React, { useState } from "react";
import { useFormik } from "formik";
import { RadioGroup, Stack, Typography } from "@mui/material";
import { createFormSubmitting } from "../../../services/createFormSubmitting";
import { toast } from "react-toastify";
import { schemaColaborationForm } from "../../../helpers/validationSchema/schemaColaborationForm";
import ArrowButton from "../ArrowButton/ArrowButton";
import CheckField from "../CheckField/CheckField";
import { Link } from "react-router-dom";
import CheckGroupField from "../CheckGroupField/CheckGroupField";
import InputField from "../InputField/InputField";
import RadioField from "../RadioField/RadioField";

const initialValues = {
  name: "",
  phone: "",
  email: "",
  companyName: "",
  description: "",
  service: [],
  time: "",
  privacy: false,
  subscribe: false,
};

export default function ColaborationForm() {
  const [isDisabled, setIsDisabled] = useState(true);

  const formik = useFormik({
    initialValues,
    validationSchema: schemaColaborationForm,
    onSubmit: async (values, { resetForm }) => {
      const formData = { ...values };
      delete formData.privacy;
      await createFormSubmitting("COLLABORATION", formData).then(() =>
        toast(
          <>
            <Typography mb={0.5}>Verstuurd</Typography>
            <Typography fontWeight={600} fontSize={48} mb={2} lineHeight={1.33}>
              Succes
            </Typography>
            <Typography fontSize={18} lineHeight={2}>
              Bedankt voor je bericht! We nemen zo snel mogelijk contact met je
              op.
            </Typography>
          </>
        )
      );

      resetForm(initialValues);
      setIsDisabled(true);
    },
  });
  return (
    <Stack id="collaboration">
      <Typography
        mb={0.5}
        fontWeight={600}
        fontSize={18}
        color="custom.accentLink"
      >
        Aan de slag
      </Typography>
      <Typography mb={0.5} fontWeight={600} fontSize={36} lineHeight={1.33}>
        Waar mogen we je mee helpen?
      </Typography>
      <Stack
        component="form"
        id="colaboration"
        onSubmit={formik.handleSubmit}
        display="grid"
        gap={2}
        gridTemplateColumns={{ lg: "1fr 1fr" }}
      >
        <Stack gap={9} mb={3} pt={3}>
          <Stack
            display="grid"
            gridTemplateColumns={{ lg: "1fr 1fr" }}
            rowGap={4}
            columnGap={2}
            pr="20%"
          >
            <Stack gap={1.5}>
              <CheckGroupField
                formik={formik}
                name="service"
                label="Nieuwe website/webshop"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Sterke content"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Hosting en domeinbeheer"
              />
            </Stack>
            <Stack gap={1.5}>
              <CheckGroupField
                formik={formik}
                name="service"
                label="Webshop optimalisatie"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Applicatie"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Webshop sales"
              />
            </Stack>
            <Stack gap={1.5}>
              <CheckGroupField
                formik={formik}
                name="service"
                label="Vindbaarheid"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Merkbekendheid"
              />
              <CheckGroupField
                formik={formik}
                name="service"
                label="Branding"
              />
            </Stack>
          </Stack>

          <InputField
            formik={formik}
            name="description"
            label="Bericht"
            textArea={2}
          />
        </Stack>
        <Stack display="grid" gridTemplateColumns={{ lg: "3fr 2fr" }} gap={2}>
          <Stack gap={2} mb={3}>
            <Stack pr={{ lg: "33%" }}>
              <InputField
                formik={formik}
                name="name"
                label="Je naam"
                required
              />
            </Stack>
            <Stack pr={{ lg: "33%" }}>
              <InputField
                formik={formik}
                name="phone"
                label="Wat is je telefoonnummer?"
              />
            </Stack>
            <InputField
              formik={formik}
              name="email"
              label="Wat is je e-mail?"
              required
            />
            <InputField
              formik={formik}
              name="companyName"
              label="Wat is je bedrijfsnaam?"
            />
          </Stack>
          <Stack pt={2.5}>
            <Typography fontSize={18} mb={3.5}>
              Wanneer kunnen we bellen?
            </Typography>
            <RadioGroup
              name="time"
              value={formik.values.time}
              onChange={formik.handleChange}
              sx={{
                "& .MuiFormControlLabel-root": {
                  marginLeft: 0,
                  marginRight: 0,
                },
                "& .MuiButtonBase-root": {
                  paddingLeft: 0,
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingRight: 1.5,
                },
              }}
            >
              <RadioField formik={formik} name="time" label="In de ochtend" />
              <RadioField
                formik={formik}
                name="time"
                label="Begin van de middag"
              />
              <RadioField
                formik={formik}
                name="time"
                label="Eind van de middag"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack
          gap={{ xxs: 2.5, lg: 5.5 }}
          direction={{ xxs: "column-reverse", lg: "row" }}
          alignItems="start"
        >
          <ArrowButton text="Verzenden" isSumbit isDisabled={isDisabled} />
          <Stack gap={1}>
            <CheckField
              formik={formik}
              name="privacy"
              func={(checked) => setIsDisabled(!checked)}
              label={
                <>
                  Ja, ik ga akkoord met de{" "}
                  <Stack
                    component={Link}
                    display="inline"
                    color="custom.primary"
                    to="/"
                  >
                    Privacy- en cookieverklaring
                  </Stack>
                  .
                </>
              }
            />
            <CheckField
              formik={formik}
              name="subscribe"
              label="Ja, ik ontvang ook graag jullie nieuwsbrief."
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
