import { Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { Image } from "./Gallery.styled";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

const speedDict = {
  0: -5,
  1: -12,
  2: -20,
  3: -30,
  4: -20,
  5: -40,
  6: -20,
  7: -10,
  8: -5,
  9: -10,
};
const posDict = {
  0: "-8%",
  1: "-16%",
  2: "-16%",
  3: "-22%",
  4: "-15%",
  5: "-28%",
  6: "-25%",
  7: "-14%",
  8: "-15%",
  9: "-25%",
};

const speedDictMob = {
  0: -5,
  1: -4,
  2: -10,
  3: -20,
  4: -15,
  5: -8,
};
const posDictMob = {
  0: "-15%",
  1: "-12%",
  2: "-15%",
  3: "-30%",
  4: "-25%",
  5: "-14%",
};

export default function GridImage({ data, pos }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const parallax = useParallax({
    speed: isDesktop ? speedDict[pos % 10] : speedDictMob[pos % 6],
  });
  const gridPos = Math.floor(pos / (isDesktop ? 10 : 6)) * (isDesktop ? 10 : 8);
  const dict = {
    0: {
      gridColumnStart: 1,
      gridColumnEnd: { xxs: 3, lg: 4 },
      gridRowStart: gridPos + 1,
      gridRowEnd: { xxs: gridPos + 3, lg: gridPos + 4 },
    },
    1: {
      gridColumnStart: { xxs: 3, lg: 4 },
      gridColumnEnd: { xxs: 5, lg: 7 },
      gridRowStart: gridPos + 1,
      gridRowEnd: { xxs: gridPos + 3, lg: gridPos + 4 },
    },
    2: {
      gridColumnStart: { xxs: 1, lg: 7 },
      gridColumnEnd: { xxs: 4, lg: 11 },
      gridRowStart: { xxs: gridPos + 3, lg: gridPos + 1 },
      gridRowEnd: { xxs: gridPos + 6, lg: gridPos + 5 },
    },
    3: {
      gridColumnStart: { xxs: 4, lg: 1 },
      gridColumnEnd: 5,
      gridRowStart: { xxs: gridPos + 3, lg: gridPos + 4 },
      gridRowEnd: { xxs: gridPos + 6, lg: gridPos + 8 },
      aspectRatio: isDesktop ? "1/1" : "auto",
    },
    4: {
      gridColumnStart: { xxs: 1, lg: 5 },
      gridColumnEnd: { xxs: 2, lg: 7 },
      gridRowStart: { xxs: gridPos + 6, lg: gridPos + 4 },
      gridRowEnd: { xxs: gridPos + 9, lg: gridPos + 8 },
      aspectRatio: "auto",
    },
    5: {
      gridColumnStart: { xxs: 2, lg: 7 },
      gridColumnEnd: { xxs: 5, lg: 11 },
      gridRowStart: { xxs: gridPos + 6, lg: gridPos + 5 },
      gridRowEnd: gridPos + 9,
    },
    6: {
      gridColumnStart: 1,
      gridColumnEnd: 4,
      gridRowStart: gridPos + 8,
      gridRowEnd: gridPos + 11,
    },
    7: {
      gridColumnStart: 4,
      gridColumnEnd: 7,
      gridRowStart: gridPos + 8,
      gridRowEnd: gridPos + 11,
    },
    8: {
      gridColumnStart: 7,
      gridColumnEnd: 9,
      gridRowStart: gridPos + 9,
      gridRowEnd: gridPos + 11,
    },
    9: {
      gridColumnStart: 9,
      gridColumnEnd: 11,
      gridRowStart: gridPos + 9,
      gridRowEnd: gridPos + 11,
    },
  };

  return (
    <Stack
      position="relative"
      component="li"
      borderRadius={3.75}
      overflow="hidden"
      sx={{ aspectRatio: "1/1", ...dict[pos % (isDesktop ? 10 : 6)] }}
    >
      <Stack
        position="absolute"
        top={isDesktop ? posDict[pos % 10] : posDictMob[pos % 6]}
        bottom={isDesktop ? posDict[pos % 10] : posDictMob[pos % 6]}
        left={0}
        right={0}
        zIndex={2}
        ref={parallax.ref}
      >
        <Image src={api + data.url} alt={data.alternativeText} />
      </Stack>
    </Stack>
  );
}
