import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { GlobalStyle } from "./components/common/GlobalStyle";
import axios from "axios";
import { ParallaxProvider } from "react-scroll-parallax";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function App() {
  return (
    <>
      <GlobalStyle />
      <ParallaxProvider>
        <RouterProvider router={router} />
      </ParallaxProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        toastClassName="custom-toast"
        style={{ width: "400px" }}
        closeButton={false}
      />
    </>
  );
}

export default App;
