import { Stack } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { useOptions } from "../../../hooks/useOptions";
import LinkCard from "./LinkCard";

export default function LinkCards({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      py={{ xxs: 6, lg: 10 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <HiddenTitle>{data.title}</HiddenTitle>
      <Wrapper
        display="grid"
        gridTemplateColumns={{ lg: "1fr 1fr" }}
        gap={{ xxs: 5, lg: 2 }}
      >
        {data.cards.map((card, idx) => (
          <LinkCard
            key={"link-card" + data.title + idx}
            data={card}
            pos={idx}
          />
        ))}
      </Wrapper>
    </Stack>
  );
}
