import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import LinkButtonStyled from "../../elements/LinkButton/LinkButtonStyled";
import { useOptions } from "../../../hooks/useOptions";
import BannerLabel from "./BannerLabel";

export default function Banner({ data }) {
  const { palette } = useOptions();
  return (
    <Stack component="section" pt={6} pb={12.5}>
      <Wrapper>
        <Stack pr={{ xxs: 3.75, md: 0 }} px={{ md: 10, lg: 17.5, lg2: 34.75 }}>
          <Stack
            pt={{ xxs: 5.5, lg: 7 }}
            pb={6}
            px={{ xxs: 3.75, sm: 5, md: 6, lg: 11.75 }}
            border="1px dashed #CECECE"
            alignItems="start"
            position="relative"
            bgcolor="custom.white"
            sx={{
              background: data.addBackground
                ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
                : "",
            }}
          >
            <Typography
              fontSize={18}
              fontWeight={600}
              color="custom.accentLink"
              mb={0.5}
              lineHeight={1.33}
            >
              {data.title}
            </Typography>
            <Typography
              fontSize={36}
              fontWeight={600}
              lineHeight={1.33}
              mb={3.25}
              sx={{ overflowWrap: "anywhere" }}
            >
              {getHighlightedText(data.description, data.highlightingWords)}
            </Typography>
            {data.link && <LinkButtonStyled data={data.link} />}
            {data.label && <BannerLabel data={data.label} />}
          </Stack>
        </Stack>
      </Wrapper>
    </Stack>
  );
}
