import { createBrowserRouter } from "react-router-dom";
import Error from "./components/common/Error/Error";
import Regular from "./pages/Regular/Regular";
import SharedLayout from "./components/common/SharedLayout/SharedLayout";
import NotFound from "./pages/NotFound/NotFound";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <SharedLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Regular />,
        errorElement: <Error />,
      },
      {
        path: "/:slug",
        element: <Regular />,
        errorElement: <Error />,
      },
      {
        path: "/news/:slug",
        element: <Regular collection="news" />,
        errorElement: <Error />,
      },
      {
        path: "/projects/:slug",
        element: <Regular collection="projects" />,
        errorElement: <Error />,
      },
      {
        path: "/services/:slug",
        element: <Regular collection="services" />,
        errorElement: <Error />,
      },
      {
        path: "/vacancies/:slug",
        element: <Regular collection="vacancies" />,
        errorElement: <Error />,
      },
      {
        path: "*",
        element: <NotFound />,
        errorElement: <Error />,
      },
    ],
  },
]);
