import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";

export default function CheckGroupField({ name, label, formik }) {
  return (
    <FormControlLabel
      sx={{
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        marginLeft: 0,
        marginRight: 0,
        color: formik.values[name].includes(label)
          ? "custom.primary"
          : "custom.ph",
      }}
      control={
        <Checkbox
          onChange={formik.handleChange}
          checked={formik.values[name].includes(label)}
          name={name}
          value={label}
          icon={
            <Stack
              width={22}
              height={22}
              borderRadius={1.25}
              border="1px solid #CECECE"
            ></Stack>
          }
          checkedIcon={
            <Stack
              width={22}
              height={22}
              borderRadius={1.25}
              border="1px solid #CECECE"
              justifyContent="center"
              alignItems="center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0FC100"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </Stack>
          }
        />
      }
      label={label}
    />
  );
}
