import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import InputField from "../../elements/InputField/InputField";
import SupportUserDataItem from "./SupportUserDataItem";
import CheckField from "../../elements/CheckField/CheckField";
import ArrowButton from "../../elements/ArrowButton/ArrowButton";
import { useFormik } from "formik";
import { schemaSupportForm } from "../../../helpers/validationSchema/schemaSupportForm";
import { createFormSubmitting } from "../../../services/createFormSubmitting";
import { toast } from "react-toastify";
import { useDeviceData } from "react-device-detect";
import { getUserIP4 } from "../../../services/getUserIP4";
import { getUserIP6 } from "../../../services/getUserIP6";
import { Link } from "react-router-dom";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  description: "",
  privacy: false,
  personalData: false,
};

export default function SupportForm() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [userIP4, setUserIP4] = useState(null);
  const [userIP6, setUserIP6] = useState(null);
  const {
    ua,
    browser: { name, version },
  } = useDeviceData();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    getUserIP4().then((data) => setUserIP4(data));
    getUserIP6().then((data) => setUserIP6(data));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schemaSupportForm,
    onSubmit: async (values, { resetForm }) => {
      const formData = { ...values };
      delete formData.privacy;
      delete formData.personalData;
      if (values.personalData) {
        formData.userIP4 = userIP4;
        formData.userIP6 = userIP6;
        formData.browser = { name, version };
        formData.ua = ua;
      }
      await createFormSubmitting("SUPPORT", formData).then(() =>
        toast(
          <>
            <Typography mb={0.5}>Verstuurd</Typography>
            <Typography fontWeight={600} fontSize={48} mb={2} lineHeight={1.33}>
              Succes
            </Typography>
            <Typography fontSize={18} lineHeight={2}>
              Bedankt voor je bericht! We nemen zo snel mogelijk contact met je
              op.
            </Typography>
          </>
        )
      );

      resetForm(initialValues);
      setIsDisabled(true);
    },
  });
  return (
    <Stack
      component="form"
      id="support"
      onSubmit={formik.handleSubmit}
      gap={{ xxs: 2, lg: 0 }}
    >
      <Stack display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
        <Stack>
          <Typography
            fontSize={18}
            fontWeight={600}
            lineHeight={1.33}
            color="custom.accentLink"
            mb={0.5}
          >
            Supportformulier
          </Typography>
          <Typography fontSize={36} fontWeight={600} lineHeight={1.33} mb={1.5}>
            Hoe kunnen we helpen?
          </Typography>
          <Stack gap={4} mb={3}>
            <InputField formik={formik} name="name" label="Je naam" required />
            <InputField
              formik={formik}
              name="email"
              label="Wat is je e-mail?"
              required
            />
            <InputField
              formik={formik}
              name="phone"
              label="Wat is je telefoonnummer?"
            />
            <InputField
              formik={formik}
              name="description"
              label="Bericht"
              textArea={isDesktop ? 10 : 4}
              required
            />
          </Stack>
        </Stack>
        <Stack pt={3.5} id="user-data">
          <Typography fontSize={36} fontWeight={600} lineHeight={1.33} mb={1.5}>
            Mijn gegevens
          </Typography>
          <Stack component="ul" spacing={2.5} mb={1.75}>
            <SupportUserDataItem name="IPv4" value={userIP4?.ip} />
            <SupportUserDataItem name="IPv4 hostname" value={userIP4?.host} />
            <SupportUserDataItem name="IPv6" value={userIP6?.ip} />
            <SupportUserDataItem name="IPv6 hostname" value={userIP6?.host} />
            <SupportUserDataItem
              name="Browser & besturingssysteem"
              value={ua}
            />
          </Stack>
          <Typography fontSize={12}>
            Met deze gegevens kunnen we je nog beter helpen.
          </Typography>
        </Stack>
      </Stack>
      <Stack
        gap={{ xxs: 2.5, lg: 5.5 }}
        direction={{ xxs: "column-reverse", lg: "row" }}
        alignItems="start"
      >
        <ArrowButton text="Verzenden" isSumbit isDisabled={isDisabled} />
        <Stack gap={1}>
          <CheckField
            formik={formik}
            name="privacy"
            func={(checked) => setIsDisabled(!checked)}
            label={
              <>
                Ja, ik ga akkoord met de{" "}
                <Stack
                  component={Link}
                  display="inline"
                  color="custom.primary"
                  to="/"
                >
                  Privacy- en cookieverklaring
                </Stack>
                .
              </>
            }
          />
          <CheckField
            formik={formik}
            name="personalData"
            label="Ja, voeg ook ‘Mijn gegevens’ toe aan het bericht."
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
