import { Stack, Typography } from "@mui/material";
import React from "react";

export default function FilterButton({ data, handler, isSelected }) {
  return (
    <Stack
      borderRadius={15}
      height={30}
      bgcolor={isSelected ? "custom.bgTag" : "custom.bgTagLight"}
      justifyContent="center"
      alignItems="center"
      px={1.5}
      component="button"
      type="button"
      onClick={() => handler(data.id)}
      sx={{
        cursor: "pointer",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          bgcolor: "custom.bgTag",
        },
        "&:focus": {
          bgcolor: "custom.bgTag",
        },
      }}
    >
      <Typography
        component="span"
        fontSize={12}
        fontWeight={isSelected ? 600 : 400}
      >
        {data.attributes.title}
      </Typography>
    </Stack>
  );
}
