import React from "react";
import AnimatedLetter from "./AnimatedLetter";

export default function AnimatedLetters({
  text,
  baseDelay = 100,
  baseTime = 100,
  delay = 300,
  time = 500,
}) {
  const letters = text.split("");
  return letters.map((letter, i) => (
    <AnimatedLetter
      key={"cta" + text + i}
      baseDelay={baseDelay}
      baseTime={baseTime}
      delay={delay}
      time={time}
    >
      {letter}
    </AnimatedLetter>
  ));
}
