import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import SubscriptionField from "./SubscriptionField";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";

export default function SubscriptionForm({ data }) {
  const { palette } = useOptions();
  const parallax = useParallax({
    speed: -5,
  });
  const parallaxTxt = useParallax({
    speed: -3,
  });
  return (
    <Stack
      component="section"
      pt={{ xxs: 6, lg: 14 }}
      pb={{ xxs: 6, lg: 8 }}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <HiddenTitle>{data.title}</HiddenTitle>
      <Wrapper display="grid" gridTemplateColumns={{ lg: "1fr 1fr" }} gap={2}>
        <Stack
          pr={{ lg: 10 }}
          spacing={5}
          width={{ lg: "90%" }}
          ref={parallax.ref}
        >
          <Typography fontSize={36} fontWeight={600} lineHeight={1.29}>
            {getHighlightedText(data.ctaText, data.highlightingWords)}
          </Typography>
          <SubscriptionField />
        </Stack>
        <Stack pt={{ xxs: 6, lg: 2 }} ref={parallaxTxt.ref}>
          {data.description && (
            <>
              <RichText
                fontSize={24}
                paragraphMargin={1.5}
                lineHeight={1.5}
                data={data.description}
              />
              {data.subDescription && (
                <Typography fontSize={14}>{data.subDescription}</Typography>
              )}
            </>
          )}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
