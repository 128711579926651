import { Stack, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import LinkButtonSupport from "../../elements/LinkButton/LinkButtonSupport";
import { brakeString } from "../../../helpers/brakeString";

export default function SupportCard({
  data,
  hovered,
  handleHover,
  pos,
  addBackground,
}) {
  const [descrHeight, setDescrHeight] = useState();
  const regularNode = useRef(null);
  const hoveredNode = useRef(null);

  useEffect(() => {
    if (hoveredNode.current && hovered) {
      setDescrHeight(hoveredNode.current.clientHeight);
    }
    if (regularNode.current && !hovered) {
      setDescrHeight(regularNode.current.clientHeight);
    }
  }, [regularNode, hoveredNode, hovered]);
  return (
    <Stack
      component="li"
      borderRadius={3.75}
      overflow="hidden"
      bgcolor={
        hovered
          ? "custom.accentLink"
          : addBackground
          ? "custom.white"
          : "custom.bg"
      }
      sx={{ transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)" }}
      onMouseEnter={() => handleHover(pos)}
      onMouseLeave={() => handleHover(null)}
    >
      <Stack
        gap={3.75}
        height="100%"
        justifyContent={hovered ? "end" : "space-between"}
        pt={6.25}
        pb={7.5}
        px={{ xxs: 3.75, sm: 5, lg: 3.75 }}
        sx={{
          background:
            "transparent linear-gradient(118deg, transparent 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <Stack>
          <Typography
            mb={0.625}
            color={hovered ? "custom.white" : "custom.text"}
            sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
          >
            {data.subtitle}
          </Typography>
          <Typography
            color={hovered ? "custom.white" : "custom.text"}
            sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
            fontSize={48}
            fontWeight={600}
            lineHeight={1.33}
            mb={4}
            pr={2}
            component="h3"
            dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
          ></Typography>

          <Stack
            height={descrHeight}
            sx={{ transition: "height 500ms cubic-bezier(0.4, 0, 0.2, 1)" }}
          >
            {!hovered ? (
              <Typography ref={regularNode} fontSize={18} lineHeight={2.22}>
                {data.description}
              </Typography>
            ) : (
              <Stack
                ref={hoveredNode}
                direction="row"
                alignItems="end"
                justifyContent="space-between"
              >
                <Typography
                  fontSize={18}
                  lineHeight={1.82}
                  color="custom.white"
                >
                  {data.subDescription}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack component="ul" direction="row" gap={2.22} flexWrap="wrap">
          {data.links.map((i, idx) => (
            <LinkButtonSupport
              key={data.title + "link" + idx}
              data={i}
              color="custom.primary"
              underlineColor="custom.primary"
              fontSize={18}
            />
          ))}
          {data.addAnchor && (
            <LinkButtonSupport
              color="custom.primary"
              underlineColor="custom.primary"
              fontSize={18}
              data={{
                type: "ANCHOR",
                title: "Formulier",
                link: "#support",
              }}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
