import { useRef, useState, useEffect } from "react";
import { Card } from "./ServiceCard.styled";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { useOptions } from "../../../hooks/useOptions";
import { servicesColors } from "../../../helpers/servicesColors";
import { useNavigate } from "react-router-dom";
import "./ServiceCard.css";

export default function ServiceCard({
  data,
  hovered,
  handleHover,
  pos,
  isBig = false,
  sx,
  component = "li",
}) {
  const { palette } = useOptions();
  const [descrHeight, setDescrHeight] = useState();
  const regularNode = useRef(null);
  const hoveredNode = useRef(null);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  useEffect(() => {
    if (hoveredNode.current && hovered) {
      setDescrHeight(hoveredNode.current.clientHeight);
    }
    if (regularNode.current && !hovered) {
      setDescrHeight(regularNode.current.clientHeight);
    }
  }, [regularNode, hoveredNode, hovered]);

  const onClickHandler = (e) => {
    if (!isDesktop) {
      e.preventDefault();
      if (hovered) {
        handleHover(null);
      } else {
        handleHover(pos);
      }
    }
  };

  const onArrowClick = (e) => {
    if (isDesktop) {
      e.preventDefault();
    } else {
      navigate("/services/" + data.service.data.attributes.slug);
    }
  };
  return (
    <Stack
      component={component}
      borderRadius={3.75}
      overflow="hidden"
      display="grid"
      onMouseEnter={() => {
        if (isDesktop) handleHover(pos);
      }}
      onMouseLeave={() => {
        if (isDesktop) handleHover(null);
      }}
      sx={{
        borderEndEndRadius: isBig ? 0 : 15,
        borderEndStartRadius: isBig ? 0 : 15,
        ...sx,
      }}
    >
      <Stack
        bgcolor={
          hovered
            ? servicesColors[data.service.data.attributes.color].primary
            : palette.secondary
        }
        sx={{ transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)" }}
        overflow="hidden"
      >
        <Card
          onClick={onClickHandler}
          onFocus={() => {
            if (isDesktop) handleHover(pos);
          }}
          onBlur={() => {
            if (isDesktop) handleHover(null);
          }}
          href={"/services/" + data.service.data.attributes.slug}
          aria-label={
            data.service.data.attributes.subtitle +
            " " +
            data.service.data.attributes.title +
            " " +
            data.description +
            " " +
            data.secondDescription
          }
        >
          <Typography mb={0.625}>
            {data.service.data.attributes.subtitle}
          </Typography>
          <Typography
            fontSize={48}
            fontWeight={600}
            lineHeight={1.29}
            mb={1.875}
          >
            {data.service.data.attributes.title}
          </Typography>

          <Stack
            height={descrHeight}
            sx={{ transition: "height 500ms cubic-bezier(0.4, 0, 0.2, 1)" }}
          >
            {!hovered ? (
              <Typography ref={regularNode} fontSize={22} lineHeight={1.82}>
                {data.description}
              </Typography>
            ) : (
              <Stack
                ref={hoveredNode}
                direction="row"
                alignItems="end"
                justifyContent="space-between"
              >
                <Typography fontSize={22} lineHeight={1.82}>
                  {data.secondDescription}
                </Typography>

                <Stack
                  onClick={onArrowClick}
                  width={48}
                  height={48}
                  borderRadius="50%"
                  border="1px dashed #fff"
                  color="custom.primary"
                  alignItems="center"
                  justifyContent="center"
                  flexShrink={0}
                  sx={{
                    transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                    "&:hover": {
                      transform: "rotate(-45deg)",
                    },
                  }}
                >
                  <svg
                    style={{
                      animation: isDesktop
                        ? ""
                        : "beat 1.5s infinite cubic-bezier(0.4, 0, 0.2, 1)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M7 7l9.2 9.2M17 7v10H7" />
                  </svg>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
}
