import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import LinkButtonFooterLocation from "../LinkButton/LinkButtonFooterLocation";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./LocationItemAbout.css";

export default function LocationData({ data }) {
  const nodeRef = useRef(null);
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={data.id}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <Stack spacing={2} ref={nodeRef}>
          <Typography
            component="address"
            color="custom.text"
            lineHeight={1.69}
            sx={{
              transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            }}
          >
            {data.addressStreet}
            <br />
            {data.addressPostcode} {data.title}
          </Typography>
          {data.links.map((i, idx) => (
            <div key={"footer-loc-link" + data.title + idx}>
              <LinkButtonFooterLocation data={i} color="custom.text" />
            </div>
          ))}
        </Stack>
      </CSSTransition>
    </SwitchTransition>
  );
}
