import React from "react";
import LinkButton from "./LinkButton";

export default function LinkButtonHeaderSub({ isLight, data }) {
  return (
    <LinkButton
      data={data}
      sx={{
        color: isLight ? "custom.ph" : "custom.white",
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        fontSize: 12,
        fontWeight: 600,
        "&:hover": { color: "custom.primary" },
        "&:focus": { color: "custom.primary" },
      }}
    />
  );
}
