import * as Yup from "yup";

export const schemaColaborationForm = Yup.object().shape({
  name: Yup.string().min(2, "Te kort!").required("Dit is een verplicht veld."),
  phone: Yup.string(),
  email: Yup.string()
    .email("Ongeldig e-mail")
    .required("Dit is een verplicht veld."),
  companyName: Yup.string(),
  description: Yup.string(),
  service: Yup.array().of(Yup.string()),
  time: Yup.string(),
  privacy: Yup.boolean().oneOf([true], "Dit is een verplicht veld."),
  subscribe: Yup.boolean(),
});
