import { Stack, useMediaQuery } from "@mui/material";
import React from "react";
import ServiceCard from "./ServiceCard";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";

const linksNamings = {
  0: "Ga naar",
  1: "Meer over",
  2: "Alles over",
  3: "Info over",
};

export default function ServiceBigList({ data, hovered, handleHover }) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return (
    <Stack
      display="grid"
      gridTemplateColumns={{ lg: `repeat(${data.length}, 1fr)` }}
      columnGap={2}
    >
      {data.map((i, idx) => (
        <ServiceCard
          key={"service-grid-card-top" + idx}
          data={i}
          hovered={idx === hovered}
          pos={idx}
          isBig
          handleHover={handleHover}
          sx={
            isDesktop
              ? {}
              : {
                  gridRowStart: idx * 3 + 1,
                  mt: idx === 0 ? 0 : 4,
                }
          }
          component="div"
        />
      ))}
      {data.map((i, idx) => (
        <Stack
          key={"service-grid-card-mid" + idx}
          onFocus={() => handleHover(idx)}
          onBlur={() => handleHover(null)}
          onMouseEnter={() => handleHover(idx)}
          onMouseLeave={() => handleHover(null)}
          p={{ xxs: 3.75, sm: 5, lg: 3.75 }}
          component="ul"
          bgcolor="custom.bgLow"
          gap={2.5}
          alignItems="start"
          sx={{
            borderEndEndRadius: 15,
            borderEndStartRadius: 15,
            gridRowStart: isDesktop ? "auto" : idx * 3 + 2,
          }}
        >
          {i.service.data.attributes.links.map((j, idx) => (
            <LinkButtonFooterLocation
              key={i.service.data.attributes.title + "service-links" + idx}
              data={j}
              underlineColor="transparent"
            />
          ))}
        </Stack>
      ))}
      {data.map((i, idx) => (
        <Stack
          key={"service-grid-card-bot" + idx}
          pt={3.75}
          pl={{ xxs: 3.75, sm: 5, lg: 3.75 }}
          onMouseEnter={() => handleHover(idx)}
          onMouseLeave={() => handleHover(null)}
          onFocus={() => handleHover(idx)}
          onBlur={() => handleHover(null)}
          sx={isDesktop ? {} : { gridRowStart: idx * 3 + 3 }}
        >
          <LinkButtonFooterLocation
            data={{
              type: "INTERNAL LINK",
              link: "/services/" + i.service.data.attributes.slug,
              title: `${
                linksNamings[idx % 4]
              } ${i.service.data.attributes.title.toLowerCase()}`,
            }}
            underlineColor="transparent"
            color="custom.accentLink"
            fontSize={22}
            svgColor="custom.ph"
            translateY={-1}
          />
        </Stack>
      ))}
    </Stack>
  );
}
