import { Stack, Typography } from "@mui/material";
import { Element, Image, LinkElement } from "./LatestNewsCard.styled";
import Wrapper from "../../common/Wrapper";
import { getDateFromString } from "../../../helpers/getDateFromString";
import RichText from "../../common/RichText/RichText";
import { useParallax } from "react-scroll-parallax";
import { useOptions } from "../../../hooks/useOptions";

const api = process.env.REACT_APP_API_URL;

export default function LatestNewsCard({ data, pos }) {
  const { setCursorType } = useOptions();
  const parallax = useParallax({
    speed: -20,
  });
  return (
    <Element $src={api + data.image.data.attributes.url}>
      <LinkElement
        to={`/news/${data.slug}`}
        $pos={pos}
        onMouseEnter={() => setCursorType("news")}
        onMouseLeave={() => setCursorType("trail")}
        onClick={() => setCursorType("trail")}
      >
        <Wrapper py={{ xxs: 5, lg: 12 }}>
          <Stack
            display="grid"
            gridTemplateColumns={{ lg: "1fr 1fr" }}
            gap={{ lg: 2 }}
          >
            <Stack
              justifyContent="space-between"
              pr={{ lg: 10 }}
              gap={{ xxs: 3, lg: 2.25 }}
            >
              <Typography
                fontSize={48}
                fontWeight={600}
                component="h3"
                lineHeight={1.29}
              >
                {data.title}
              </Typography>
              <Stack spacing={3.25}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    className="attr"
                    component="span"
                    fontSize={22}
                    fontWeight={600}
                  >
                    {data.createdBy.firstname} {data.createdBy.lastname}
                  </Typography>
                  <Typography
                    className="attr"
                    component="span"
                    fontSize={22}
                    color="custom.ph"
                  >
                    {getDateFromString(data.date)}
                  </Typography>
                </Stack>
                {data.tags.data.length > 0 && (
                  <Stack component="ul" direction="row" spacing={1.25}>
                    {data.tags.data.map((i, idx) => (
                      <Stack
                        key={data.title + "news" + pos + "tag" + idx}
                        component="li"
                        borderRadius={15}
                        height={30}
                        bgcolor="custom.bgTag"
                        justifyContent="center"
                        alignItems="center"
                        px={1.5}
                      >
                        <Typography
                          component="span"
                          fontSize={12}
                          fontWeight={600}
                        >
                          {i.attributes.title}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack pt={{ xxs: 3, lg: 1 }}>
              <RichText
                data={data.description}
                fontSize={18}
                lineHeight={2}
                paragraphMargin={2}
              />
            </Stack>
          </Stack>
        </Wrapper>
      </LinkElement>
      <Stack
        position="absolute"
        top="-20%"
        bottom="-50%"
        left={0}
        right={0}
        zIndex={-1}
        ref={parallax.ref}
      >
        <Image
          src={api + data.image.data.attributes.url}
          alt={data.image.data.attributes.alternativeText}
        />
      </Stack>
    </Element>
  );
}
