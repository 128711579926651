import React from "react";
import LinkButton from "./LinkButton";
import { Typography } from "@mui/material";

export default function LinkButtonFooterLocation({
  data,
  color,
  fontSize = 16,
  gap = 0.5,
  underlineColor = "custom.phLight",
  svgColor = color,
  translateY = 0,
  avoidIcon = false,
}) {
  return (
    <>
      {data.type === "PHONE" || data.type === "EMAIL" ? (
        <LinkButton
          data={data}
          sx={{
            fontSize,
            color,
            display: "inline-block",
            position: "relative",
            "&::after": {
              content: "''",
              position: "absolute",
              bottom: -5,
              left: 0,
              width: "100%",
              height: "1px",
              backgroundColor: underlineColor,
              transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              transformOrigin: "100% 100%",
            },
            "&:hover::after": {
              backgroundColor: "custom.accentLink",
            },
            "&:focus::after": {
              backgroundColor: "custom.accentLink",
            },
          }}
        />
      ) : (
        <LinkButton
          data={data}
          sx={{
            fontSize,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap,
            color,
            "& svg": {
              color: svgColor,
              transform: `translateY(${translateY}px)`,
              transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            },
            "&:hover svg": {
              color: "custom.accentLink",
              transform: `rotate(-45deg) translateY(${translateY}px)`,
            },
            "&:focus svg": {
              color: "custom.accentLink",
              transform: `rotate(-45deg) translateY(${translateY}px)`,
            },
            "& span::after": {
              content: "''",
              position: "absolute",
              bottom: -5,
              left: 0,
              width: "100%",
              height: "1px",
              backgroundColor: underlineColor,
              transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              transformOrigin: "100% 100%",
            },
            "&:hover span::after": {
              backgroundColor: "custom.accentLink",
            },
            "&:focus span::after": {
              backgroundColor: "custom.accentLink",
            },
          }}
        >
          <Typography
            component="span"
            fontSize={fontSize}
            sx={{
              position: "relative",
            }}
          >
            {data.title}
          </Typography>
          {!avoidIcon && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 7l9.2 9.2M17 7v10H7" />
            </svg>
          )}
        </LinkButton>
      )}
    </>
  );
}
