import React from "react";
import { Stack, Typography } from "@mui/material";
import RichText from "../../common/RichText/RichText";
import LinkButtonFooterLocation from "../LinkButton/LinkButtonFooterLocation";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { useParallax } from "react-scroll-parallax";

const api = process.env.REACT_APP_API_URL;

export default function RegularCard({ data, pos }) {
  const { setCursorType } = useOptions();
  const parallax = useParallax({
    speed: pos % 2 ? 7 : 3,
  });
  return (
    <Stack
      height="100%"
      boxSizing="border-box"
      pb={7.5}
      spacing={5.5}
      justifyContent="space-between"
      sx={{
        "&:hover img": { transform: "scale(1.03)" },
      }}
    >
      <Stack spacing={5.5}>
        <Stack
          position="relative"
          overflow="hidden"
          width="100%"
          borderRadius={3.75}
          flexShrink={0}
          sx={{ aspectRatio: "1/1" }}
        >
          <Stack
            position="absolute"
            top="-20%"
            bottom="-20%"
            left={0}
            right={0}
            zIndex={2}
            ref={parallax.ref}
          >
            <img
              src={`${api}${data.image.data.attributes.url}`}
              alt={data.image.data.attributes.alternativeText ?? data.title}
              style={{
                objectFit: "cover",
                objectPosition: "center",
                height: "100%",
                transition: "transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
            />
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <Typography
            fontWeight={600}
            fontSize={24}
            lineHeight={1.33}
            dangerouslySetInnerHTML={{ __html: brakeString(data.title, true) }}
          ></Typography>
          {data.description && (
            <RichText
              data={data.description}
              fontSize={18}
              paragraphMargin={1}
            />
          )}
        </Stack>
      </Stack>
      <div
        onMouseEnter={() => setCursorType("trail")}
        onMouseLeave={() => setCursorType("slider")}
      >
        <LinkButtonFooterLocation
          underlineColor="custom.white"
          fontSize={22}
          data={{
            type: "INTERNAL LINK",
            title: "Bekijk klantverhaal",
            link: `/projects/${data.slug}`,
          }}
        />
      </div>
    </Stack>
  );
}
