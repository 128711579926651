import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Wrapper from "../../common/Wrapper";
import { useParallax } from "react-scroll-parallax";
import FilterButton from "../../elements/FilterButton/FilterButton";
import RichText from "../../common/RichText/RichText";
import { getProjects } from "../../../services/getProjects";
import CaseCard from "../../elements/CaseCard/CaseCard";
import { Swiper, SwiperSlide } from "../../common/Swiper/Swiper";
import { useOptions } from "../../../hooks/useOptions";
import AllCasesSlide from "./AllCasesSlide";
import BestProject from "../../elements/BestProject/BestProject";
import { brakeString } from "../../../helpers/brakeString";
import Loader from "../../common/Loader/Loader";
import ToTopButton from "../../elements/ToTopButton/ToTopButton";
import { useInView } from "react-intersection-observer";

export default function AllCases({ data }) {
  const { setCursorType, palette } = useOptions();
  const [cases, setCases] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const parallax = useParallax({
    speed: -5,
  });
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    getProjects(1, 8, selectedFilters).then((resData) => {
      setTotal(resData.meta.pagination.total / 4);
      setCases(resData.data);
      setPage(2);
      return;
    });
  }, [selectedFilters]);

  const handleFilter = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters((prev) => prev.filter((i) => i !== id));
    } else {
      setSelectedFilters((prev) => [...prev, id]);
    }
  };

  const loadMore = () => {
    setIsLoading(true);
    getProjects(page + 1, 4, selectedFilters)
      .then((data) => setCases((prev) => [...prev, ...data.data]))
      .finally(() => setIsLoading(false));
    setPage(page + 1);
  };

  return (
    <Stack component="section" py={8} id="all-cases">
      <Wrapper mb={{ xxs: 2, lg: 20 }}>
        <Stack
          display="grid"
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          gap={2}
          ref={parallax.ref}
        >
          <Stack justifyContent="end" position="relative">
            <Typography
              fontSize={{ xxs: 68, lg: 100 }}
              fontWeight={600}
              lineHeight={1.1}
              component="h2"
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>

            <Stack mt={3.5} position={{ lg: "absolute" }} bottom={-95}>
              <Typography fontSize={14} pl={2.5} mb={2.25} fontWeight={600}>
                Welke projecten{" "}
                <Typography
                  component="span"
                  fontFamily="Libre Baskerville"
                  fontSize="italic"
                >
                  wil jij
                </Typography>{" "}
                zien?
              </Typography>
              <Stack component="ul" direction="row" gap={1.25} flexWrap="wrap">
                {data.tags.data.map((i, idx) => (
                  <li key={"filtration-item" + idx}>
                    <FilterButton
                      data={i}
                      handler={handleFilter}
                      isSelected={selectedFilters.includes(i.id)}
                    />
                  </li>
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack justifyContent="end" display={{ xxs: "none", lg: "flex" }}>
            <RichText
              data={data.description}
              fontSize={24}
              lineHeight={1.33}
              paragraphMargin={2}
            />
          </Stack>
        </Stack>
      </Wrapper>
      <Wrapper display="grid" gap={2} gridTemplateColumns={{ lg: "1fr 1fr" }}>
        {cases.slice(0, 2).map((i, idx) => (
          <CaseCard
            key={"all-cases-slice1" + idx}
            data={i.attributes}
            pos={idx}
          />
        ))}
      </Wrapper>
      {data.projects && (
        <Stack
          sx={{
            background: data.addBackground
              ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
              : "",

            mb: cases.length > 2 ? 0 : 10,
          }}
        >
          <Wrapper
            extra={{
              px: { xxs: 1.875, sm: 5, lg: 10, xl: 0 },
              pl: { xxs: 1.875, sm: 5, lg: 10, xl: 17.5 },
              pr: { xxs: 1.875, sm: 0, lg: 0, xl: 0 },
            }}
            my={8}
          >
            <div
              onMouseEnter={() => setCursorType("slider")}
              onMouseLeave={() => setCursorType("trail")}
            >
              <Swiper
                height="auto"
                isAsymmetric
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  780: {
                    slidesPerView: 2.5,
                  },
                  1280: {
                    slidesPerView: 3.5,
                  },
                  1920: {
                    slidesPerView: 4.5,
                  },
                }}
                spaceBetween={16}
                scrollbar={true}
                mousewheel={true}
              >
                {data.projects.data.map((i, idx) => (
                  <SwiperSlide key={"allcases-swiper" + idx} height="auto">
                    <AllCasesSlide data={i.attributes} pos={idx} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Wrapper>
        </Stack>
      )}
      <div ref={ref}>
        <Wrapper display="grid" gap={2} gridTemplateColumns={{ lg: "1fr 1fr" }}>
          {cases.slice(2, 4).map((i, idx) => (
            <CaseCard
              key={"all-cases-slice2" + idx}
              data={i.attributes}
              pos={idx}
            />
          ))}
        </Wrapper>
        {data.bestProject && (
          <BestProject
            data={data.bestProject.data.attributes}
            title={data.bestProjectTitle}
          />
        )}
        <Wrapper display="grid" gap={2} gridTemplateColumns={{ lg: "1fr 1fr" }}>
          {cases.slice(4).map((i, idx) => (
            <CaseCard
              key={"all-cases-slice3" + idx}
              data={i.attributes}
              pos={idx}
            />
          ))}
        </Wrapper>
      </div>
      <Wrapper mt={{ xxs: 4, lg: 13 }} display="flex" justifyContent="center">
        {total > page && !isLoading && (
          <Stack
            component="button"
            type="button"
            onClick={loadMore}
            gap={1}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              "& svg": {
                color: "custom.ph",
                transform: `translateY(-1px)`,
                transition:
                  "color 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              },
              "&:hover svg": {
                color: "custom.primary",
                transform: `rotate(45deg) translateY(-2px)`,
              },
              "&:focus svg": {
                color: "custom.primary",
                transform: `rotate(45deg) translateY(-2px)`,
              },
              "&:hover span": {
                color: "custom.primary",
              },
              "&:focus span": {
                color: "custom.primary",
              },
            }}
          >
            <Typography
              component="span"
              fontSize={22}
              sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
            >
              Laad meer cases
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 7l9.2 9.2M17 7v10H7" />
            </svg>
          </Stack>
        )}
        {isLoading && <Loader />}
      </Wrapper>
      <ToTopButton isShow={inView} title="Cases filteren" target="#all-cases" />
    </Stack>
  );
}
