import { Stack, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../common/Wrapper";
import { useOptions } from "../../../hooks/useOptions";
import { brakeString } from "../../../helpers/brakeString";
import { calculateColumns } from "../../../helpers/calculateColumns";

const api = process.env.REACT_APP_API_URL;

export default function Partners({ data }) {
  const { palette } = useOptions();
  return (
    <Stack
      component="section"
      pt={6}
      pb={8}
      sx={{
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      <Wrapper>
        <Typography
          component="h2"
          fontSize={48}
          fontWeight={600}
          lineHeight={1.33}
          mb={8}
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
        ></Typography>
        <Stack
          component="ul"
          px={{ md: "10%", lg2: "20%", xl: "10%" }}
          display="grid"
          gap={2}
          gridTemplateColumns={{
            xxs: "repeat(2, 1fr)",
            sm: `repeat(${calculateColumns(
              data.partners.data.length,
              4
            )}, 1fr)`,
          }}
        >
          {data.partners.data.map((i, idx) => (
            <Stack
              key={"partners" + idx}
              component="li"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="custom.bgPartner"
              borderRadius={3.75}
              sx={{ aspectRatio: "1/1" }}
            >
              <Stack
                height="100%"
                component="a"
                target="_blank"
                href={i.attributes.link}
                justifyContent="center"
                alignItems="center"
                sx={{
                  "&:hover img": { filter: "grayscale(0%) !important" },
                  "&:focus img": { filter: "grayscale(0%) !important" },
                }}
              >
                <img
                  src={api + i.attributes.image.data.attributes.url}
                  alt={i.attributes.image.data.attributes.alternativeText}
                  style={{
                    maxWidth: "80%",
                    maxHeight: "40%",
                    filter: "grayscale(100%)",
                    transition: "filter 250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Wrapper>
    </Stack>
  );
}
