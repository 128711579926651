import { useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./CustomTitle.css";
import { Stack, Typography } from "@mui/material";
import { getHighlightedText } from "../../../helpers/getHighlightedText";
import { getArrSeparatedByAsterisk } from "../../../helpers/getArrSeparatedByAsterisk";
import AnimatedLetters from "../AnimatedText/AnimatedLetters";

export default function CustomTitle({
  data: { title, characteristic, characteristicHighlightedWords, subtitle },
}) {
  const nodeRef = useRef(null);
  const charRef = useRef(null);
  const subRef = useRef(null);
  return (
    <Stack>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={characteristic}
          nodeRef={charRef}
          addEndListener={(done) => {
            nodeRef.current.addEventListener("transitionend", done, false);
          }}
          classNames="fade-char"
        >
          <Typography
            ref={charRef}
            color="custom.white"
            fontSize={18}
            fontWeight={600}
            component="span"
            mb={2}
            pl={1.25}
          >
            {characteristic &&
              (characteristicHighlightedWords
                ? getHighlightedText(
                    characteristic,
                    characteristicHighlightedWords
                  )
                : characteristic)}
          </Typography>
        </CSSTransition>
      </SwitchTransition>
      <Stack overflow="hidden">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={title}
            nodeRef={nodeRef}
            addEndListener={(done) => {
              nodeRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="fade-title"
          >
            <Typography
              ref={nodeRef}
              color="custom.white"
              fontSize={{ xxs: 70, lg: 120 }}
              fontWeight={600}
              lineHeight={1.08}
              component="h3"
              mb={0.5}
            >
              {getArrSeparatedByAsterisk(title).map((i, idx, arr) => (
                <span key={"animated-case-hero" + i + idx}>
                  <AnimatedLetters
                    text={i}
                    delay={200}
                    time={400}
                    baseDelay={0}
                  />
                  {arr.length - 1 !== idx && <br />}
                </span>
              ))}
            </Typography>
          </CSSTransition>
        </SwitchTransition>
      </Stack>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={subtitle}
          nodeRef={subRef}
          addEndListener={(done) => {
            nodeRef.current.addEventListener("transitionend", done, false);
          }}
          classNames="fade-sub"
        >
          <Typography
            ref={subRef}
            color="custom.white"
            fontSize={16}
            fontWeight={700}
            component="span"
          >
            {subtitle}
          </Typography>
        </CSSTransition>
      </SwitchTransition>
    </Stack>
  );
}
