import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Wrapper from "../../common/Wrapper";
import RichText from "../../common/RichText/RichText";
import { getNews } from "../../../services/getNews";
import LatestNewsCard from "./LatestNewsCard";
import { useParallax } from "react-scroll-parallax";
import FilterButton from "../../elements/FilterButton/FilterButton";
import { brakeString } from "../../../helpers/brakeString";
import Loader from "../../common/Loader/Loader";
import { useInView } from "react-intersection-observer";
import ToTopButton from "../../elements/ToTopButton/ToTopButton";

export default function LatestNews({ data }) {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const parallax = useParallax({
    speed: -5,
  });
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    getNews(1, data.showPerOnePage, selectedFilters).then((resData) => {
      setTotal(resData.meta.pagination.total / data.showPerOnePage);
      setNews(resData.data);
      setPage(1);
      return;
    });
  }, [data.showPerOnePage, selectedFilters]);

  const handleFilter = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters((prev) => prev.filter((i) => i !== id));
    } else {
      setSelectedFilters((prev) => [...prev, id]);
    }
  };

  const loadMore = () => {
    setIsLoading(true);
    getNews(page + 1, data.showPerOnePage, selectedFilters)
      .then((data) => setNews((prev) => [...prev, ...data.data]))
      .finally(() => setIsLoading(false));
    setPage(page + 1);
  };

  return (
    <Stack
      component="section"
      pt={{ xxs: 8, lg: 12 }}
      pb={{ xxs: 6, lg: 8.5 }}
      id="latest-news"
    >
      <Wrapper mb={{ xxs: 5, lg: 15.5 }}>
        <Stack
          display="grid"
          gridTemplateColumns={{ lg: "1fr 1fr" }}
          gap={3.5}
          ref={parallax.ref}
        >
          <Stack justifyContent="end" position="relative">
            <Typography
              fontSize={{ xxs: 68, lg: 100 }}
              fontWeight={600}
              lineHeight={1.1}
              component="h2"
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>
            {data.addFiltration && (
              <Stack mt={3.5} position={{ lg: "absolute" }} bottom={-72}>
                <Typography fontSize={14} pl={2.5} mb={2.25}>
                  Selecteer hieronder{" "}
                  <Typography
                    component="span"
                    fontFamily="Libre Baskerville"
                    fontSize="italic"
                  >
                    jouw interesses
                  </Typography>
                </Typography>
                <Stack
                  component="ul"
                  direction="row"
                  gap={1.25}
                  flexWrap="wrap"
                >
                  {data.tags.data.map((i, idx) => (
                    <li key={"filtration-item" + idx}>
                      <FilterButton
                        data={i}
                        handler={handleFilter}
                        isSelected={selectedFilters.includes(i.id)}
                      />
                    </li>
                  ))}
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack justifyContent="end">
            <RichText
              data={data.description}
              fontSize={24}
              lineHeight={1.33}
              paragraphMargin={2}
            />
          </Stack>
        </Stack>
      </Wrapper>
      <Stack component="ul" ref={ref}>
        {news.map((i, idx) => (
          <LatestNewsCard
            key={"news-card" + idx}
            data={i.attributes}
            pos={idx}
          />
        ))}
      </Stack>
      {total > page && !isLoading && (
        <Wrapper>
          <Stack
            component="button"
            type="button"
            ml={{ lg: "calc(50% + 8px)" }}
            onClick={loadMore}
            mt={4.5}
            gap={1}
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              "& svg": {
                color: "custom.ph",
                transform: `translateY(-1px)`,
                transition:
                  "color 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms cubic-bezier(0.4, 0, 0.2, 1)",
              },
              "&:hover svg": {
                color: "custom.primary",
                transform: `rotate(45deg) translateY(-2px)`,
              },
              "&:focus svg": {
                color: "custom.primary",
                transform: `rotate(45deg) translateY(-2px)`,
              },
              "&:hover span": {
                color: "custom.primary",
              },
              "&:focus span": {
                color: "custom.primary",
              },
            }}
          >
            <Typography
              component="span"
              fontSize={22}
              sx={{ transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
            >
              Meer nieuws
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M7 7l9.2 9.2M17 7v10H7" />
            </svg>
          </Stack>
        </Wrapper>
      )}
      {isLoading && <Loader sx={{ paddingLeft: "50%" }} />}
      <ToTopButton
        isShow={data.addFiltration && inView}
        title="Nieuws filteren"
        target="#latest-news"
      />
    </Stack>
  );
}
