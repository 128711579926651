import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Typography } from "@mui/material";
import "./ToTopButton.css";

export default function ToTopButton({ isShow, title, target }) {
  return (
    <AnimatePresence>
      {isShow && (
        <motion.a
          href={target}
          key="up-button-news"
          className="to-top-button"
          initial={{ scale: 0 }}
          animate={{
            scale: 1,
            transition: { duration: 0.25 },
          }}
          exit={{
            scale: 0,
            transition: { duration: 0.25 },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)" }}
          >
            <path d="M12 19V6M5 12l7-7 7 7" />
          </svg>
          <Typography
            component="span"
            color="custom.white"
            fontSize={14}
            lineHeight={1.14}
            fontWeight={600}
            sx={{
              transform: "rotate(-5deg)",
              textAlign: "center",
              transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            }}
          >
            {title}
          </Typography>
        </motion.a>
      )}
    </AnimatePresence>
  );
}
