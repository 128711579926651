import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

export default function AnimatedNumber({
  num,
  start = 0,
  delay = 200,
  duration = 500,
  addRandom = false,
}) {
  const { ref, inView } = useInView();
  const { number } = useSpring({
    number: !inView ? start : num,
    from: { number: !inView ? num : start },
    delay,
    config: {
      mass: 1,
      tension: 5,
      friction: 5,
      duration: duration + addRandom ? Math.round(Math.random() * 1000) : 0,
    },
  });
  return (
    <animated.span ref={ref}>{number.to((i) => i.toFixed())}</animated.span>
  );
}
