import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(NavLink)`
  position: relative;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  color: #fff;

  span {
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media screen and (max-width: 1279px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &.active {
      span {
        font-weight: 600;
      }
    }
    & svg {
    }
  }
  @media screen and (min-width: 1280px) {
    color: ${(props) => (props.$isLight ? "#0e232e" : "#fff")};
    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${(props) => (props.$isLight ? "#707070" : "#707070")};
      transform: scaleX(0);
      transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
      transform-origin: 100% 100%;
    }
    &:hover::after,
    &:focus::after {
      transform: scaleX(1);
      transform-origin: 0% 100%;
    }
    &:hover span,
    &:focus span {
      color: #707070;
    }

    &.active {
      span {
        color: #38b3f6;
      }
      &::after {
        background-color: #38b3f6;
        transform: scaleX(1);
      }
    }
  }
`;
