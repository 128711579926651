import React from "react";
import { Stack, Typography } from "@mui/material";
import { useOptions } from "../../../hooks/useOptions";
import RichText from "../../common/RichText/RichText";
import AnimatedNumber from "../../common/AnimatedNumber/AnimatedNumber";

export default function ContentListItem({
  data,
  pos,
  addNumeration,
  isCardsGradient,
  addBackground,
  renderTwoColumns,
  coloredSubtitles,
}) {
  const { palette } = useOptions();
  return (
    <Stack
      component="li"
      bgcolor={addBackground ? "custom.white" : palette.secondary}
      borderRadius={7.5}
      pt={
        renderTwoColumns
          ? { xxs: addNumeration ? 16 : 6.25, lg: 6.25 }
          : { xxs: addNumeration ? 16 : 6.25, lg: 5.5 }
      }
      pb={
        renderTwoColumns
          ? { xxs: addNumeration ? 16 : 7, lg: 8 }
          : { xxs: addNumeration ? 16 : 7, lg: 7.25 }
      }
      pl={
        renderTwoColumns
          ? { xxs: 3, sm: 5, md: 10, xl: 4 }
          : { xxs: 3, sm: 5, md: 10, xl: 19.25 }
      }
      pr={
        renderTwoColumns
          ? { xxs: 3, sm: 5, md: 10, xl: 3.5 }
          : { xxs: 3, sm: 5, md: 8, xl: 15.25 }
      }
      sx={{
        background:
          isCardsGradient && !addBackground
            ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
            : "",
      }}
    >
      {data.subtitle && (
        <Typography
          fontSize={18}
          lineHeight={1.33}
          color={coloredSubtitles ? "custom.accentLink" : "inherit"}
          fontWeight={600}
          mb={0.25}
          pl={0.25}
        >
          {data.subtitle}
        </Typography>
      )}
      <Typography
        fontWeight={600}
        fontSize={48}
        lineHeight={1.33}
        mb={renderTwoColumns ? 2 : 3.5}
        position="relative"
      >
        {addNumeration && (
          <Typography
            component="span"
            position="absolute"
            left={{ xxs: 0, lg: -65 }}
            bottom={{ xxs: data.subtitle ? 112 : 85, lg: 6 }}
            fontWeight={600}
            fontSize={36}
            lineHeight={1.33}
          >
            {pos + 1 < 10 && 0}
            <AnimatedNumber num={pos + 1} start={1} delay={0} />.
          </Typography>
        )}

        {data.title}
      </Typography>
      {data.description && (
        <RichText
          fontSize={18}
          lineHeight={2}
          paragraphMargin={0}
          data={data.description}
        />
      )}
    </Stack>
  );
}
