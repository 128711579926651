import { Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function LinkButton({
  children,
  data: { type, link, title },
  sx,
  func = () => {},
}) {
  const convertPhoneLink = (link) => {
    return `tel:${link.replace(/\D/g, "")}`;
  };

  const convertEmailLink = (link) => {
    return `mailto:${link}`;
  };

  return (
    <>
      {type === "EXTERNAL LINK" && (
        <Stack
          onMouseEnter={() => func(false)}
          onMouseLeave={() => func(true)}
          onFocus={() => func(false)}
          onBlur={() => func(true)}
          component="a"
          href={link}
          target="_blank"
          rel="noreferrer"
          aria-label={title}
          lineHeight={1.5}
          sx={{
            flexShrink: 0,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": { color: "custom.primary" },
            "&:focus": { color: "custom.primary" },
            ...sx,
          }}
        >
          {children ?? title}
        </Stack>
      )}
      {type === "INTERNAL LINK" && (
        <Stack
          onMouseEnter={() => func(false)}
          onMouseLeave={() => func(true)}
          onFocus={() => func(false)}
          onBlur={() => func(true)}
          component={Link}
          to={link}
          aria-label={title}
          lineHeight={1.5}
          sx={{
            flexShrink: 0,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": { color: "custom.primary" },
            "&:focus": { color: "custom.primary" },
            ...sx,
          }}
        >
          {children ?? title}
        </Stack>
      )}
      {type === "PHONE" && (
        <Stack
          onMouseEnter={() => func(false)}
          onMouseLeave={() => func(true)}
          onFocus={() => func(false)}
          onBlur={() => func(true)}
          component="a"
          href={convertPhoneLink(link)}
          aria-label={title}
          lineHeight={1.5}
          sx={{
            flexShrink: 0,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": { color: "custom.primary" },
            "&:focus": { color: "custom.primary" },
            ...sx,
          }}
        >
          {children ?? title}
        </Stack>
      )}
      {type === "EMAIL" && (
        <Stack
          onMouseEnter={() => func(false)}
          onMouseLeave={() => func(true)}
          onFocus={() => func(false)}
          onBlur={() => func(true)}
          component="a"
          href={convertEmailLink(link)}
          aria-label={title}
          lineHeight={1.5}
          sx={{
            flexShrink: 0,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": { color: "custom.primary" },
            "&:focus": { color: "custom.primary" },
            ...sx,
          }}
        >
          {children ?? title}
        </Stack>
      )}
      {type === "ANCHOR" && (
        <Stack
          onMouseEnter={() => func(false)}
          onMouseLeave={() => func(true)}
          onFocus={() => func(false)}
          onBlur={() => func(true)}
          component="a"
          href={link}
          aria-label={title}
          lineHeight={1.5}
          sx={{
            flexShrink: 0,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": { color: "custom.primary" },
            "&:focus": { color: "custom.primary" },
            ...sx,
          }}
        >
          {children ?? title}
        </Stack>
      )}
    </>
  );
}
