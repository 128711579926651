import { Stack } from "@mui/material";
import React from "react";

export default function ControlButton({ children, handler }) {
  return (
    <Stack
      component="button"
      type="button"
      onClick={handler}
      borderRadius="50%"
      sx={{
        transition: "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        boxSizing: "content-box",
        cursor: "pointer",
        border: "6px solid transparent",
        "&:hover": {
          borderColor: "custom.ph",
        },
        "&:focus": {
          borderColor: "custom.ph",
        },
      }}
    >
      <Stack
        width={60}
        height={60}
        borderRadius="50%"
        bgcolor="custom.controls"
        color="custom.white"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Stack>
    </Stack>
  );
}
