import axios from "axios";

export const getProjects = async (page = 1, pageSize = 4, tags = []) => {
  let filter = "";

  if (tags.length > 0) {
    filter = tags.map((tag) => `&filters[tags][id][$eq]=${tag}`).join("");
  }

  const response = await axios.get(
    `/api/projects?sort[0]=createdAt:desc&populate[tags][populate]=*&populate[image][populate]=*&pagination[pageSize]=${pageSize}&pagination[page]=${page}&publicationState=live${filter}`
  );
  return response.data;
};
