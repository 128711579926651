import React, { useState } from "react";
import { useFormik } from "formik";
import { Stack, Typography } from "@mui/material";
import { schemaContactForm } from "../../../helpers/validationSchema/schemaContactForm";
import { createFormSubmitting } from "../../../services/createFormSubmitting";
import { toast } from "react-toastify";
import InputField from "../../elements/InputField/InputField";
import ArrowButton from "../../elements/ArrowButton/ArrowButton";
import CheckField from "../../elements/CheckField/CheckField";
import { Link } from "react-router-dom";

const initialValues = {
  name: "",
  email: "",
  description: "",
  privacy: false,
  subscribe: false,
};

export default function ContactForm() {
  const [isDisabled, setIsDisabled] = useState(true);

  const formik = useFormik({
    initialValues,
    validationSchema: schemaContactForm,
    onSubmit: async (values, { resetForm }) => {
      const formData = { ...values };
      delete formData.privacy;
      await createFormSubmitting("CONTACT", formData).then(() =>
        toast(
          <>
            <Typography mb={0.5}>Verstuurd</Typography>
            <Typography fontWeight={600} fontSize={48} mb={2} lineHeight={1.33}>
              Succes
            </Typography>
            <Typography fontSize={18} lineHeight={2}>
              Bedankt voor je bericht! We nemen zo snel mogelijk contact met je
              op.
            </Typography>
          </>
        )
      );

      resetForm(initialValues);
      setIsDisabled(true);
    },
  });
  return (
    <Stack component="form" id="contact" onSubmit={formik.handleSubmit}>
      <Stack gap={4} mb={3}>
        <InputField formik={formik} name="name" label="Je naam" required />
        <InputField
          formik={formik}
          name="email"
          label="Wat is je e-mail?"
          required
        />
        <InputField
          formik={formik}
          name="description"
          label="Ik wil meer informatie over"
          textArea={4}
        />
      </Stack>
      <Stack
        gap={{ xxs: 2.5, lg: 5.5 }}
        direction={{ xxs: "column-reverse", lg: "row" }}
        alignItems="start"
      >
        <ArrowButton text="Verzenden" isSumbit isDisabled={isDisabled} />
        <Stack gap={1}>
          <CheckField
            formik={formik}
            name="privacy"
            func={(checked) => setIsDisabled(!checked)}
            label={
              <>
                Ja, ik ga akkoord met de{" "}
                <Stack
                  component={Link}
                  display="inline"
                  color="custom.primary"
                  to="/"
                >
                  Privacy- en cookieverklaring
                </Stack>
                .
              </>
            }
          />
          <CheckField
            formik={formik}
            name="subscribe"
            label="Ja, ik ontvang ook graag jullie nieuwsbrief."
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
