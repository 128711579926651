import { Swiper, SwiperSlide } from "../../common/Swiper/Swiper";
import { useOptions } from "../../../hooks/useOptions";
import HiddenTitle from "../../elements/HiddenTitle/HiddenTitle";
import { Stack, Typography } from "@mui/material";
import Wrapper from "../../common/Wrapper";
import LinkButtonFooterLocation from "../../elements/LinkButton/LinkButtonFooterLocation";
import GridImage from "./GridImage";
import { brakeString } from "../../../helpers/brakeString";
import SlideImage from "./SlideImage";

export default function Gallery({ data }) {
  const { setCursorType, palette } = useOptions();
  return (
    <section
      style={{
        paddingTop: 42,
        paddingBottom: 42,
        background: data.addBackground
          ? `transparent linear-gradient(101deg, ${palette.secondary} 0%, #F6FAFF 100%) 0% 0% no-repeat padding-box`
          : "",
      }}
    >
      {data.showTitleAndLink ? (
        <Wrapper>
          <Stack
            direction={{ xxs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ xxs: "start", md: "end" }}
            mb={5.5}
          >
            <Typography
              fontSize={{ xxs: 68, lg: 100 }}
              fontWeight={600}
              lineHeight={1.1}
              dangerouslySetInnerHTML={{ __html: brakeString(data.title) }}
            ></Typography>
            <LinkButtonFooterLocation
              data={data.link}
              fontSize={22}
              underlineColor="custom.white"
            />
          </Stack>
        </Wrapper>
      ) : (
        <HiddenTitle>{data.title}</HiddenTitle>
      )}
      {data.type === "SLIDER" && (
        <Wrapper
          extra={{
            px: 0,
            pl: { xxs: 1.875, sm: 5, lg: 10, xl: 17.5 },
            pr: { xxs: 1.875, sm: 0 },
          }}
        >
          <div
            onMouseEnter={() => setCursorType("slider")}
            onMouseLeave={() => setCursorType("trail")}
          >
            <Swiper
              spaceBetween={16}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                780: {
                  slidesPerView: 2.5,
                },
                1280: {
                  slidesPerView: 3.5,
                },
                1920: {
                  slidesPerView: 4.5,
                },
              }}
              scrollbar={true}
              isAsymmetric
              mousewheel={true}
            >
              {data.images.data.map((i, idx) => (
                <SwiperSlide key={"swiper" + idx} paddingBottom={30}>
                  <SlideImage data={i} pos={idx} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Wrapper>
      )}
      {data.type === "GRID" && (
        <Wrapper
          display="grid"
          gap={{ xxs: 1.25, lg: 2 }}
          gridTemplateColumns={{
            xxs: "repeat(4, 1fr)",
            lg: "repeat(10, 1fr)",
          }}
          gridAutoRows="1fr"
          component="ul"
        >
          {data.images.data.map((i, idx) => (
            <GridImage
              key={"grid-gallery" + idx}
              data={i.attributes}
              pos={idx}
            />
          ))}
        </Wrapper>
      )}
    </section>
  );
}
