import { Stack } from "@mui/material";
import React from "react";

export default function CloseButton({
  handler,
  sx,
  size = 18,
  strokeWidth = 1,
}) {
  return (
    <Stack
      component="button"
      type="button"
      onClick={handler}
      sx={{
        cursor: "pointer",
        position: "absolute",
        top: 28,
        right: 28,
        "&:hover svg": {
          color: "custom.primary",
        },
        "&:focus svg": {
          color: "custom.primary",
        },
        "& svg": {
          color: "custom.text",
          transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        },
        ...sx,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="5 5 14 14"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </Stack>
  );
}
