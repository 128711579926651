import { Stack } from "@mui/material";
import React from "react";

export default function MenuButton({ isLight, handleMenu }) {
  return (
    <Stack
      component="button"
      type="button"
      onClick={handleMenu}
      sx={{
        cursor: "pointer",
        "&:hover svg": {
          color: "custom.primary",
        },
        "&:focus svg": {
          color: "custom.primary",
        },
        "& svg": {
          color: isLight ? "custom.primary" : "custom.white",
          transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="22"
        viewBox="4 4 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </Stack>
  );
}
