import { useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./CustomDescription.css";
import RichText from "../../common/RichText/RichText";
import { Stack } from "@mui/material";

export default function CustomDescription({ data }) {
  const nodeRef = useRef(null);
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={data}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener("transitionend", done, false);
        }}
        classNames="fade-description"
      >
        <Stack ref={nodeRef} width={280}>
          {data ? (
            <RichText
              data={data}
              dark={false}
              lineHeight={1.75}
              paragraphMargin={1}
            />
          ) : null}
        </Stack>
      </CSSTransition>
    </SwitchTransition>
  );
}
